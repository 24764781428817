import React from 'react';
import { Grid, Divider, Typography, CircularProgress } from '@material-ui/core';

import { ContentSection, StatsCard } from 'components/lib';

import { BarChart } from 'components/charts/barChartNew';

export const StatsBlock = (props) => {
	const { state = false } = props;
	return (
		<Grid container spacing={2} direction='row' justify='flex-start'>
			<Grid
				item
				style={{
					backgroundColor: '#283038',
					paddingTop: 15,
					paddingBottom: 15,
					borderRadius: 4,
					minHeight: 150,
					maxWidth: 340,
					minWidth: 340,
					marginRight: 10,
					marginLeft: 10,
				}}
			>
				<Typography variant='h5' style={{ marginLeft: 15, marginBottom: 5 }}>
					Calls to apptmnts
				</Typography>
				{state.loading ? (
					<Grid
						item
						container
						justify='center'
						alignItems='center'
						style={{ height: '100%' }}
					>
						<CircularProgress />
					</Grid>
				) : (
					<StatsCard Data={state.statsCalls} alt />
				)}
			</Grid>

			{false && (
				<Grid
					item
					//xs={4}
					style={{
						backgroundColor: '#283038',
						paddingTop: 15,
						paddingBottom: 15,
						borderRadius: 4,
						minHeight: 150,
						maxWidth: 380,
						minWidth: 340,
					}}
				>
					<Typography variant='h5' style={{ marginLeft: 15, marginBottom: 5 }}>
						Revenue
					</Typography>
					{state.loading ? (
						<Grid
							item
							container
							justify='center'
							alignItems='center'
							style={{ height: '100%' }}
						>
							<CircularProgress />
						</Grid>
					) : (
						<StatsCard Data={state.revenueCards} noElevation alt />
					)}
				</Grid>
			)}
			<Grid
				item
				//xs={4}
				style={{
					backgroundColor: '#283038',
					paddingTop: 15,
					paddingBottom: 15,
					borderRadius: 4,
					//minHeight: 185,
					minHeight: 150,
					maxWidth: 380,
					minWidth: 340,
				}}
			>
				<Typography variant='h5' style={{ marginLeft: 15, marginBottom: 5 }}>
					Commision
				</Typography>
				{state.loading ? (
					<Grid
						item
						container
						justify='center'
						alignItems='center'
						style={{ height: '100%' }}
					>
						<CircularProgress />
					</Grid>
				) : (
					<StatsCard Data={state.commisionCards} noElevation alt />
				)}
			</Grid>
		</Grid>
	);
};

export const BarChartSection = (props) => {
	const { state } = props;
	const { chartData = false, ISABarChartFooter = false } = state;
	return (
		<ContentSection type='barChart'>
			{state.loading ? (
				<Grid
					item
					container
					justify='center'
					alignItems='center'
					style={{ height: '100%', minHeight: 400 }}
				>
					<CircularProgress />
				</Grid>
			) : (
				<BarChart chartData={chartData} />
			)}
			{ISABarChartFooter && (
				<>
					<Divider
						variant='fullWidth'
						style={{
							width: '100%',
							backgroundColor: '#171C22',
							marginBottom: '0.1em',
							height: 2,
						}}
					/>
					<Grid
						container
						direction='row'
						//justify='space-evenly'
						justify='space-between'
						alignItems='center'
						style={{ padding: '0 30px' }}
					>
						{ISABarChartFooter.map((i, index) => {
							return (
								<Grid item key={index} style={{ width: 120 }}>
									<Typography
										variant='h4'
										gutterBottom
										align='center'
										style={{ width: '100%' }}
									>
										{i.value}
									</Typography>

									<Typography
										color='textSecondary'
										variant='h5'
										align='center'
										style={{ width: '100%' }}
									>
										{i.label}
									</Typography>
								</Grid>
							);
						})}
					</Grid>
				</>
			)}
			<Divider style={{ marginTop: 30 }} />
		</ContentSection>
	);
};
