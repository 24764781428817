import React from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import { CardMUI } from '../../lib';
import Style from './comparisonCard.module.scss';

import CallMadeIcon from '@material-ui/icons/CallMade'; //grow
/*

comparison-icon-negative
comparison-icon-equal


//props: data, select label, select options, placeholder strings dataItem.e % $ etc

//trend widget
*/
export function ComparisonCard(props) {
	const { data, placeholderString = false, width, noBorders = false } = props;
	return (
		<div className={Style.gridContainer} style={width && { width }}>
			{data &&
				data.map((dataItem, dataItemIndex) => {
					return (
						<Grid item className={Style.gridItem} key={dataItemIndex}>
							<CardMUI
								label={dataItem.label}
								number1={!noBorders ? '' : dataItem.number1}
								number2={!noBorders ? '' : dataItem.number2}
								key={dataItemIndex}
								elevation={0}
								customClass={
									noBorders
										? ''
										: dataItemIndex < 1
										? Style['grid-card-start']
										: dataItemIndex === data.length - 1
										? Style['grid-card-end']
										: Style['grid-card']
								}
							>
								<Typography color='textSecondary' variant='h5' gutterBottom>
									{dataItem.label}
								</Typography>
								<Typography variant='h2' gutterBottom>
									{placeholderString
										? placeholderString[dataItemIndex] === '$'
											? '$' + dataItem.number1
											: dataItem.number1 + placeholderString[dataItemIndex]
										: dataItem.number1}
								</Typography>
								{dataItem.number2 && (
									<Paper
										elevation={0}
										classes={{ root: Style['comparison-paper-container'] }}
									>
										<Paper
											elevation={0}
											classes={{ root: Style['comparison-paper-item'] }}
										>
											<CallMadeIcon
												classes={{
													root:
														Style[
															Math.sign(dataItem.number2) === 1
																? 'comparison-icon-positive'
																: Math.sign(dataItem.number2) === -1
																? 'comparison-icon-negative'
																: 'comparison-icon-equal'
														],
												}}
											/>
										</Paper>
										<Typography color='textPrimary' variant='h5'>
											{`${dataItem.number2}%`}
										</Typography>
									</Paper>
								)}
							</CardMUI>
						</Grid>
					);
				})}
		</div>
	);
}
