/***
 *
 *   PROFILE
 *   Update the user profile
 *
 **********/

import React, { useEffect, useState } from 'react';
import { History, useMemberInfo } from 'components/lib';

import { Grid, Avatar, Button, Paper, Typography } from '@material-ui/core';
import Axios from 'axios';

export function ProfilePicture(props) {
	//TODO: callback and user profile update after API change
	// const [img, setImg] = useState(false);

	const [state, setState] = useState({
		loader: false,
		disabled: false,
		pictureUpload: null,
		picture: null,
		imgReqMsg: false,
		imgSizeMsg: false,
		userId: false,
	});

	let { userData, isMembmer = false, isCompany = false } = props;
	const { updateMember } = useMemberInfo();
	useEffect(() => {
		//eslint-disable-next-line react-hooks/exhaustive-deps
		if (!userData) return;
		//if (!userData.avatarId) return;

		setState({
			...state,
			picture: userData.avatarId,
			userId: userData.id,
			imgSizeMsg: false,
		});
	}, [userData]);

	async function apiCall(method, url, data) {
		let res = await Axios({
			method: method,
			url: process.env.REACT_APP_API_URI + url,
			data,
		});
		return res;
	}

	const updateProfile = async (imageId, userId) => {
		let res = await apiCall(
			'PUT',
			`/api/${isCompany ? 'organization' : 'user'}`,
			{
				avatarId: imageId,
				id: userId,
			}
		);
		if (res?.data && res.status === 200) {
			if (isMembmer) {
				updateMember();
			} else if (isCompany) {
				//
			} else {
				let oldUser = { ...userData };
				let changedUser = res.data;
				let newUser = { ...oldUser, ...changedUser };
				localStorage.setItem('user', JSON.stringify(newUser));
				History.push('/profile');
			}
		}
	};

	const submit = async (event) => {
		// event.preventDefault();
		setState({ ...state, imgSizeMsg: false });
		const filesSelected = event.target.files[0];
		if (!filesSelected) return;
		if (filesSelected.size > 128000)
			return setState({ ...state, imgSizeMsg: true });
		setState({ ...state, loader: true });
		let bodyFormData = new FormData();
		await bodyFormData.append('file', filesSelected);

		try {
			Axios({
				method: 'post',
				url: process.env.REACT_APP_API_URI + '/api/image',
				data: bodyFormData,
				headers: { 'Content-Type': 'multipart/form-data' },
			})
				.then(function (response) {
					if (response.status === 200) {
						setState({ ...state, loader: false, picture: response.data.avatarId });
						updateProfile(response.data.avatarId, state.userId);
					}
					//setState({ ...state, picture: url });
				})
				.catch(function (response) {
					//handle error

					setState({ ...state, loader: false });
				});
		} catch (error) {
			console.log('error', error);
		}
	};

	return (
		<>
			<Grid item xs={3}>
				<Paper
					style={{
						minHeight: 240,
						maxHeight: 240,
						minWidth: 200,
						width: '100%',
						borderRadius: 10,
					}}
					onMouseEnter={() => setState({ ...state, imgReqMsg: true })}
					onMouseLeave={() => setState({ ...state, imgReqMsg: false })}
				>
					<Grid
						container
						direction='column'
						justify='space-between'
						//alignItems='center'
						style={{ padding: 10, minHeight: 240 }}
					>
						<Grid item>
							<Typography
								color='textPrimary'
								variant='h5'
								style={{ marginBottom: '1em' }}
							>
								{isCompany ? 'Company Logo' : `Profile Picture`}
							</Typography>
						</Grid>
						<Grid item container direction='row' justify='space-between'>
							<Grid
								item
								container
								direction='row'
								justify='center'
								alignItems='flex-start'
								xs={6}
							>
								{/* {state.picture && ( */}
								<Avatar
									style={{
										height: '140px',
										width: '140px',
										marginBottom: '2em',
									}}
									src={
										state.picture
											? process.env.REACT_APP_API_URI + `/image/${state.picture}`
											: ''
									}
								></Avatar>
								{/* )} */}
							</Grid>
							<Grid
								item
								container
								direction='column'
								justify='flex-end'
								alignItems='flex-end'
								xs={6}
							>
								<Typography
									color={state.imgSizeMsg ? 'secondary' : 'textSecondary'}
									variant='h6'
									style={{ width: 100 }}
								>
									{state.imgReqMsg &&
										!state.imgSizeMsg &&
										'max picture size:128x128`px and 128kb'}
									{state.imgSizeMsg && 'your picture size is more than 128kb'}
								</Typography>

								<Button
									variant='contained'
									color='primary'
									component='label'
									disabled={isMembmer}
								>
									{state.loader ? 'uploading' : 'upload new'}
									<input
										type='file'
										style={{ display: 'none' }}
										onChange={submit}
										id='inputFileToLoad'
									/>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</>
	);
}
