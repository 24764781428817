import React from 'react';
import {
	TableList,
	ComparisonCard,
	ContentSection,
} from 'components/lib';
import {
	CallsListData,
	mockAppointmData,
	AppointmPlaceholdrStr,
} from 'data/mockData';

export function SalesAppointments() {
	return (
		<>
			<>
				<ContentSection type='large' dropdown selectLabel='compare to'>
					<ComparisonCard
						data={mockAppointmData}
						placeholderString={AppointmPlaceholdrStr}
						width='100%'
					/>
				</ContentSection>
				<ContentSection disableHeader>
					<TableList data={CallsListData} />
				</ContentSection>
			</>
		</>
	);
}
