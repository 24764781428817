import React, { useEffect, useState } from 'react';
import {
	Grid,
	Typography,
	Avatar,
	Divider,
	Paper,
	Select,
	MenuItem,
} from '@material-ui/core';

import {
	ContentSection,
	useCheckAuth,
	PageWrapper,
	Wrapper,
	Loader,
	NavTabs,
	TableList,
	useAgentInfo,
	apiAxiosCall,
	dataValidator,
	percIncrease,
	ChartBare,
	getDate,
} from 'components/lib';

import { makeStyles } from '@material-ui/core/styles';
import Style from 'components/global.scss';

import CompariosonStyle from './agentView.module.scss';

import { ManagerAgentBarChart } from 'data/chartData';

const listTabsTemplate = [
	{ label: `Active`, chip: 0 },
	{ label: 'Not Contacted', chip: 0 },
];

const kpiTemplate = [
	{
		title: 'Total Leads',
		subtitle: 'Over past 30 days',
		number: false,
		ratio: false,
	},

	{
		title: 'Yesterday’s Calls',
		subtitle: 'Excl. weekends',
		number: false,
		ratio: false,
	},

	{
		title: 'Conversations',
		subtitle: 'Over past 30 days',
		number: false,
		ratio: false,
	},

	{
		title: 'Appointments',
		subtitle: 'Scheduled in next 30 days',
		number: false,
		ratio: false,
	},
	{
		title: 'Appointments held',
		subtitle: 'Over past 30 days',
		number: false,
		ratio: false,
	},
];

const kpiRawTemplate = {
	leads: {
		title: 'Total Leads',
		subtitle: 'Over past 30 days',
		number: false,
		ratio: false,
	},

	yesterdayCalls: {
		title: 'Yesterday’s Calls',
		subtitle: 'Excl. weekends',
		number: false,
		ratio: false,
	},

	conversationsCount: {
		title: 'Conversations',
		subtitle: 'Over past 30 days',
		number: false,
		ratio: false,
	},

	appointmentCount: {
		title: 'Appointments',
		subtitle: 'Scheduled in next 30 days',
		number: false,
		ratio: false,
	},
	showingsCount: {
		title: 'Appointments held',
		subtitle: 'Over past 30 days',
		number: false,
		ratio: false,
	},
};

const listTemplate = {
	headerName: '',
	labels: [
		'Customer',
		'updated At',
		'LEAD AGE (days)',
		'LEAD SOURCE',
		'CLOSED',
		'NOTES',
	],
	dataSet: [],
	customCells: [
		{ index: 0, celltype: 'align-left' },
		{ index: 1, celltype: 'date' },
		{ index: 5, celltype: 'button' },
	],
	coumnsWithoutSorting: [4],
	cellsFixedSize: [],
};

const initState = {
	//

	leadsList: listTemplate,
	leadsListNotContacted: listTemplate,
	listTabs: listTabsTemplate,

	kpi: kpiTemplate,
	loading: true,
	loaded: false,
	chart: false,
	currentAgentId: false,
};

const BarChartSection = (props) => {
	const { chart, loading } = props;

	return (
		<Wrapper customStyle={{ width: '100%' }}>
			{loading ? (
				<Grid
					item
					container
					justify='center'
					alignItems='center'
					style={{ height: '100%', minHeight: 292 }}
				>
					<Loader />
				</Grid>
			) : (
				<ChartBare
					chartData={chart}
					customStyle={{ width: '100%', minHeight: 292 }}
				/>
			)}
		</Wrapper>
	);
};

const TrendIcon = (props) => {
	const { ratio = false } = props;
	return (
		ratio && (
			<Paper
				elevation={0}
				classes={{ root: CompariosonStyle['comparison-paper-container'] }}
			>
				<Typography color='textPrimary' variant='h5'>
					{/* ${ratio.trendVal > 0 ? '+' : ratio.trendVal < 0 ? '-' : ''} */}
					{`${ratio.percentage}%`}
				</Typography>
			</Paper>
		)
	);
};
const KPISummaryCard = (props) => {
	const classes = useStyles();
	const {
		title = false,
		subtitle = false,
		number = false,
		ratio = false,
	} = props;

	const SummaryCardClasses = {
		root: classes.SummeryCardRoot,
	};

	return (
		<Grid item style={{ maxHeight: 60 }}>
			<Wrapper classes={SummaryCardClasses}>
				<Grid
					container
					direction='row'
					justify='flex-start'
					alignItems='center'
					spacing={1}
				>
					<Grid item>
						<Typography variant='subtitle1'>{title}</Typography>
					</Grid>
					<Grid item>
						<Typography color='textSecondary' variant='h6'>
							{subtitle}
						</Typography>
					</Grid>
					<Grid item>{number}</Grid>
					<Grid item>
						<TrendIcon ratio={ratio} />
					</Grid>
				</Grid>
			</Wrapper>
		</Grid>
	);
};

export function AgentView(props) {
	useCheckAuth();
	const classes = useStyles();
	const { agentId, listOfAgentsShort, updateAgent } = useAgentInfo();
	const [state, setState] = useState(initState);
	const [viewIndex, setViewIndex] = useState(0);

	const handleViewChange = (event) => {
		setViewIndex(event);
	};

	const calculateTrend = (newVal, oldVal) => {
		let percentage = percIncrease(newVal, oldVal);
		let trendVal = Math.sign(percentage);
		return { percentage, trendVal };
	};

	const generateKPI = (data) => {
		if (!data) return false;
		const { now, old } = data;
		const newData = {
			leads: now.leadsCount,
			leadsOld: old.leadsCount,
			yesterdayCalls: now.yesterdayCalls,
			yesterdayCallsOld: old.yesterdayCalls,
			conversationsCount: now.conversationsCount,
			conversationsCountOld: old.conversationsCount,
			appointmentCount: now.appointmentCount,
			appointmentCountOld: old.appointmentCount,
			showingsCount: now.showingsCount,
			showingsCountOld: old.showingsCount,
		};
		let templateRaw = { ...kpiRawTemplate };
		for (const key in newData) {
			const index = Object.keys(newData).indexOf(key);
			if (index % 2 == 0) {
				templateRaw[key].number = newData[key];
				templateRaw[key].ratio = calculateTrend(newData[key], newData[`${key}Old`]);
			}
		}
		return [
			templateRaw.leads,
			templateRaw.yesterdayCalls,
			templateRaw.conversationsCount,
			templateRaw.appointmentCount,
			templateRaw.showingsCount,
		];
	};

	const generateList = (data, Contacted) => {
		if (!data) return;
		const newData = data
			.filter((lead) => {
				if ((Contacted && lead.isContacted) || (!Contacted && !lead.isContacted)) {
					return true;
				} else {
					return false;
				}
			})
			.map((lead) => {
				return {
					values: [
						[dataValidator(lead.name)],
						[dataValidator(lead.updated)],
						[dataValidator(lead.age)],
						[dataValidator(lead.source)],
						[lead.closed ? getDate(lead.closed, true) : 'N/A'],
						[['view', 'primary']],
					],
					id: lead.fubId,
				};
			});
		let template = { ...listTemplate };
		template.dataSet = newData;
		return template;
	};

	const generateChart = (data) => {
		if (data) {
			let temp = JSON.parse(JSON.stringify(ManagerAgentBarChart));
			temp.series.push({
				name: '',
				data,
				color: '#6179E5',
			});
			temp = {
				...temp,
				tooltip: {
					formatter: function () {
						return `${getDate(this.x, true)}<br/><b>${this.y}</b>`;
					},
				},
			};

			return temp;
		}
		return false;
	};

	const populateState = (data) => {
		try {
			const kpi = generateKPI(data);
			const leadsList = generateList(data.leads, true);
			const leadsListNotContacted = generateList(data.leads);
			const chart = generateChart(data.calls);

			const tabs = listTabsTemplate.map((t, indx) => {
				let tab = { ...t };
				if (indx === 0) {
					tab.chip = leadsList.dataSet.length;
				} else {
					tab.chip = leadsListNotContacted.dataSet.length;
				}
				return tab;
			});

			setState((prevState) => {
				return {
					...prevState,
					kpi,
					leadsList,
					leadsListNotContacted,
					chart,
					listTabs: tabs,
					currentAgentId: agentId,
					loading: false,
				};
			});
		} catch (error) {
			console.log('error in agent view', error);
		}
	};

	useEffect(() => {
		if (agentId) {
			if (agentId !== state.currentAgentId) {
				setState({ ...initState, loading: true });
				apiAxiosCall(`/api/metrics/management/ISA/${agentId}`).then((res) => {
					if (!res.err) {
						populateState(res.data);
					}
				});
			}
		} else {
			window.location = '/isas';
		}
	}, [agentId]);

	const listBtnClb = (el, row) => {
		if (row.id) {
			const url = `https://reefpointrealty.followupboss.com/2/people/view/${row.id}`;
			url.length > 0 && window.open(url, '_blank');
		}
	};

	const selectAnotherAgent = (event) =>
		updateAgent({ agentId: event.target.value });

	return (
		<PageWrapper>
			<Wrapper classes={{ root: classes.selectHeader }}>
				<Grid
					container
					direction='row'
					justify='flex-start'
					alignItems='center'
					spacing={1}
				>
					{listOfAgentsShort && (
						<Select
							labelId='label'
							id='select'
							value={agentId}
							classes={{ root: classes.selectListBase }}
							onChange={selectAnotherAgent}
						>
							{listOfAgentsShort.map((agent) => {
								return (
									<MenuItem value={agent.id} key={agent.id}>
										<Grid
											container
											direction='row'
											justify='flex-start'
											alignItems='center'
											spacing={1}
										>
											<Grid item>
												<Avatar
													style={{
														height: 24,
														width: 24,
													}}
													src={
														agent.avatarId
															? process.env.REACT_APP_API_URI + `/image/${agent.avatarId}`
															: ''
													}
												></Avatar>
											</Grid>
											<Grid item>
												<Typography variant='h4'>{agent.name}</Typography>
											</Grid>
										</Grid>
									</MenuItem>
								);
							})}
						</Select>
					)}
					{state.loading && <Loader />}
				</Grid>
			</Wrapper>
			<Divider style={{ marginBottom: '2.5em', height: 0 }} />
			<ContentSection title='' disableButton>
				<Grid
					container
					direction='row'
					justify='space-between'
					alignItems='center'
					spacing={1}
				>
					<Grid item xs={5}>
						<ContentSection title='KPI Summary' disableButton>
							<Grid
								item
								container
								direction='column'
								justify='flex-start'
								alignItems='stretch'
								spacing={1}
							>
								{state.kpi?.map((item) => {
									return (
										<KPISummaryCard
											title={item.title}
											subtitle={item.subtitle}
											number={item.number}
											ratio={item.ratio}
											key={Math.random()}
										/>
									);
								})}
							</Grid>
						</ContentSection>
					</Grid>
					<Grid item xs={7}>
						<ContentSection title='Calls per day' disableButton>
							<BarChartSection chart={state.chart} />
						</ContentSection>
					</Grid>
				</Grid>
			</ContentSection>
			<ContentSection disableButton title=' '>
				<Grid container direction='row' justify='space-between' alignItems='center'>
					<Grid item>
						<NavTabs
							changeView={handleViewChange}
							items={state.listTabs}
							secondary
							viewIndex={viewIndex}
						/>
					</Grid>
				</Grid>

				<TableList
					callBack={listBtnClb}
					initialOrderBy='closed'
					initialOrder='desc'
					minHeight='80vh'
					data={viewIndex === 1 ? state.leadsListNotContacted : state.leadsList}
				/>
			</ContentSection>
		</PageWrapper>
	);
}

const useStyles = makeStyles((theme) => ({
	selectHeader: {
		width: '100%',
		height: 43,
		padding: 5,
		marginTop: 3,

		position: 'fixed',
		zIndex: 3,
		left: 0,
		paddingLeft: `calc(${Style['sidebar-width1']} + 1.4em)`,
	},
	SummeryCardRoot: {
		minHeight: 52,
		padding: "10px 2px 10px 8px",
		maxHeight: 60,
		width: 450 },

	selectListBase: {
		minWidth: 90,
		maxHeight: 27,
		width: 250,
		display: 'flex',
		flexFlow: 'row',
		flexWrap: 'nowrap',
	},
}));
