import React, { useContext } from 'react';
import { Container, Grid, Button, Avatar, Typography } from '@material-ui/core';
import { AuthContext, History } from 'components/lib';

const rolesPriority = [
	'Team Leader',
	'Sales Manager',
	'ISA Manager',
	'Marketing Manager',
	'Ops Director ',
	'Agent',
	'ISA',
	'Client Care M',
	'Chief Mindset Officer',
	'Assistant',
];

export default function NavUser(props) {
	let user = {};
	user = { ...JSON.parse(localStorage.getItem('user')) };

	const context = useContext(AuthContext);
	let bool = false;
	return (
		<Container
			maxWidth='sm'
			style={{
				// position: 'absolute',
				//bottom: '1em',
				//height: "100%",

				//padding:0
				paddingBottom: 50,
			}}
			fixed={true}
			//
		>
			<Grid
				container
				direction='column'
				justify='space-between'
				alignItems='stretch'
				spacing={1}
			>
				<Grid
					container
					item
					direction='row'
					justify='flex-start'
					alignItems='center'
					style={{
						cursor: 'pointer',
					}}
					onClick={() => History.push('/profile')}
				>
					<Grid
						container
						item
						xs={3} //spacing={3}
					>
						<Avatar
							src={
								user.avatarId
									? process.env.REACT_APP_API_URI + `/image/${user.avatarId}`
									: ''
							}
						/>
					</Grid>

					<Grid
						container
						item
						direction='column'
						justify='center'
						alignItems='center'
						xs={6} //spacing={3}
					>
						<Typography
							variant='h5'
							gutterBottom
							align='center'
							style={{ paddingTop: 5 }}
						>
							{user.firstName ? user.firstName : 'no name provided'}
							{` `}
							{user.lastName ? user.lastName : 'no lastname provided'}
						</Typography>
						<Typography
							variant='h6'
							gutterBottom
							align='center'
							color='textSecondary'
						>
							{user.roles?.[0]
								? rolesPriority.map((priorityRole) => {
										let roleLabel = false;
										user.roles.filter((role, index) => {
											if (role.name.toLowerCase() === priorityRole.toLowerCase()) {
												roleLabel = `${role.name}`;
											}
											return false;
										});
										if (roleLabel && !bool) {
											bool = true;
											return roleLabel;
										}
								  })
								: 'no role provided'}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					item
					//xs={6} sm={3}
				>
					<Button
						variant='outlined'
						fullWidth
						size='large'
						onClick={() => context.signout()}
					>
						Sign out
					</Button>
				</Grid>
			</Grid>
		</Container>
	);
}
