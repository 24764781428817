import React from 'react';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { CardMUI } from '../../lib';
import Style from './stats.module.scss';

export function StatsCard(props) {
	const {
		children,
		Data = [],
		//noElevation = false,
		alt = false,
		largeNum = false,
		gutterFirst = true,
		//noPadding = false,
		compact = false,
		loading = false,
	} = props;
	//const Data = mockSalesData;
	return (
		<div className={Style.gridContainer}>
			{Data &&
				Data.map((i, index) => {
					return (
						<Grid
							item
							className={compact ? Style['gridItem-compact'] : Style.gridItem}
							key={index}
						>
							{loading ? (
								<Grid
									item
									container
									direction='column'
									justify='center'
									alignItems='center'
									style={{ height: '100%' }}
								>
									<CircularProgress />
								</Grid>
							) : (
								<CardMUI
									label={i.label}
									number1={i.number1}
									number2={i.number2}
									key={index}
									//noElevation={noElevation}
									noPadding={compact}
									customClass={
										index < 1
											? Style['grid-card-start']
											: index === Data.length - 1
											? Style['grid-card-end']
											: Style['grid-card']
									}
									//classes={}
								>
									<>
										{alt || (
											<Typography variant='h2' gutterBottom={gutterFirst}>
												{i.number1}
											</Typography>
										)}

										{alt || (
											<Typography color='textPrimary' gutterBottom variant='h5'>
												{i.label}
											</Typography>
										)}
										{alt && (
											<Typography
												color='textPrimary'
												gutterBottom={gutterFirst}
												variant={largeNum ? 'h3' : 'h4'}
											>
												{i.number1}
											</Typography>
										)}
										{alt && (
											<Typography
												color='textSecondary'
												variant='h5'
												style={{ whiteSpace: 'nowrap' }}
											>
												{i.label}
											</Typography>
										)}
										{alt || (
											<Typography color='textSecondary' variant='h5'>
												{i.number2}
											</Typography>
										)}
										{children}
									</>
								</CardMUI>
							)}
						</Grid>
					);
				})}
		</div>
	);
}
