import React from 'react';

import {
	ComparisonCard,
	ContentSection,
	LineChart,
	PieChart,
	TableList,
} from 'components/lib';
import {
	mockCompareData,
	CompareFieldsPlaceholderStr,
	mockCompareDataSecond,
	CompareStrSecond,
	leadsDta,
} from 'data/mockData';

export function SalesLeads(props) {
	const [state, setState] = React.useState({
		checkedA: false,
	});

	const handleChange = (event) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};
	return (
		<>
			<ContentSection type='large' dropdown selectLabel='compare to'>
				<ComparisonCard
					data={mockCompareData}
					placeholderString={CompareFieldsPlaceholderStr}
					width='100%'
				/>
			</ContentSection>
			<ContentSection
				type='lineChart'
				title={!state.checkedA ? 'Average ROI ($) over time' : null}
				toggleSwitch={handleChange}
				switchState={state.checkedA}
			>
				{!state.checkedA ? (
					<>
						<ComparisonCard
							data={mockCompareDataSecond}
							placeholderString={CompareStrSecond}
						/>
						<div
							style={{
								backgroundColor: '#171c22',
								width: 2,
								height: '100%',
								position: 'relative',
							}}
						>
							<div
								style={{
									backgroundColor: '#171c22',
									width: 2,
									height: '100%',
									position: 'absolute',
									top: '-15%',
								}}
							></div>
						</div>

						<PieChart />
					</>
				) : (
					<LineChart />
				)}
			</ContentSection>
			<ContentSection disableHeader>
				<TableList data={leadsDta} />
			</ContentSection>
		</>
	);
}
