import React, { useState, useEffect } from 'react';
import {
	PageWrapper,
	ContentSection,
	NavTabs,
	TableList,
	Wrapper,
	SwitchBtns,
	SwitchBtnsPlchld,
	ChartAlt as LineChart,
	ComparisonCard,
	Loader,
	useAPI,
} from 'components/lib';

import { Grid, Divider, CircularProgress } from '@material-ui/core';

import {
	generateList,
	generateLineChart,
	initState,
	generateBarChart,
	transactionsStatsTemp,
	revenueStatsTemp,
	generateStats,
} from './transactionsHelpers';
import { BarChartSection } from './transactionsComponents';

export function Transactions() {
	const [state, setState] = useState(initState);
	const [stateDta, setStateDta] = useState();
	const [viewIndex, setViewIndex] = React.useState(0);
	const handleViewChange = (event) => {
		setViewIndex(event);
	};

	const transactions = useAPI('/api/transactions');

	useEffect(() => {
		if (transactions.loading) setState({ ...state, loading: true });
		if (transactions.data && Object.keys(transactions.data).length > 0) {
			//console.log('transactions', transactions);
			const data = { ...transactions.data };
			const currentData30 = data['30'];
			const currentData90 = data['90'];
			const currentData365 = data['365'];

			//console.log('data pred ', currentData365);
			const SalesChrt = generateLineChart(
				currentData365.sales,
				currentData365.predictions
			);
			//buyersCount
			//sellersCount
			//total - currentData30.transactions
			const barChartFooter = {
				30: {
					buyersCount: currentData30.buyersCount,
					sellersCount: currentData30.sellersCount,
					total: currentData30.buyersCount + currentData30.sellersCount,
				},
				90: {
					buyersCount: currentData90.buyersCount,
					sellersCount: currentData90.sellersCount,
					total: currentData90.buyersCount + currentData90.sellersCount,
				},
				365: {
					buyersCount: currentData365.buyersCount,
					sellersCount: currentData365.sellersCount,
					total: currentData365.buyersCount + currentData365.sellersCount,
				},
			};
			const listAll = {
				30: generateList(currentData30.transactions, state.listAll),
				90: generateList(currentData90.transactions, state.listAll),
				365: generateList(currentData365.transactions, state.listAll),
			};
			const listSellers = {
				30: generateList(currentData30.transactions, state.listSellers, 'seller'),
				90: generateList(currentData90.transactions, state.listSellers, 'seller'),
				365: generateList(currentData365.transactions, state.listSellers, 'seller'),
			};
			const listBuyers = {
				30: generateList(currentData30.transactions, state.listBuyers, 'buyer'),
				90: generateList(currentData90.transactions, state.listBuyers, 'buyer'),
				365: generateList(currentData365.transactions, state.listBuyers, 'buyer'),
			};

			const transactionsStats = {
				30: generateStats(
					transactionsStatsTemp,
					currentData30.buyersCount + currentData30.sellersCount,
					currentData30.cancelled
				),
				90: generateStats(
					transactionsStatsTemp,
					currentData90.buyersCount + currentData90.sellersCount,
					currentData90.cancelled
				),
				365: generateStats(
					transactionsStatsTemp,
					currentData365.buyersCount + currentData365.sellersCount,
					currentData365.cancelled
				),
			};
			const revenueStats = {
				30: generateStats(
					revenueStatsTemp,
					currentData30.allProfit,
					currentData30.projectedProfit,
					true
				),
				90: generateStats(
					revenueStatsTemp,
					currentData90.allProfit,
					currentData90.projectedProfit,
					true
				),
				365: generateStats(
					revenueStatsTemp,
					currentData365.allProfit,
					currentData365.projectedProfit,
					true
				),
			};
			const RevenueChrt = {
				30: generateBarChart(currentData30),
				90: generateBarChart(currentData90),
				365: generateBarChart(currentData365),
			};

			setStateDta({
				...state,

				listAll,
				listSellers,
				listBuyers,
				SalesChrt,
				RevenueChrt,
				revenueStats,
				transactionsStats,
				barChartFooter,
			});
			//SalesChrt
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transactions]);

	const handleTimeSelector = async (index, val) => {
		let indx = parseInt(index);
		switch (indx) {
			case 0:
				//RevenueChrt
				await setState((previousState) => {
					return {
						...previousState,
						RevenueChrt: stateDta.RevenueChrt[val],
						revenueStats: stateDta.revenueStats[val],
						transactionsStats: stateDta.transactionsStats[val],
						barChartFooter: stateDta.barChartFooter[val],
						loading: false,
					};
				});
				break;
			case 1:
				await setState((previousState) => {
					return {
						...previousState,
						listAll: stateDta.listAll[val],
						listSellers: stateDta.listSellers[val],
						listBuyers: stateDta.listBuyers[val],
						listTabs: [
							{ label: `All`, chip: stateDta.listAll[val].dataSet.length },
							{ label: 'Buyers', chip: stateDta.listBuyers[val].dataSet.length },
							{ label: 'Sellers', chip: stateDta.listSellers[val].dataSet.length },
						],
						SalesChrt: stateDta.SalesChrt,
						loading: false,
					};
				});

				break;
			default:
				break;
		}
	};

	return (
		<PageWrapper>
			<Divider style={{ marginBottom: '1em', height: 0 }} />
			<ContentSection title='Sales Volume' disableButton>
				<ContentSection type='large'>
					{state.loading ? (
						<Loader wrapped />
					) : (
						<LineChart chartData={state.SalesChrt} />
					)}
				</ContentSection>
			</ContentSection>
			<ContentSection
				title='Trasactions Overview'
				disableButton
				headerEl={
					stateDta ? (
						<SwitchBtns clb={handleTimeSelector} indx='0' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				<Grid
					container
					//spacing={1}
					direction='row'
					justify='space-between'
					alignItems='stretch'
				>
					<Grid item xs={8}>
						<BarChartSection state={state} />
					</Grid>
					<Grid
						item
						container
						spacing={1}
						direction='column'
						justify='flex-end'
						//	alignItems='center'
						xs={4}
						style={{ paddingBottom: 1 }}
					>
						<Grid item>
							<Wrapper>
								{state.transactionsStats ? (
									<ComparisonCard
										data={state.transactionsStats}
										width='100%'
										noBorders
									/>
								) : (
									<Grid
										item
										container
										direction='column'
										justify='center'
										alignItems='center'
										style={{ minHeight: 136, width: '100%' }}
									>
										<CircularProgress />
									</Grid>
								)}
							</Wrapper>
						</Grid>
						<Grid item>
							<Wrapper>
								{state.revenueStats ? (
									<ComparisonCard data={state.revenueStats} width='100%' noBorders />
								) : (
									<Grid
										item
										container
										direction='column'
										justify='center'
										alignItems='center'
										style={{ minHeight: 136, width: '100%' }}
									>
										<CircularProgress />
									</Grid>
								)}
							</Wrapper>
						</Grid>
					</Grid>
				</Grid>
			</ContentSection>
			<ContentSection title=' ' disableButton>
				<Grid container direction='row' justify='space-between' alignItems='center'>
					<Grid item>
						<NavTabs
							changeView={handleViewChange}
							items={state.listTabs}
							secondary
							viewIndex={viewIndex}
						/>
					</Grid>
					<Grid item>
						{stateDta ? (
							<SwitchBtns clb={handleTimeSelector} indx='1' />
						) : (
							<SwitchBtnsPlchld />
						)}
					</Grid>
				</Grid>

				<TableList
					initialOrderBy='commision'
					initialOrder='desc'
					minHeight='80vh'
					data={
						viewIndex === 1
							? state.listBuyers
							: viewIndex === 2
							? state.listSellers
							: state.listAll
					}
				/>
			</ContentSection>
		</PageWrapper>
	);
}
