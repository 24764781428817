/***
 *
 *   HEADER
 *   Header section with title and optional buttons used in dashboard section and as an app header
 *
 *   PROPS
 *   title: title of the header (optional)
 *   btn: render buttons (optional)
 *       -btnTitle: button title
 *       -btnOnClick: click handle fn
 * 	 dropdown: render dropdown component
 * 	 type: choose between standartized layout
 *   can pass the children components between the title and button (optional)
 *
 * TODO: custom buttons, readd dropdown menu section
 **********/

import React, { useEffect, useState } from 'react';
import Style from './headerMUIGen.module.scss';
import { Button, Grid, Typography } from '@material-ui/core';
import { SelectMUI as Select } from '../lib';
import ClassNames from 'classnames';

import { useAgentInfo } from 'components/helpers/context/agent';
import { useLocation } from 'react-router-dom';

const ConditionalWrapper = ({ condition, wrapper, children }) =>
	condition ? wrapper(children) : children;

export function HeaderMUIGen(props) {
	const {
		type,
		title,
		btnTitle,
		btn,
		btnOnClick,
		children,
		dropdown,
		selectLabel,
	} = props;
	const location = useLocation();
	const [customHeader, setCustomHeader] = useState(false);
	const [timeframeHeader, setTimeframeHeader] = useState(false);
	const { agentName, agentType } = useAgentInfo();
	useEffect(() => {
		if (location.pathname === '/osas/agent' && agentType === 'osa') {
			setCustomHeader(agentName);
		}

		if (location.pathname === '/isas/agent' && agentType === 'isa') {
			setCustomHeader(agentName);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [agentName]);

	useEffect(() => {
		if (agentType === 'osa' && location.pathname !== '/osas/agent') {
			setCustomHeader(false);
		} else if (agentType === 'isa' && location.pathname !== '/isas/agent') {
			setCustomHeader(false);
		}
		//crude addition of custom header el
		else if (
			location.pathname === '/marketing/overview' ||
			location.pathname === '/marketing/leads'
		) {
			setTimeframeHeader('90');
		} else if (
			location.pathname === '/manager/overview' ||
			location.pathname === '/manager/leads'
		) {
			setTimeframeHeader('30');
		} else if (
			location.pathname !== '/marketing/overview' ||
			location.pathname !== '/marketing/leads' ||
			location.pathname !== '/manager/overview' ||
			location.pathname !== '/manager/leads'
		) {
			setTimeframeHeader(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const css = ClassNames([
		type && Style[type],
		props.largeHeader && Style.gridG,
	]);

	return (
		<ConditionalWrapper
			//condition={type ? true : false}
			condition={type === 'largeHeader' || type === 'largeHeader-nosidebar'}
			wrapper={(children) => <div className={css}>{children}</div>}
		>
			<Grid
				className={type ? Style.gridG : null}
				container
				item
				direction='row'
				justify={type === 'contentSectionAlt' ? 'flex-end' : 'space-between'}
				alignItems='flex-start'
			>
				{title && (
					<Grid
						item
						// container
						// direction='row'
						// justify='flex-start'
						// alignItems='center'
					>
						<Typography
							variant={type ? 'h4' : 'subtitle1'}
							align='center'
							color='textPrimary'
							style={{ display: 'flex' }}
						>
							{`${title}${
								customHeader && type === 'largeHeader' ? ' - ' + customHeader : ''
							} `}
							{timeframeHeader && type === 'largeHeader' && (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
									}}
								>
									<Typography
										variant='subtitle1'
										align='center'
										color='textSecondary'
										style={{ marginLeft: 7 }}
									>
										{`(last ${timeframeHeader} days)`}
									</Typography>
								</div>
							)}
						</Typography>
					</Grid>
				)}
				{children}
				{btn && (
					<Grid item>
						<Button variant='outlined' size='large' onClick={() => btnOnClick()}>
							{btnTitle}
						</Button>
					</Grid>
				)}
				{dropdown && <Select selectLabel={selectLabel} />}
			</Grid>
		</ConditionalWrapper>
	);
}
//compare to/time dropdown && general dropdown with background/alt background
