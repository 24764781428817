import React, { useEffect, useState } from 'react';
import { Grid, Typography, Avatar } from '@material-ui/core';

import {
	ContentSection,
	useCheckAuth,
	PageWrapper,
	Loader,
	useAPI,
	TableList,
	apiAxiosCall,
	dataValidator,
	getDate,
	NavTabs,
} from 'components/lib';

const listTemplate = {
	headerName: '',
	labels: ['Customer', 'created at', 'AGENT', 'LEAD SOURCE', 'CLOSED', 'NOTES'],
	dataSet: [],
	customCells: [
		{ index: 0, celltype: 'align-left' },
		{ index: 1, celltype: 'date' },
		{ index: 5, celltype: 'button-disableable' },
	],
	coumnsWithoutSorting: [4],
	cellsFixedSize: [],
};

const listTabsTemplate = [
	{ label: `Not called 3 times in first 12 hours`, chip: 0 },
	{ label: 'Not Contacted', chip: 0 },
	{ label: 'Not Tagged', chip: 0 },
	{ label: 'Appointments Not Held', chip: 0 },
	{ label: '', chip: 0 },
];

export function ISAManagerLeads(props) {
	useCheckAuth();

	const [state, setState] = useState({
		leadsList: listTemplate,
		leadsListUnfiltered: listTemplate,
		rawList: false,
		loading: true,
		loaded: false,
	});

	const compileList = (data, filter = false) => {
		if (!data) return;
		const newData = data
			.filter((lead) => {
				if ((filter && lead[filter] === true) || !filter) {
					return true;
				} else {
					return false;
				}
			})
			.map((lead) => {
				return {
					values: [
						[dataValidator(lead.name)],
						[dataValidator(lead.created)],
						[dataValidator(lead.isa)],
						[dataValidator(lead.source)],
						[lead.closed ? getDate(lead.closed, true) : 'N/A'],
						[['view', 'primary']],
					],
					id: lead.fubId,
				};
			});

		let template = { ...state.leadsList };
		template.dataSet = newData;
		return template;
	};

	useEffect(() => {
		apiAxiosCall('/api/metrics/management/ISA/leads').then((res) => {
			if (!res.err) {
				const list = compileList(res.data);
				//console.log('leads res', res.data);
				setState((previousState) => {
					return {
						...previousState,
						loading: false,
						leadsList: list,
						leadsListUnfiltered: list,
						rawList: res.data,
					};
				});
			} else {
				setState((previousState) => {
					return { ...previousState, loading: false };
				});
			}
		});
	}, []);

	const handleBtnClick = (el, row) => {
		if (row.id) {
			const url = `https://reefpointrealty.followupboss.com/2/people/view/${row.id}`;
			url.length > 0 && window.open(url, '_blank');
		}
	};

	const handleViewChange = (filter) => {
		/*
		0 - not called 		// notCalled
		1 - not contacted 	// isContacted \\notContacted
		2 - not tagged 		// notTagged
		3 - no appointment 	// apptNotHeld
		4 - no filters		// state.leadsList
		 */
		let filteredList;
		switch (filter) {
			case 0:
				filteredList = compileList(state.rawList, 'notCalled');
				break;
			case 1:
				filteredList = compileList(state.rawList, 'notContacted');
				break;
			case 2:
				filteredList = compileList(state.rawList, 'notTagged');
				break;
			case 3:
				filteredList = compileList(state.rawList, 'apptNotHeld');
				break;
			case 4:
				filteredList = state.leadsListUnfiltered;
				break;
			default:
				break;
		}
		setState((previousState) => {
			return {
				...previousState,
				leadsList: filteredList,
			};
		});
	};

	return (
		<PageWrapper>
			<ContentSection title='Lead Summary' disableButton>
				{state.loading ? (
					<Loader />
				) : (
					<>
						<Grid
							container
							direction='row'
							justify='space-between'
							alignItems='center'
						>
							<Grid
								item
								//xs={8}
							>
								<NavTabs
									changeView={handleViewChange}
									items={listTabsTemplate}
									secondary
									disableChips
									outlined
									deselect={4}

									//viewIndex={viewIndex}
								/>
							</Grid>
						</Grid>
						<TableList
							data={state.leadsList}
							initialOrderBy='CLOSED'
							initialOrder='desc'
							callBack={handleBtnClick}
						/>
					</>
				)}
			</ContentSection>
		</PageWrapper>
	);
}
