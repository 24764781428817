import React from 'react';
import { ISADetailed } from './ISADetailed';
import { PageWrapper } from 'components/lib';

export function TempISA(props) {
	return (
		<PageWrapper>
			<ISADetailed
				apiCall={
					'/api/metrics/?isa=isa&tag=leaderboard&field=appointmentCount&order=DESC'
				}
			/>
		</PageWrapper>
	);
}
