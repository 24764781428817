import React, { useEffect, useState } from 'react';
import {
	PageWrapper,
	apiAxiosCall,
	ContentSection,
	useCheckAuth,
	Loader,
	useAPI,
	TableList,
	dataValidator,
	getDate,
	NavTabs,
	currencyFormatter,
	Wrapper,
	ComparisonCard,
	ChartBare,
} from 'components/lib';

import {
	PerformanceListRow,
	ListRow,
	ChartLabelIcons,
	PieChartLegendRow,
	BarChartSection,
} from './marketingComponents';
import {
	copmpileMarketingSpend,
	generateBarChart,
	generatePieChart,
	generatePieChartLegend,
} from './marketingHelpers';

import { Grid, Typography } from '@material-ui/core';

const listTabsTemplate = [
	{ label: `All`, chip: 0 },
	{ label: 'Digital', chip: 0 },
	{ label: 'TV/Radio', chip: 0 },
	{ label: 'SOI', chip: 0 },
	{ label: 'External', chip: 0 },
];

const listTemplate = {
	headerName: '',
	labels: [
		'channel',
		'spent',
		'leads',
		'cpl',
		'APPTS:HELD',
		'ESCROW',
		'closed',
		'ROI',
	],
	dataSet: [],
	customCells: [{ index: 0, celltype: 'align-left' }],
	coumnsWithoutSorting: [0],
	cellsFixedSize: [{ index: 0, size: 210 }],
};

export function MarketingLeadSources(props) {
	const [state, setState] = useState({
		loading: false,
		listTabs: listTabsTemplate,
		listUnfiltered: false,
		viewIndex: 0,

		barChart: false,
		pieChart: false,
		pieChartLegend: false,

		marketingSpend: {
			label: 'Total marketing spend',
			number1: null,
			number2: '',
		},
	});

	const handleViewChange = (event) => {
		setState((prevState) => {
			return {
				...prevState,
				viewIndex: event,
			};
		});
	};

	const handleBtnClick = (el, row) => {};

	const generateList = (data = false, filter = false) => {
		if (data === (undefined || false)) return false;
		const newList = data
			.filter((lead) => {
				if (
					(filter && lead.category.toLowerCase() === filter.toLowerCase()) ||
					(lead.category.toLowerCase() === 'unknown' && filter === 'external') ||
					!filter
				) {
					return true;
				} else {
					return false;
				}
			})
			.map((lead) => {
				return {
					values: [
						[lead.source],
						[
							dataValidator(lead.spend)
								? currencyFormatter.format(lead.spend)
								: lead.spend === undefined
								? '-'
								: lead.spend,
						],
						[lead.leadsCount],
						//
						[isNaN(lead.cpl) ? lead.cpl : currencyFormatter.format(lead.cpl)],
						[lead.apptShowingsRatio],
						[lead.escrowCount],
						[lead.closedCount],
						[lead.roi],
					],
				};
			});

		let template = { ...listTemplate };
		template.dataSet = newList;
		return template;
	};

	const updateChips = (data = false, tabs = false) => {
		if (data === (undefined || false)) return state.listTabs;

		const clonedTabs = tabs.map((el) => {
			return { ...el };
		});
		clonedTabs.map((tab) => {
			const d = data[tab.label.toLowerCase()];
			d && (tab.chip = d.dataSet.length);
		});
		return clonedTabs;
	};

	const populateList = (data) => {
		try {
			// console.log('data lead sources', data);
			//
			const listUnfiltered = generateList(data);
			const listDigital = generateList(data, 'digital');
			const listTvRadio = generateList(data, 'TV/Radio');
			const listSOI = generateList(data, 'SOI');
			const listExternal = generateList(data, 'external');

			const listTabs = updateChips(
				{
					all: listUnfiltered,
					digital: listDigital,
					'tv/radio': listTvRadio,
					soi: listSOI,
					external: listExternal,
				},
				state.listTabs
			);

			setState((prevState) => {
				return {
					...prevState,
					loading: false,
					listUnfiltered,
					listTabs,
					listDigital,
					listTvRadio,
					listSOI,
					listExternal,
				};
			});
			//
		} catch (e) {
			console.log('error in lead sources list', e);
		}
	};

	const populateCharts = (data) => {
		try {
			// console.log('data lead sources', data);
			//
			const barChart = generateBarChart(data.costPerLead);
			const pieChart = generatePieChart(data.spent);
			const pieChartLegend = generatePieChartLegend(data.spent);
			const marketingSpend = copmpileMarketingSpend(
				data.now.totalMarketing,
				data.old.totalMarketing
			);

			setState((prevState) => {
				return {
					...prevState,
					loading: false,
					barChart,
					pieChart,
					pieChartLegend,
					marketingSpend,
				};
			});
			//
		} catch (e) {
			console.log('error in lead sources charts', e);
		}
	};

	useEffect(() => {
		setState((prevState) => {
			return {
				...prevState,
				loading: true,
			};
		});
		apiAxiosCall(`/api/marketing/sources`).then((res) => {
			if (!res.err) {
				populateList(res.data);
			}
		});
		apiAxiosCall(`/api/marketing`).then((res) => {
			if (!res.err) {
				populateCharts(res.data);
			}
		});
	}, []);
	return (
		<PageWrapper>
			<ContentSection title='' disableButton>
				<Wrapper customStyle={{ minHeight: 270, width: '100%' }}>
					<Grid
						container
						direction='row'
						justify='space-between'
						alignItems='stretch'
						style={{ minHeight: 270, width: '100%' }}
					>
						<Grid
							item
							container
							direction='row'
							justify='center'
							alignItems='center'
							xs={4}
						>
							<ComparisonCard data={[state.marketingSpend]} />
						</Grid>
						<Grid
							item
							style={{
								borderColor: '#171C22',
								borderWidth: 0,
								borderLeftWidth: 1,
								borderRightWidth: 1,
								borderStyle: 'solid',
								padding: '20px 40px',
							}}
							xs={3}
						>
							<BarChartSection chart={state.barChart} loading={state.loading} />

							<Typography variant='subtitle1' align='center'>
								Cost per Lead
							</Typography>
						</Grid>
						<Grid
							item
							xs={5}
							container
							direction='row'
							justify='center'
							alignItems='center'
						>
							<Grid item xs={6}>
								<ChartBare
									chartData={state.pieChart}
									customStyle={{ width: '100%', minHeight: 200 }}
								/>
							</Grid>
							<Grid
								item
								xs={4}
								container
								direction='column'
								justify='flex-start'
								alignItems='flex-start'
							>
								<Typography variant='h4' align='center' gutterBottom>
									Total Spent
								</Typography>
								<Grid
									item
									container
									direction='column'
									justify='flex-start'
									alignItems='flex-start'
									spacing={1}
								>
									{state.pieChartLegend &&
										state.pieChartLegend.map((el, index) => {
											return <PieChartLegendRow el={el} key={index} />;
										})}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Wrapper>
			</ContentSection>
			<ContentSection title='' disableButton>
				{state.loading ? (
					<Loader />
				) : (
					<>
						<NavTabs
							changeView={handleViewChange}
							viewIndex={state.viewIndex}
							items={state.listTabs}
							secondary
						/>

						<TableList
							data={
								state.viewIndex === 1
									? state.listDigital
									: state.viewIndex === 2
									? state.listTvRadio
									: state.viewIndex === 3
									? state.listSOI
									: state.viewIndex === 4
									? state.listExternal
									: state.listUnfiltered
							}
							initialOrderBy='CLOSED'
							initialOrder='desc'
						/>
					</>
				)}
			</ContentSection>
		</PageWrapper>
	);
}
