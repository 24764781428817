import React, { useRef, useEffect, useState } from 'react';
import {
	Grid,
	Divider,
	Popover,
	Typography,
	Paper,
	CircularProgress,
} from '@material-ui/core';

import {
	CardMUI,
	StatsCard,
	currencyFormatterCompact,
	currencyFormatter,
	percIncrease,
} from 'components/lib';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CallMadeIcon from '@material-ui/icons/CallMade';

import { makeStyles } from '@material-ui/core/styles';

import Style from './trendIcon.module.scss';

const popperTemplate = {
	label1: 'Last 90 days',
	label2: 'Last year',
	val1: '-',
	val2: '-',
};

//recheck changes //showAgreeRatio -> showAgrmntRatio

export const Semaphore = (props) => {
	const {
		data = false,
		customFields = false,
		alt = false,
		ratio = false,
	} = props;

	const classes = useStyles();
	const semaphoreClasses = {
		fontSizeSmall: classes.fontSizeSmall,
		colorAction: classes.colorAction,
		colorPrimary: classes.colorPrimary,
		colorSecondary: classes.colorSecondary,

		colorDisabled: alt ? classes.colorDisabledAlt : classes.colorDisabled,

		root: classes.root,
	};
	//
	//POPOVER
	const popperAnchor = useRef(null);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [cstFields, setCstFields] = useState(false); //customFields={[{ index: 0, type: 'currency-compact' }]}
	const handleClick = () => setAnchorEl(popperAnchor.current);
	const handleClose = () => setAnchorEl(false);
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	//Trend
	const [trend, setTrend] = React.useState([]);

	const getTrendColor = (pos) => {
		if (trend.length < 1) return 'disabled';
		const val = trend[0]?.trendVal;
		return val === -1 && pos === 1
			? 'secondary'
			: val === 0 && pos === 2
			? 'action'
			: val === 1 && pos === 3
			? 'primary'
			: 'disabled';
	};

	const calculateTrend = (newVal, oldVal) => {
		let percentage = percIncrease(newVal, oldVal);
		let trendVal = Math.sign(percentage);
		if (ratio) {
			percentage = -percentage;
			trendVal = -trendVal;
		}
		return { percentage, trendVal };
	};
	useEffect(() => {
		if (customFields && data) setCstFields(customFields);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customFields]);
	useEffect(() => {
		if (data) {
			let newTrend = [];
			data.map((popoverEl, popoverIndex) => {
				const trendElement = calculateTrend(popoverEl.val1, popoverEl.val2);
				newTrend.splice(popoverIndex, 0, trendElement);
				return popoverEl;
			});
			setTrend(newTrend);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<Grid
			item
			ref={popperAnchor}
			container
			direction='row'
			justify='center'
			alignItems='center'
			style={{
				position: 'relative',
				padding: 0,
				width: 25,
				height: 10,
				marginTop: alt ? 0 : 10,
			}}
		>
			<div
				onClick={(e) => handleClick(e)}
				style={{
					position: 'absolute',
					width: '100%',
					height: '100%',
					cursor: 'pointer',
				}}
			/>
			<FiberManualRecordIcon
				fontSize='small'
				color={getTrendColor(1)}
				classes={semaphoreClasses}
			/>
			<FiberManualRecordIcon
				fontSize='small'
				color={getTrendColor(2)}
				classes={semaphoreClasses}
			/>
			<FiberManualRecordIcon
				fontSize='small'
				color={getTrendColor(3)}
				classes={semaphoreClasses}
			/>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				classes={{ paper: classes.cardPaper }}
			>
				<Grid container direction='row' justify='center' alignItems='center'>
					{!data ? (
						<CardMUI noPadding={true} customClasses={{ root: classes.cardRoot }}>
							<Grid
								container
								direction='column'
								justify='center'
								alignItems='center'
								style={{ height: 100 }}
							>
								<Grid item>
									<Typography variant='h4' color='textPrimary'>
										NO DATA
									</Typography>
								</Grid>
							</Grid>
						</CardMUI>
					) : (
						data.map((popoverEl, popoverIndex) => {
							let currentTrend = false;
							if (trend.length > 0) currentTrend = trend[popoverIndex];
							const checkCustomField = (val) => {
								if (
									cstFields &&
									cstFields?.some(
										(el) => el.type === 'currency-compact' && el.index === popoverIndex
									)
								) {
									return typeof val === 'number';
								}

								return false;
							};
							return (
								<React.Fragment key={Math.random()}>
									<CardMUI
										noPadding={true}
										customClasses={{ root: classes.cardRoot }}
										key={Math.random()}
									>
										<Grid
											container
											direction='column'
											justify='space-around'
											alignItems='center'
											spacing={1}
										>
											<Grid item>
												<Typography variant='h4' color='textSecondary'>
													{popoverEl.header}
												</Typography>
											</Grid>
											<Grid
												item
												container
												direction='row'
												justify='space-between'
												alignItems='center'
											>
												<Typography variant='h4' color='textSecondary'>
													{popoverEl.label1}
												</Typography>
												<Typography variant='h4' color='textPrimary'>
													{checkCustomField(popoverEl.val1)
														? currencyFormatterCompact.format(popoverEl.val1)
														: popoverEl.val1}
												</Typography>
											</Grid>
											<Grid
												item
												container
												direction='row'
												justify='space-between'
												alignItems='center'
											>
												<Typography variant='h4' color='textSecondary'>
													{popoverEl.label2}
												</Typography>
												<Typography variant='h4' color='textPrimary'>
													{checkCustomField(popoverEl.val2)
														? currencyFormatterCompact.format(popoverEl.val2)
														: popoverEl.val2}
												</Typography>
											</Grid>
											<Grid item>
												<Paper
													elevation={0}
													classes={{
														root:
															Style[
																currentTrend?.trendVal === 1
																	? cstFields &&
																	  cstFields?.some(
																			(el) =>
																				el.type === 'revert-positive' && el.index === popoverIndex
																	  )
																		? 'comparison-paper-container-negative'
																		: 'comparison-paper-container-positive'
																	: currentTrend?.trendVal === -1
																	? cstFields &&
																	  cstFields?.some(
																			(el) =>
																				el.type === 'revert-positive' && el.index === popoverIndex
																	  )
																		? 'comparison-paper-container-positive'
																		: 'comparison-paper-container-negative'
																	: 'comparison-paper-container-equal'
															],
													}}
												>
													<Paper
														elevation={0}
														classes={{ root: Style['comparison-paper-item'] }}
													>
														<CallMadeIcon
															classes={{
																root:
																	Style[
																		currentTrend?.trendVal === 1
																			? 'comparison-icon-positive'
																			: currentTrend?.trendVal === -1
																			? 'comparison-icon-negative'
																			: 'comparison-icon-equal'
																	],
															}}
															fontSize='small'
														/>
													</Paper>
													<Typography color='textPrimary' variant='h4'>
														{currentTrend?.percentage ? `${currentTrend.percentage}%` : ''}
													</Typography>
												</Paper>
											</Grid>
										</Grid>
									</CardMUI>
									{data.length > 1 && popoverIndex === 0 && (
										<Divider
											orientation='vertical'
											style={{ width: '2px', backgroundColor: '#171C22' }}
										/>
									)}
								</React.Fragment>
							);
						})
					)}
				</Grid>
			</Popover>
		</Grid>
	);
};

export const SalesStats = (props) => {
	const { loading = false, data = false, timeFrame = false } = props;
	const initState = {
		sales: { label: 'Sales volume', number1: '-' },
		salesTrend: false,
		buyers: { label: 'Buyers', number1: '-' },
		buyersTrend: false,
		sellers: { label: 'Sellers', number1: '-' },
		sellersTrend: false,
		// total: { label: 'Total', number1: '-' },
		// totalTrend: false,
	};
	const [salesState, setSalesState] = useState(initState);

	useEffect(() => {
		let timeFrameLabel = timeFrame
			? timeFrame === '365'
				? 'This Year'
				: `Last ${timeFrame} days`
			: 'Current';
		let salesTrend = {
			...popperTemplate,
			label1: timeFrameLabel,
			header: 'Sales volume',
		};
		let buyersTrend = {
			...popperTemplate,
			label1: timeFrameLabel,
			header: 'Buyers',
		};
		let sellersTrend = {
			...popperTemplate,
			label1: timeFrameLabel,
			header: 'Sellers',
		};
		if (data) {
			const newSellStats = { ...salesState };
			for (var prop in data) {
				if (newSellStats[prop])
					newSellStats[prop].number1 = currencyFormatterCompact.format(data[prop]);

				switch (prop) {
					case 'salesRaw':
						salesTrend.val1 = data[prop];
						break;
					case 'salesRawOld':
						salesTrend.val2 = data[prop];
						break;
					case 'buyers':
						buyersTrend.val1 = data[prop];
						break;
					case 'buyersOld':
						buyersTrend.val2 = data[prop];
						break;
					case 'sellers':
						sellersTrend.val1 = data[prop];
						break;
					case 'sellersOld':
						sellersTrend.val2 = data[prop];
						break;
					default:
						break;
				}
			}
			setSalesState({
				...salesState,
				...newSellStats,
				salesTrend,
				buyersTrend,
				sellersTrend,
			});
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<Grid
			item
			xs={3}
			container
			direction='column'
			justify='center'
			alignItems='center'
		>
			{loading ? (
				<Grid item>
					<CircularProgress />
				</Grid>
			) : (
				<>
					<Grid item>
						<StatsCard
							loading={loading}
							Data={salesState.sales ? [salesState.sales] : [initState.sales]}
							noElevation
							alt
							largeNum
							gutterFirst={false}
						>
							<Semaphore
								data={salesState.salesTrend && [salesState.salesTrend]}
								customFields={[{ index: 0, type: 'currency-compact' }]}
							/>
						</StatsCard>
					</Grid>
					<Grid
						item
						container
						direction='row'
						justify='space-evenly'
						alignItems='center'
						wrap='nowrap'
					>
						<Grid item>
							<StatsCard
								loading={loading}
								Data={salesState.buyers ? [salesState.buyers] : [initState.buyers]}
								noElevation
								alt
								gutterFirst={false}
								compact
							>
								<Semaphore
									data={salesState.buyersTrend && [salesState.buyersTrend]}
									customFields={[{ index: 0, type: 'currency-compact' }]}
								/>
							</StatsCard>
						</Grid>
						<Grid item>
							<StatsCard
								loading={loading}
								Data={salesState.sellers ? [salesState.sellers] : [initState.sellers]}
								noElevation
								alt
								gutterFirst={false}
								compact
							>
								<Semaphore
									data={salesState.sellersTrend && [salesState.sellersTrend]}
									customFields={[{ index: 0, type: 'currency-compact' }]}
								/>
							</StatsCard>
						</Grid>
					</Grid>
				</>
			)}
		</Grid>
	);
};

export const SalesStatsBarChart = (props) => {
	const { loading = false, data = false, timeFrame = false } = props;
	const initState = {
		sales: { label: 'Sales volume', number1: '-' },
		salesTrend: false,
		buyers: { label: 'Buyers', number1: '-' },
		buyersTrend: false,
		sellers: { label: 'Sellers', number1: '-' },
		sellersTrend: false,
		total: { label: 'Total', number1: '-' },
		totalTrend: false,
	};
	const [salesState, setSalesState] = useState(initState);

	useEffect(() => {
		let timeFrameLabel = timeFrame
			? timeFrame === '365'
				? 'This Year'
				: `Last ${timeFrame} days`
			: 'Current';
		let salesTrend = {
			...popperTemplate,
			label1: timeFrameLabel,
			header: 'Sales volume',
		};
		let buyersTrend = {
			...popperTemplate,
			label1: timeFrameLabel,
			header: 'Buyers',
		};
		let sellersTrend = {
			...popperTemplate,
			label1: timeFrameLabel,
			header: 'Sellers',
		};
		let totalTrend = {
			...popperTemplate,
			label1: timeFrameLabel,
			header: 'Total',
		};
		if (data) {
			const newSellStats = { ...salesState };
			for (var prop in data) {
				if (newSellStats[prop]) newSellStats[prop].number1 = data[prop];

				switch (prop) {
					case 'salesRaw':
						salesTrend.val1 = data[prop];
						break;
					case 'salesRawOld':
						salesTrend.val2 = data[prop];
						break;
					case 'buyers':
						buyersTrend.val1 = data[prop];
						break;
					case 'buyersOld':
						buyersTrend.val2 = data[prop];
						break;
					case 'sellers':
						sellersTrend.val1 = data[prop];
						break;
					case 'sellersOld':
						sellersTrend.val2 = data[prop];
						break;
					case 'total':
						totalTrend.val1 = data[prop];
						break;
					case 'totalOld':
						totalTrend.val2 = data[prop];
						break;
					default:
						break;
				}
			}
			setSalesState({
				...salesState,
				...newSellStats,
				salesTrend,
				buyersTrend,
				sellersTrend,
				totalTrend,
			});
		}

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<Grid
			item
			xs={3}
			container
			direction='column'
			justify='center'
			alignItems='center'
		>
			{loading ? (
				<Grid item>
					<CircularProgress />
				</Grid>
			) : (
				<>
					<Grid item>
						<StatsCard
							loading={loading}
							Data={salesState.total ? [salesState.total] : [initState.total]}
							noElevation
							alt
							gutterFirst={false}
							compact
							largeNum
						>
							<Semaphore data={salesState.totalTrend && [salesState.totalTrend]} />
						</StatsCard>
					</Grid>
					<Grid
						item
						container
						direction='row'
						justify='space-evenly'
						alignItems='center'
						wrap='nowrap'
					>
						<Grid item>
							<StatsCard
								loading={loading}
								Data={salesState.buyers ? [salesState.buyers] : [initState.buyers]}
								noElevation
								alt
								gutterFirst={false}
								compact
							>
								<Semaphore data={salesState.buyersTrend && [salesState.buyersTrend]} />
							</StatsCard>
						</Grid>
						<Grid item>
							<StatsCard
								loading={loading}
								Data={salesState.sellers ? [salesState.sellers] : [initState.sellers]}
								noElevation
								alt
								gutterFirst={false}
								compact
							>
								<Semaphore
									data={salesState.sellersTrend && [salesState.sellersTrend]}
								/>
							</StatsCard>
						</Grid>
					</Grid>
				</>
			)}
		</Grid>
	);
};

export const SalesFunnel = (props) => {
	const { loading = false, data = false, timeFrame = false } = props;
	const classes = useStyles();
	const initState = {
		leads: { label: 'Leads', number1: '-', number2: '-' },
		apptSh: { label: 'Appointments showed', number1: '-', number2: '-' },
		closing: { label: 'Closing', number1: '-', number2: '-' },
		leadsTrend: false,
		apptShTrend: false,
		closingTrend: false,
		leadApptRatioTrend: false,
		apptClosedRatioTrend: false,

		leadApptRatio: '- : 1',
		apptClosedRatio: '- : 1',
	};
	const [state, setState] = useState(initState);

	//ration switch around

	useEffect(() => {
		let leadsTrend = [];
		let timeFrameLabel = timeFrame
			? timeFrame === '365'
				? 'This Year'
				: `Last ${timeFrame} days`
			: 'Current';

		let leadsValsTrend = {
			...popperTemplate,
			header: 'Leads',
			label1: timeFrameLabel,
		};
		let leadsCurrencyTrend = {
			...popperTemplate,
			header: 'Leads Cost',
			label1: timeFrameLabel,
		};
		let apptShTrend = [];
		let apptShValsTrend = {
			...popperTemplate,
			header: 'Apptmts showed',
			label1: timeFrameLabel,
		};
		let apptShPriceTrend = {
			...popperTemplate,
			header: 'Apptmts showed Cost',
			label1: timeFrameLabel,
		};
		let closingTrend = [];
		let closingValsTrend = {
			...popperTemplate,
			header: 'Closing',
			label1: timeFrameLabel,
		};
		let closingCurrencyTrend = {
			...popperTemplate,
			header: 'Revenue Generated',
			label1: timeFrameLabel,
		};

		let leadApptRatioTrend = {
			...popperTemplate,
			header: 'Lead to Appt Ratio',
			label1: timeFrameLabel,
		};

		let apptClosedRatioTrend = {
			...popperTemplate,
			header: 'Appt to Closed Ratio',
			label1: timeFrameLabel,
		};
		let leadApptRatio = false;
		let apptClosedRatio = false;

		if (data) {
			const newStats = { ...state };

			for (var prop in data) {
				switch (prop) {
					//sales funnel
					case 'leads':
						newStats[prop].number1 = data[prop];
						newStats[prop].number2 =
							typeof data['leadsPrice'] === 'number'
								? currencyFormatter.format(data['leadsPrice'])
								: data['leadsPrice'];
						leadsValsTrend.val1 = data[prop];
						leadsValsTrend.val2 = data['leadsOld'];
						leadsCurrencyTrend.val1 = data['leadsPrice'];
						leadsCurrencyTrend.val2 = data['leadsPriceOld'];
						leadsTrend = [leadsValsTrend, leadsCurrencyTrend];
						break;
					case 'apptSh':
						newStats[prop].number1 = data[prop];
						newStats[prop].number2 =
							typeof data['apptsPrice'] === 'number'
								? currencyFormatter.format(data['apptsPrice'])
								: data['apptsPrice'];
						apptShValsTrend.val1 = data[prop];
						apptShValsTrend.val2 = data['apptShOld'];
						apptShPriceTrend.val1 = data['apptsPrice'];
						apptShPriceTrend.val2 = data['apptsPriceOld'];
						apptShTrend = [apptShValsTrend, apptShPriceTrend];
						break;
					case 'closing':
						newStats[prop].number1 = data[prop];
						newStats[prop].number2 =
							typeof data['closedPrice'] === 'number'
								? currencyFormatter.format(data['closedPrice'])
								: data['closedPrice'];
						closingValsTrend.val1 = data[prop];
						closingValsTrend.val2 = data['closingOld'];
						closingCurrencyTrend.val1 = data['closedPrice'];
						closingCurrencyTrend.val2 = data['closedPriceOld'];
						closingTrend = [closingValsTrend, closingCurrencyTrend];
						break;

					case 'leadApptRatio':
						//leadApptRatioTrend

						leadApptRatioTrend.val1 =
							data[prop] === '-' ? data[prop] : parseFloat(data[prop]);

						leadApptRatioTrend.val2 =
							data['leadApptRatioOld'] === '-'
								? data['leadApptRatioOld']
								: parseFloat(data['leadApptRatioOld']);
						leadApptRatio = data[prop];

						break;
					case 'apptClosedRatio':
						apptClosedRatioTrend.val1 =
							data[prop] === '-' ? data[prop] : parseFloat(data[prop]);

						apptClosedRatioTrend.val2 =
							data['apptClosedRatioOld'] === '-'
								? data['apptClosedRatioOld']
								: parseFloat(data['apptClosedRatioOld']);
						apptClosedRatio = data[prop];
						break;
					default:
						break;
				}
			}
			setState({
				...state,
				...newStats,
				leadsTrend,
				closingTrend,
				apptShTrend,
				leadApptRatioTrend,
				apptClosedRatioTrend,
				leadApptRatio,
				apptClosedRatio,
			});
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<>
			<Grid item xs={3}>
				<StatsCard
					loading={loading}
					Data={state.leads ? [state.leads] : [initState.leads]}
					noElevation
					gutterFirst={false}
					compact
				>
					<Semaphore
						data={state.leadsTrend && state.leadsTrend}
						customFields={[
							{ index: 1, type: 'currency-compact' },
							{ index: 1, type: 'revert-positive' },
						]}
					/>
				</StatsCard>
			</Grid>
			<Grid
				item
				xs={2}
				container
				direction='column'
				justify='space-between'
				alignItems='center'
				style={{
					maxWidth: 50,
					height: '100%',
				}}
			>
				<Grid item xs={4}>
					<Divider
						orientation='vertical'
						style={{ width: '1px', backgroundColor: '#171C22' }}
					/>
				</Grid>

				<Grid
					item
					container
					direction='column'
					justify='space-evenly'
					alignItems='center'
					wrap='nowrap'
					classes={{ container: classes.containerSepaphore }}
				>
					<Typography color='textPrimary' variant='h6'>
						{state.leadApptRatio}
					</Typography>
					<Semaphore
						alt
						data={state.leadApptRatioTrend && [state.leadApptRatioTrend]}
						ratio
					/>
				</Grid>

				<Grid item xs={4}>
					<Divider
						orientation='vertical'
						style={{ width: '1px', backgroundColor: '#171C22' }}
					/>
				</Grid>
			</Grid>
			<Grid item xs={3}>
				<StatsCard
					loading={loading}
					Data={state.apptSh ? [state.apptSh] : [initState.apptSh]}
					noElevation
					gutterFirst={false}
					compact
				>
					<Semaphore
						data={state.apptShTrend && state.apptShTrend}
						customFields={[
							{ index: 1, type: 'currency-compact' },
							{ index: 1, type: 'revert-positive' },
						]}
					/>
				</StatsCard>
			</Grid>
			<Grid
				item
				xs={2}
				container
				direction='column'
				justify='space-between'
				alignItems='center'
				style={{
					maxWidth: 50,
					height: '100%',
				}}
			>
				<Grid item xs={4}>
					<Divider
						orientation='vertical'
						style={{ width: '1px', backgroundColor: '#171C22' }}
					/>
				</Grid>
				<Grid
					item
					container
					direction='column'
					justify='space-evenly'
					alignItems='center'
					wrap='nowrap'
					classes={{ container: classes.containerSepaphore }}
				>
					<Typography color='textPrimary' variant='h6'>
						{state.apptClosedRatio}
					</Typography>
					<Semaphore
						alt
						data={state.apptClosedRatioTrend && [state.apptClosedRatioTrend]}
						ratio
					/>
				</Grid>

				<Grid item xs={4}>
					<Divider
						orientation='vertical'
						style={{ width: '1px', backgroundColor: '#171C22' }}
					/>
				</Grid>
			</Grid>
			<Grid item xs={3}>
				<StatsCard
					loading={loading}
					Data={state.closing ? [state.closing] : [initState.closing]}
					noElevation
					gutterFirst={false}
					compact
				>
					<Semaphore
						data={state.closingTrend && state.closingTrend}
						customFields={[{ index: 1, type: 'currency-compact' }]}
					/>
				</StatsCard>
			</Grid>
		</>
	);
};

export const ISA = (props) => {
	const { loading = false, data = false, timeFrame = false } = props;
	const classes = useStyles();
	const initState = {
		calls: { label: 'Calls', number1: '-', number2: '-' },
		conversations: { label: 'Conversations', number1: '-', number2: '-' },
		apptH: { label: 'Appointments showed', number1: '-', number2: '-' },
		callsTrend: false,
		apptShTrend: false,
		conversationsTrend: false,

		callConvRatioTrend: false,
		convShowRatioTrend: false,

		callConvRatio: '- : 1',
		convShowRatio: '- : 1',
	};
	const [state, setState] = useState(initState);
	useEffect(() => {
		let callsTrend = [];
		let timeFrameLabel = timeFrame
			? timeFrame === '365'
				? 'This Year'
				: `Last ${timeFrame} days`
			: 'Current';
		let callsValsTrend = {
			...popperTemplate,
			label1: timeFrameLabel,
			header: 'Calls',
		};
		let callsPriceTrend = {
			...popperTemplate,
			header: 'Calls Cost',
			label1: timeFrameLabel,
		};
		let conversationsTrend = [];
		let conversationsValsTrend = {
			...popperTemplate,
			header: 'Conversations',
			label1: timeFrameLabel,
		};
		let conversationsPriceTrend = {
			...popperTemplate,
			header: 'Conversations Cost',
			label1: timeFrameLabel,
		};
		let apptShTrend = [];
		let apptShValsTrend = {
			...popperTemplate,
			header: 'Apptmnts Showed',
			label1: timeFrameLabel,
		};
		let apptShPriceTrend = {
			...popperTemplate,
			header: 'Apptmnts Showed Cost',
			label1: timeFrameLabel,
		};
		let callConvRatioTrend = {
			...popperTemplate,
			header: 'Call to Conv Ratio',
			label1: timeFrameLabel,
		};
		let convShowRatioTrend = {
			...popperTemplate,
			header: 'Convertions to Show Ratio',
			label1: timeFrameLabel,
		};
		let callConvRatio = false;
		let convShowRatio = false;
		if (data) {
			const newStats = { ...state };
			for (var prop in data) {
				switch (prop) {
					case 'calls':
						newStats[prop].number1 = data[prop];
						newStats[prop].number2 =
							typeof data['callsPrice'] === 'number'
								? currencyFormatter.format(data['callsPrice'])
								: data['callsPrice'];
						callsValsTrend.val1 = data[prop];
						callsValsTrend.val2 = data['callsOld'];
						callsPriceTrend.val1 = data['callsPrice'];
						callsPriceTrend.val2 = data['callsPriceOld'];
						callsTrend = [callsValsTrend, callsPriceTrend];
						break;
					case 'conversations':
						newStats[prop].number1 = data[prop];
						newStats[prop].number2 =
							typeof data['conversationsPrice'] === 'number'
								? currencyFormatter.format(data['conversationsPrice'])
								: data['conversationsPrice'];
						conversationsValsTrend.val1 = data[prop];
						conversationsValsTrend.val2 = data['conversationsOld'];
						conversationsPriceTrend.val1 = data['conversationsPrice'];
						conversationsPriceTrend.val2 = data['conversationsPriceOld'];
						conversationsTrend = [conversationsValsTrend, conversationsPriceTrend];
						break;
					case 'apptH':
						newStats[prop].number1 = data[prop];
						newStats[prop].number2 =
							typeof data['apptHPrice'] === 'number'
								? currencyFormatter.format(data['apptHPrice'])
								: data['apptHPriceb '];
						apptShValsTrend.val1 = data[prop];
						apptShValsTrend.val2 = data['apptHOld'];
						apptShPriceTrend.val1 = data['apptHPrice'];
						apptShPriceTrend.val2 = data['apptHOldPrice'];
						apptShTrend = [apptShValsTrend, apptShPriceTrend];
						break;
					case 'callConvRatio':
						callConvRatioTrend.val1 =
							data[prop] === '-' ? data[prop] : parseFloat(data[prop]);
						callConvRatioTrend.val2 =
							data['callConvRatioOld'] === '-'
								? data['callConvRatioOld']
								: parseFloat(data['callConvRatioOld']);
						callConvRatio = data[prop];
						break;
					case 'convShowRatio':
						convShowRatioTrend.val1 =
							data[prop] === '-' ? data[prop] : parseFloat(data[prop]);
						convShowRatioTrend.val2 =
							data['convShowRatioOld'] === '-'
								? data['convShowRatioOld']
								: parseFloat(data['convShowRatioOld']);
						convShowRatio = data[prop];
						break;
					default:
						break;
				}
			}

			setState({
				...state,
				...newStats,
				callConvRatio,
				convShowRatio,
				callsTrend,
				conversationsTrend,
				apptShTrend,
				callConvRatioTrend: callConvRatioTrend,
				convShowRatioTrend: convShowRatioTrend,
			});
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<>
			<Grid item xs={3}>
				<StatsCard
					loading={loading}
					Data={state.calls ? [state.calls] : [state.calls]}
					noElevation
					gutterFirst={false}
					compact
				>
					<Semaphore
						data={state.callsTrend && state.callsTrend}
						customFields={[
							{ index: 1, type: 'currency-compact' },
							{ index: 1, type: 'revert-positive' },
						]}
					/>
				</StatsCard>
			</Grid>
			<Grid
				item
				xs={2}
				container
				direction='column'
				justify='space-between'
				alignItems='center'
				style={{
					maxWidth: 50,
					height: '100%',
				}}
			>
				<Grid item xs={4}>
					<Divider
						orientation='vertical'
						style={{ width: '1px', backgroundColor: '#171C22' }}
					/>
				</Grid>

				<Grid
					item
					container
					direction='column'
					justify='space-evenly'
					alignItems='center'
					wrap='nowrap'
					classes={{ container: classes.containerSepaphore }}
				>
					<Typography color='textPrimary' variant='h6'>
						{state.callConvRatio}
					</Typography>
					<Semaphore
						alt
						data={state.callConvRatioTrend && [state.callConvRatioTrend]}
						ratio
					/>
				</Grid>

				<Grid item xs={4}>
					<Divider
						orientation='vertical'
						style={{ width: '1px', backgroundColor: '#171C22' }}
					/>
				</Grid>
			</Grid>
			<Grid item xs={3}>
				<StatsCard
					loading={loading}
					Data={
						state.conversations ? [state.conversations] : [initState.conversations]
					}
					noElevation
					gutterFirst={false}
					compact
				>
					<Semaphore
						data={state.conversationsTrend && state.conversationsTrend}
						customFields={[
							{ index: 1, type: 'currency-compact' },
							{ index: 1, type: 'revert-positive' },
						]}
					/>
				</StatsCard>
			</Grid>
			<Grid
				item
				xs={2}
				container
				direction='column'
				justify='space-between'
				alignItems='center'
				style={{
					maxWidth: 50,
					height: '100%',
				}}
			>
				<Grid item xs={4}>
					<Divider
						orientation='vertical'
						style={{ width: '1px', backgroundColor: '#171C22' }}
					/>
				</Grid>
				<Grid
					item
					container
					direction='column'
					justify='space-evenly'
					alignItems='center'
					wrap='nowrap'
					classes={{ container: classes.containerSepaphore }}
				>
					<Typography color='textPrimary' variant='h6'>
						{state.convShowRatio}
					</Typography>
					<Semaphore
						alt
						data={state.convShowRatioTrend && [state.convShowRatioTrend]}
						ratio
						//bug?
					/>
				</Grid>
				<Grid item xs={4}>
					<Divider
						orientation='vertical'
						style={{ width: '1px', backgroundColor: '#171C22' }}
					/>
				</Grid>
			</Grid>
			<Grid item xs={3}>
				<StatsCard
					loading={loading}
					Data={state.apptH ? [state.apptH] : [initState.apptH]}
					noElevation
					gutterFirst={false}
					compact
				>
					<Semaphore
						data={state.apptShTrend && state.apptShTrend}
						customFields={[
							{ index: 1, type: 'currency-compact' },
							{ index: 1, type: 'revert-positive' },
						]}
					/>
				</StatsCard>
			</Grid>
		</>
	);
};

export const OSA = (props) => {
	const { loading = false, data = false, timeFrame = false } = props;
	const classes = useStyles();
	const initState = {
		apptH: { label: 'Appointments showed', number1: '-', number2: '-' },
		agrmntSgnd: { label: 'Agrmnts Signed', number1: '-', number2: '-' },
		closed: { label: 'Closed', number1: '-', number2: '-' },
		apptHTrend: false,
		agrmntSgndTrend: false,
		closedTrend: false,

		leadApptRatioTrend: false,
		apptClosedRatioTrend: false,

		showAgreeRatio: '- : 1',
		agreeClosedRatio: '- : 1',
	};
	const [state, setState] = useState(initState);

	useEffect(() => {
		let apptHTrend = [];
		let timeFrameLabel = timeFrame
			? timeFrame === '365'
				? 'This Year'
				: `Last ${timeFrame} days`
			: 'Current';
		let apptHValsTrend = {
			...popperTemplate,
			header: 'Appointments showed',
			label1: timeFrameLabel,
		};
		let apptHPriceTrend = {
			...popperTemplate,
			header: 'Appointmnts showed',
			label1: timeFrameLabel,
		};
		let agrmntSgndTrend = [];
		let agrmntSgndValsTrend = {
			...popperTemplate,
			header: 'Agrmnts Signed',
			label1: timeFrameLabel,
		};
		let agrmntSgndPriceTrend = {
			...popperTemplate,
			header: 'Agrmnts Signed',
			label1: timeFrameLabel,
		};
		let closedTrend = [];
		let closedValsTrend = {
			...popperTemplate,
			header: 'Closed',
			label1: timeFrameLabel,
		};
		let closedPriceTrend = {
			...popperTemplate,
			header: 'Closed',
			label1: timeFrameLabel,
		};

		let showAgreeRatioTrend = {
			...popperTemplate,
			header: 'ApptH to Agrmnts',
			label1: timeFrameLabel,
		};
		let agreeClosedRatioTrend = {
			...popperTemplate,
			header: 'Agrmnts to Closed',
			label1: timeFrameLabel,
		};

		let showAgreeRatio = false;
		let agreeClosedRatio = false;

		if (data) {
			const newStats = { ...state };

			for (var prop in data) {
				switch (prop) {
					case 'apptH':
						newStats[prop].number1 = data[prop];
						newStats[prop].number2 =
							typeof data['showingsPrice'] === 'number'
								? currencyFormatter.format(data['showingsPrice'])
								: data['showingsPrice'];
						apptHValsTrend.val1 = data[prop];
						apptHValsTrend.val2 = data['apptHOld'];
						apptHPriceTrend.val1 = data['showingsPrice'];
						apptHPriceTrend.val2 = data['showingsPriceOld'];
						apptHTrend = [apptHValsTrend, apptHPriceTrend];
						break;
					case 'agrmntSgnd':
						newStats[prop].number1 = data[prop];
						newStats[prop].number2 =
							typeof data['agreementPrice'] === 'number'
								? currencyFormatter.format(data['agreementPrice'])
								: data['agreementPrice'];
						agrmntSgndValsTrend.val1 = data[prop];
						agrmntSgndValsTrend.val2 = data['agrmntSgndOld'];
						agrmntSgndPriceTrend.val1 = data['agreementPrice'];
						agrmntSgndPriceTrend.val2 = data['agreementPriceOld'];
						agrmntSgndTrend = [
							agrmntSgndValsTrend,
							// agrmntSgndPriceTrend
						];

						break;
					case 'closed':
						newStats[prop].number1 = data[prop];
						newStats[prop].number2 =
							typeof data['closedPrice'] === 'number'
								? currencyFormatter.format(data['closedPrice'])
								: data['closedPrice'];
						closedValsTrend.val1 = data[prop];
						closedValsTrend.val2 = data['closedOld'];
						closedPriceTrend.val1 = data['closedPrice'];
						closedPriceTrend.val2 = data['closedPriceOld'];
						closedTrend = [closedValsTrend, closedPriceTrend];

						break;
					case 'showAgreeRatio':
						showAgreeRatioTrend.val1 =
							data[prop] === '-' ? data[prop] : parseFloat(data[prop]);

						showAgreeRatioTrend.val2 =
							data['showAgreeRatioOld'] === '-'
								? data['showAgreeRatioOld']
								: parseFloat(data['showAgreeRatioOld']);
						showAgreeRatio = data[prop];

						break;
					case 'agreeClosedRatio':
						agreeClosedRatioTrend.val1 =
							data[prop] === '-' ? data[prop] : parseFloat(data[prop]);

						agreeClosedRatioTrend.val2 =
							data['agreeClosedRatioOld'] === '-'
								? data['agreeClosedRatioOld']
								: parseFloat(data['agreeClosedRatioOld']);
						agreeClosedRatio = data[prop];
						break;
					default:
						break;
				}
			}
			setState({
				...state,
				...newStats,
				apptHTrend,
				agrmntSgndTrend,
				closedTrend,
				showAgreeRatio,
				agreeClosedRatio,
				showAgreeRatioTrend,
				agreeClosedRatioTrend,
			});
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<>
			<Grid item xs={3}>
				<StatsCard
					loading={loading}
					Data={state.apptH ? [state.apptH] : [initState.apptH]}
					noElevation
					gutterFirst={false}
					compact
				>
					<Semaphore
						data={state.apptHTrend && state.apptHTrend}
						customFields={[
							{ index: 1, type: 'currency-compact' },
							{ index: 1, type: 'revert-positive' },
						]}
					/>
				</StatsCard>
			</Grid>
			<Grid
				item
				xs={2}
				container
				direction='column'
				justify='space-between'
				alignItems='center'
				style={{
					maxWidth: 50,
					height: '100%',
				}}
			>
				<Grid item xs={4}>
					<Divider
						orientation='vertical'
						style={{ width: '1px', backgroundColor: '#171C22' }}
					/>
				</Grid>

				<Grid
					item
					container
					direction='column'
					justify='space-evenly'
					alignItems='center'
					wrap='nowrap'
					classes={{ container: classes.containerSepaphore }}
				>
					<Typography color='textPrimary' variant='h6'>
						{state.showAgreeRatio}
					</Typography>
					<Semaphore
						alt
						data={state.showAgreeRatioTrend && [state.showAgreeRatioTrend]}
						ratio
					/>
				</Grid>

				<Grid item xs={4}>
					<Divider
						orientation='vertical'
						style={{ width: '1px', backgroundColor: '#171C22' }}
					/>
				</Grid>
			</Grid>
			<Grid item xs={3}>
				<StatsCard
					loading={loading}
					Data={state.agrmntSgnd ? [state.agrmntSgnd] : [initState.agrmntSgnd]}
					noElevation
					gutterFirst={false}
					compact
				>
					<Semaphore
						data={state.agrmntSgndTrend && state.agrmntSgndTrend}
						customFields={[
							{ index: 1, type: 'currency-compact' },
							{ index: 1, type: 'revert-positive' },
						]}
					/>
				</StatsCard>
			</Grid>
			<Grid
				item
				xs={2}
				container
				direction='column'
				justify='space-between'
				alignItems='center'
				style={{
					maxWidth: 50,
					height: '100%',
				}}
			>
				<Grid item xs={4}>
					<Divider
						orientation='vertical'
						style={{ width: '1px', backgroundColor: '#171C22' }}
					/>
				</Grid>
				<Grid
					item
					container
					direction='column'
					justify='space-evenly'
					alignItems='center'
					wrap='nowrap'
					classes={{ container: classes.containerSepaphore }}
				>
					<Typography color='textPrimary' variant='h6'>
						{state.agreeClosedRatio}
					</Typography>
					<Semaphore
						alt
						data={state.agreeClosedRatioTrend && [state.agreeClosedRatioTrend]}
						ratio
					/>
				</Grid>

				<Grid item xs={4}>
					<Divider
						orientation='vertical'
						style={{ width: '1px', backgroundColor: '#171C22' }}
					/>
				</Grid>
			</Grid>
			<Grid item xs={3}>
				<StatsCard
					loading={loading}
					Data={state.closed ? [state.closed] : [initState.closed]}
					noElevation
					gutterFirst={false}
					compact
				>
					<Semaphore
						data={state.closedTrend && state.closedTrend}
						customFields={[
							{ index: 1, type: 'currency-compact' },
							{ index: 1, type: 'revert-positive' },
						]}
					/>
				</StatsCard>
			</Grid>
		</>
	);
};

export const ChartStats = (props) => {
	const { loading = false, data = false, timeFrame = false } = props;
	const initState = {
		buyers: { label: 'Buyers', number1: '-' },
		buyersTrend: false,
		sellers: { label: 'Sellers', number1: '-' },
		sellersTrend: false,
		total: { label: 'Total', number1: '-' },
		totalTrend: false,
	};
	const [state, setState] = useState(initState);
	const formatData = (val) => {
		return val !== undefined
			? !isNaN(parseInt(val))
				? currencyFormatterCompact.format(parseInt(val))
				: val
			: '-';
		//
	};

	useEffect(() => {
		let timeFrameLabel = timeFrame
			? timeFrame === '365'
				? 'This Year'
				: `Last ${timeFrame} days`
			: 'Current';

		let buyersTrend = {
			...popperTemplate,
			label1: timeFrameLabel,
			header: 'Buyers',
		};
		let sellersTrend = {
			...popperTemplate,
			label1: timeFrameLabel,
			header: 'Sellers',
		};
		let totalTrend = {
			...popperTemplate,
			label1: timeFrameLabel,
			header: 'Total',
		};
		if (data) {
			const newStats = { ...state };
			for (var prop in data) {
				if (newStats[prop]) newStats[prop].number1 = formatData(data[prop]);

				switch (prop) {
					case 'buyers':
						buyersTrend.val1 = data[prop];
						break;
					case 'buyersOld':
						buyersTrend.val2 = data[prop];
						break;
					case 'sellers':
						sellersTrend.val1 = data[prop];
						break;
					case 'sellersOld':
						sellersTrend.val2 = data[prop];
						break;
					case 'total':
						totalTrend.val1 = data[prop];
						break;
					case 'totalOld':
						totalTrend.val2 = data[prop];
						break;
					default:
						break;
				}
			}
			setState({
				...state,
				...newStats,

				buyersTrend,
				sellersTrend,
				totalTrend,
			});
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<Grid
			item
			//xs={3}
			container
			direction='column'
			justify='center'
			alignItems='center'
		>
			{loading ? (
				<Grid item>
					<CircularProgress />
				</Grid>
			) : (
				<>
					<Grid
						item
						container
						direction='row'
						justify='space-evenly'
						alignItems='center'
						wrap='nowrap'
					>
						<Grid item>
							<StatsCard
								loading={loading}
								Data={state.total ? [state.total] : [initState.total]}
								noElevation
								alt
								gutterFirst={false}
								compact
							>
								<Semaphore
									data={state.totalTrend && [state.totalTrend]}
									customFields={[{ index: 0, type: 'currency-compact' }]}
								/>
							</StatsCard>
						</Grid>
						<Grid item>
							<StatsCard
								loading={loading}
								Data={state.buyers ? [state.buyers] : [initState.buyers]}
								noElevation
								alt
								gutterFirst={false}
								compact
							>
								<Semaphore
									data={state.buyersTrend && [state.buyersTrend]}
									customFields={[{ index: 0, type: 'currency-compact' }]}
								/>
							</StatsCard>
						</Grid>
						<Grid item>
							<StatsCard
								loading={loading}
								Data={state.sellers ? [state.sellers] : [initState.sellers]}
								noElevation
								alt
								gutterFirst={false}
								compact
							>
								<Semaphore
									data={state.sellersTrend && [state.sellersTrend]}
									customFields={[{ index: 0, type: 'currency-compact' }]}
								/>
							</StatsCard>
						</Grid>
					</Grid>
				</>
			)}
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	root: { color: 'green', margin: 0.5 },
	fontSizeSmall: { fontSize: '6px' },
	colorPrimary: { color: '#14909B' },
	colorSecondary: { color: '#E56161' },
	colorAction: { color: '#f3ae13' },
	colorDisabled: { color: '#171C22' },
	colorDisabledAlt: { color: '#283038' },

	cardRoot: {
		backgroundColor: 'transparent !important',
		padding: 10,
		minWidth: 180,
		minHeight: 100,
	},
	cardPaper: {
		backgroundColor: '#171c22b3',
		backdropFilter: 'blur(6px)',
	},

	containerSepaphore: {
		backgroundColor: '#171C22',
		borderRadius: 40,
		padding: 3,
		minWidth: 65,
		minHeight: 35,
	},

	paper: {
		display: 'flex',
		border: `1px solid ${theme.palette.divider}`,
		flexWrap: 'wrap',
		backgroundColor: 'transparent',
		height: 28,
		alignItems: 'center',
	},
	divider: {
		margin: theme.spacing(1, 0.5),
	},
	containedBtn: {
		padding: '4px 5px',
		fontSize: '0.9285714285714285rem',
		height: 18,
	},
	selectedBtn: {
		padding: '4px 5px',
		fontSize: '0.9285714285714285rem',
		height: 18,
		color: '#283038 !important',
		backgroundColor: '#fff !important',
	},
	BtnLabel: {
		height: 10,
	},
	selectorBtn: {
		padding: 0,
	},
}));
