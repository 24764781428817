import { currencyFormatter, getDate } from 'components/lib';

export const statsTemplate = [
	{ label: 'This Month', number1: '  -  ' },
	{ label: 'Last Month', number1: '  -  ' },
	{ label: 'Average', number1: '  -  ' },
];

// const chartTemplate = {
// 	categories: [
// 		'Leads',
// 		'Calls',
// 		'Appntmnts',
// 		'ApptH',
// 		'Agrmnts',
// 		'ESCROW',
// 		'Closed',
// 	],
// 	series: [],
// };

const BarChartFooterTemplate = [
	{ label: 'Stale leads', value: '-' },
	{ label: 'Clls:Lds', value: '-' },
	{ label: 'Clls:Apptmnts', value: '-' },
	{ label: 'Apptmnts:ApptH', value: '-' },
	{ label: 'ApptH:Agrmnts', value: '-' },
	{ label: 'ApptH:ESCROW', value: '-' },
	{ label: 'ApptH:Clsd', value: '-' },
];
const AssignedLeadsListTemplate = {
	headerName: '',
	labels: ['leads', 'updated at', 'not contacted', 'shwngs', 'agrmnts'],
	dataSet: [],
	customCells: [
		{ index: 0, celltype: 'clickable' },
		{ index: 0, celltype: 'with-icon' },
		{ index: 1, celltype: 'date' },
	],
};
export const LeaderboardList = {
	headerName: '',
	labels: [
		'agent',
		'calls',
		'appntmnts',
		'shwngs',
		'agrmnts',
		'escrow',
		'closed',
	],
	customCells: [{ index: 0, celltype: 'with-icon' }],
	dataSet: [],
	coumnsWithoutSorting: [0],
};
export const initState = {
	loading: true,
	closedCount: false,
	chartData: false,
	ISABarChartFooter: BarChartFooterTemplate,
	statsCalls: statsTemplate,
	revenueCards: statsTemplate,
	commisionCards: statsTemplate,
	AssignedLeadsList: AssignedLeadsListTemplate,
	LeaderboardList: LeaderboardList,
};

export const generateStats = (
	template = false,
	data = false,
	isCurrency = false
) => {
	if (template && data) {
		const stats = template.map((t) => {
			return { ...t };
		});

		stats.map((item, index) => {
			const label = item.label.toLocaleLowerCase();
			let value = false;
			switch (label) {
				case 'this month':
					value = data.current;

					if (isCurrency) {
						return (item.number1 =
							value !== undefined && !isNaN(value)
								? currencyFormatter.format(parseInt(value))
								: item.number1);
					} else {
						return (item.number1 = value
							? value.length > 7
								? value.replace(/\s/g, '')
								: value
							: item.number1);
					}
				case 'last month':
					value = data.previous;
					if (isCurrency) {
						return (item.number1 =
							value !== undefined && !isNaN(value)
								? currencyFormatter.format(parseInt(value))
								: item.number1);
					}
					return (item.number1 = value
						? value.length > 7
							? value.replace(/\s/g, '')
							: value
						: item.number1);
				case 'average':
					value = data.average;
					if (isCurrency) {
						return (item.number1 =
							value !== undefined && !isNaN(value)
								? currencyFormatter.format(parseInt(value))
								: item.number1);
					}
					return (item.number1 = value
						? value.length > 7
							? value.replace(/\s/g, '')
							: value
						: item.number1);
				default:
					break;
			}
			return item;
		});
		return stats;
	}
	return false;
};

export const generateChart = (data) => {
	if (data) {
		return {
			categories: [
				'Leads',
				'Calls',
				'Appointments',
				'Appointments Held',
				'Agreements',
				'Escrow',
				'Closed',
			],
			series: [
				{
					name: 'WIP',
					data: [
						data.leadsCount ? data.leadsCount : 0,
						data.callsCount ? data.callsCount : 0,
						data.appointmentCount ? data.appointmentCount : 0,
						data.showingsCount ? data.showingsCount : 0,
						data.agreementCount ? data.agreementCount : 0,
						data.escrowCount ? data.escrowCount : 0,
						data.closedCount ? data.closedCount : 0,
					],
					color: '#6179E5',
				},
			],
		};
	}
	return false;
};

export const generateChartFooter = (data, staleLeads) => {
	if (!data) return false;

	const footer = BarChartFooterTemplate.map((t) => {
		return { ...t };
	});
	const footerData = [
		data.staleLeads !== undefined ? data.staleLeads : '-',
		data.callLeadRatio !== undefined ? data.callLeadRatio : '-',
		data.callApptRatio !== undefined ? data.callApptRatio : '-',
		data.apptShowRatio !== undefined ? data.apptShowRatio : '-',
		data.showAgrmntRatio !== undefined ? data.showAgrmntRatio : '-',
		data.showEscrowRatio !== undefined ? data.showEscrowRatio : '-',
		data.showClosedRatio !== undefined ? data.showClosedRatio : '-',
	];

	footerData.forEach((el, elIndex) => {
		footer[elIndex].value = el;
	});

	return footer;
};

export const generateLeadsList = (data) => {
	if (!data) return false;

	const template = JSON.parse(JSON.stringify(AssignedLeadsListTemplate));

	const leadsData = [];
	data.leads.map((lead, leadIndex) => {
		leadsData.push({
			icons: [
				{
					index: 0,
					type: 'avatar',
					link: lead.picture
						? process.env.REACT_APP_API_URI + `/image/${lead.picture}`
						: '',
					color: '#fff',
				},
			],
			values: [
				[lead.name],
				[lead.updated ? getDate(lead.updated, true) : getDate(lead.created, true)],
				[lead.isContacted ? 'YES' : 'NO'],
				[lead.showings],
				[lead.agreement ? 'YES' : 'NO'],
			],
			id: lead.fubId ? lead.fubId : null,
		});
		return lead;
	});
	template.dataSet = leadsData;
	return template;
};

export const generateLeaderboardList = (data) => {
	if (!data) return false;

	const template = JSON.parse(JSON.stringify(LeaderboardList));

	const leadersData = [];
	data.map((agent, agentIndex) => {
		leadersData.push({
			icons: [
				{
					index: 0,
					type: 'avatar',
					link: agent.avatarId
						? process.env.REACT_APP_API_URI + `/image/${agent.avatarId}`
						: '',
					color: '#fff',
				},
			],
			values: [
				[
					`${agent.firstName ? agent.firstName : ''} ${
						agent.firstName ? agent.lastName.charAt(0).toUpperCase() + '.' : ''
					}`,
				],
				//[`${agent.leadsCount ? agent.leadsCount : ''}`],
				[`${agent.callsCount ? agent.callsCount : ''}`],
				[`${agent.appointmentCount ? agent.appointmentCount : ''}`],
				[`${agent.showingsCount ? agent.showingsCount : ''}`],
				[`${agent.agreementCount ? agent.agreementCount : ''}`],
				[`${agent.escrowCount ? agent.escrowCount : ''}`],
				[`${agent.closedCount ? agent.closedCount : ''}`],
			],
		});
		return agent;
	});
	template.dataSet = leadersData;
	return template;
};
