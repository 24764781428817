import React, { useEffect } from 'react';

import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';

import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';

import { ListItemLink } from './menuItemLink';

import { useNavItems } from 'components/lib';
import { useLocation } from 'react-router-dom';

import Style from './navMUI.module.scss';

const AppMenuItem = (props) => {
	const { updateTree } = useNavItems();
	const {
		name,
		link = '',
		Icon,
		items = [],
		isExpandable,
		isOpen,
		parentIndex,
		childIndex = false,
		isActive,
		child = false,
	} = props;

	const location = useLocation();

	function handleClick() {
		//open/collapse tree
		if (isExpandable) updateTree(parentIndex);
	}

	useEffect(() => {
		//check current rout when current list item is mounted,
		//if it's the same as provided link, then we send parent and child index(if any) to the context along with the special flag
		if (location.pathname === link) updateTree(parentIndex, childIndex, true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const MenuItemRoot = (
		<ListItemLink
			link={link}
			onClick={handleClick}
			className={child ? Style.childItem : Style.parentItem}
		>
			{/* Display an icon if any */}
			{!!Icon && (
				<ListItemIcon classes={{ root: Style['icon'] }}>
					<Icon style={{ color: isActive ? '#6179E5' : '#8b8ba1' }} />
				</ListItemIcon>
			)}
			<ListItemText
				primary={name}
				inset={!Icon}
				style={{ color: isActive ? 'white' : '#8b8ba1' }} //todo styles
			/>
			{/* Display the expand menu if the item has children */}
			{isExpandable && !isOpen && <IconExpandMore />}
			{isExpandable && isOpen && <IconExpandLess />}
		</ListItemLink>
	);

	const MenuItemChildren = isExpandable ? (
		<Collapse
			in={isOpen}
			timeout='auto' //unmountOnExit
		>
			<Divider />
			<List component='div' disablePadding>
				{items.map((item, childIndex) => (
					<AppMenuItem
						{...item}
						key={childIndex}
						childIndex={childIndex}
						parentIndex={parentIndex}
						child={true}
					/>
				))}
			</List>
		</Collapse>
	) : null;

	return (
		<>
			{MenuItemRoot}
			{MenuItemChildren}
		</>
	);
};

export default AppMenuItem;
