import React from 'react';

// import {
// 	ContentSection,
// 	BarChart,
// 	StatsCard,
// } from 'components/lib';
// import { mockSalesDataSecond } from 'data/mockData';
// import { Divider, Grid, Typography } from '@material-ui/core';

export function SalesEtc() {
	return <></>;
}
