// import { Dashboard } from 'views/dashboard/dashboard';
// import { OverviewMUI } from 'views/dashboard/overview';
// import { AgentsMUI } from 'views/dashboard/agents';
import { Transactions } from 'views/dashboard/transactions/transactions';
import {
	SalesOverview,
	// SalesLeads,
	// SalesCalls,
	// SalesAppointments,
	// SalesEtc,
} from 'views/dashboard/sales/index';
// import { KPIMUI } from 'views/dashboard/kpi';

// import { Clients } from 'views/clients';
// import { RunNumbers } from 'views/runNumbers';
// import { TargetNumbers } from 'views/targetNumbers';
// import { Training } from 'views/training';

import Form1 from 'views/forms/Formpage1';
import Form2 from 'views/forms/Formpage2';
import Form3 from 'views/forms/Formpage3';
import Form4 from 'views/forms/Formpage4';
import Form5 from 'views/forms/Formpage5';
import Form6 from 'views/forms/Formpage6';
import Form7 from 'views/forms/Formpage7';

//demo temp pages

import { TempISA } from 'views/dashboard/agent/ISA/tempISA';
import { OSA } from 'views/dashboard/agent/OSA/OSA';
import { OSAs } from 'views/dashboard/manager/OSAs/OSAs';
import { OSAAgent } from 'views/dashboard/manager/OSAs/OSAAgent';

import { ManagerDashboard } from 'views/dashboard/manager/Overview/ManagerDashboard';
import { ISAs } from 'views/dashboard/manager/ISAs/ISAs';
import { ISAAgent } from 'views/dashboard/manager/ISAs/ISAAgent';

//isa manager pages
import { ISAManagerOverview } from 'views/dashboard/manager/ISA_Manager/overview';
import { AgentView } from 'views/dashboard/manager/ISA_Manager/agenView';
import { ISAManagerLeads } from 'views/dashboard/manager/ISA_Manager/leads';

//marketing pages

import { MarketingOverview } from 'views/dashboard/marketing/overview';
import { MarketingLeadSources } from 'views/dashboard/marketing/leads';

export default [
	{
		path: '/trasactions',
		view: Transactions,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Dashboard Transactions',
	},
	{
		path: '/sales',
		view: SalesOverview,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Dashboard Sales',
	},
	// {
	// 	path: '/sales/leads',
	// 	view: SalesLeads,
	// 	layout: 'appMUI',
	// 	//permission: 'user',
	// 	title: 'Dashboard Sales',
	// },
	// {
	// 	path: '/sales/calls',
	// 	view: SalesCalls,
	// 	layout: 'appMUI',
	// 	//permission: 'user',
	// 	title: 'Dashboard Sales',
	// },
	// {
	// 	path: '/sales/appointments',
	// 	view: SalesAppointments,
	// 	layout: 'appMUI',
	// 	//permission: 'user',
	// 	title: 'Dashboard Sales',
	// },
	// {
	// 	path: '/sales/etc',
	// 	view: SalesEtc,
	// 	layout: 'appMUI',
	// 	//permission: 'user',
	// 	title: 'Dashboard Sales',
	// },
	// {
	// 	path: '/kpi',
	// 	view: KPIMUI,
	// 	layout: 'appMUI',
	// 	//permission: 'user',
	// 	title: 'Dashboard KPI',
	// },
	{
		path: '/forms/form1',
		view: Form1,
		layout: 'appMUI',
		title: 'Appointment form',
	},
	{
		path: '/forms/form2',
		view: Form2,
		layout: 'appMUI',
		title: 'Escrow (Buyer) Form',
	},
	{
		path: '/forms/form3',
		view: Form3,
		layout: 'appMUI',
		title: 'Agreement (BBA/RLA) Signed Form',
	},
	{
		path: '/forms/form4',
		view: Form4,
		layout: 'appMUI',
		title: 'Detailed Disposition Status Report',
	},
	{
		path: '/forms/form5',
		view: Form5,
		layout: 'appMUI',
		title: 'Disposition Status Report',
	},
	{
		path: '/forms/form6',
		view: Form6,
		layout: 'appMUI',
		title: 'Reschedule Appointment',
	},
	{
		path: '/forms/form7',
		view: Form7,
		layout: 'appMUI',
		title: 'Assignment Form',
	},

	//DEMO ROUTES

	{
		path: '/isa',
		view: TempISA,
		layout: 'appMUI',
		//permission: 'user',
		title: 'ISA',
	},
	{
		path: '/osa',
		view: OSA,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Agent',
	},
	//manager

	{
		path: '/isas',
		view: ISAs,
		layout: 'appMUI',
		//permission: 'user',
		title: 'ISAs',
	},
	{
		path: '/isas/agent',
		view: ISAAgent,
		layout: 'appMUI',
		//permission: 'user',
		title: 'ISA Agent',
	},
	{
		path: '/osas',
		view: OSAs,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Agents',
	},
	{
		path: '/osas/agent',
		view: OSAAgent,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Agent',
	},
	{
		path: '/overview',
		view: ManagerDashboard,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Overview',
	},

	//ISA Manegment pages
	{
		path: '/manager/overview',
		view: ISAManagerOverview,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Overview',
	},
	{
		path: '/manager/agent',
		view: AgentView,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Agent page',
	},
	{
		path: '/manager/leads',
		view: ISAManagerLeads,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Leads',
	},

	//marketing pages

	{
		path: '/marketing/overview',
		view: MarketingOverview,
		layout: 'appMUI',
		title: 'Overview',
	},
	{
		path: '/marketing/leads',
		view: MarketingLeadSources,
		layout: 'appMUI',
		title: 'Lead sources',
	},
];
