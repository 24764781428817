/***
 *
 *   TABLE
 *   that allows the columns to be sorted and can display any number of columns depending on data recieved
 *
 *   PROPS
 *   data: array of objects for body and header (optional)
 *
 **********/

import React, { useEffect } from 'react';
import {
	TableRow,
	Table,
	TableBody,
	TableContainer,
	CircularProgress,
} from '@material-ui/core';

import Style from './table.module.scss';

import {
	CellWithIcon,
	Cell,
	CellWithButton,
	CellWithChips,
	CellBtnIcon,
} from './tableCells';
import { ListHeader } from './tableHeader';
import { makeStyles } from '@material-ui/core/styles';

import { Loader } from 'components/lib';

/*TODO:	
	medals logic, - due to table rework it is now possible to display medals without pain, but need to write it in to the cell components	
	refactor to make it more readable, - eh, but it could be better
	write component overview comments
*/
function isInteger(value) {
	return /^-?\d+(\.\d{1,9})?$/.test(value);
}

function descendingComparator(a, b, orderBy) {
	let first = isInteger(a[orderBy]) ? parseInt(a[orderBy]) : a[orderBy];
	let second = isInteger(b[orderBy]) ? parseInt(b[orderBy]) : b[orderBy];
	if (second < first) {
		return -1;
	}
	if (second > first) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

export function TableList(props) {
	const {
		data = false,
		callBack,
		initialOrderBy = false,
		initialOrder = 'desc',
		maxRows = false,
		topResults = false,
		minHeight = 0,
		loading = false,
	} = props;

	const [currentData, setCurrentData] = React.useState(false);
	//const [shortData, setShortData] = React.useState(false);
	//const [init, setInit] = React.useState(true);
	const [order, setOrder] = React.useState(initialOrder);

	const useStyles = makeStyles({
		tableBody: { minHeight: minHeight },
	});
	const classes = useStyles();

	const [orderBy, setOrderBy] = React.useState(
		data && initialOrderBy
			? initialOrderBy.toLowerCase()
			: data?.labels?.[0].toLowerCase()
	);

	useEffect(() => {
		if (data) {
			let parsedDta = parseList(data);

			setCurrentData(parsedDta);
			//setShortData(parsedDta);
			//setInit(true);

			setOrderBy(
				initialOrderBy
					? initialOrderBy.toLowerCase()
					: data?.labels?.[0].toLowerCase()
			);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);
	if (!data) return <CircularProgress />;
	if (!currentData) return <CircularProgress />;

	const stableSort = (array, comparator) => {
		const stabilizedThis = array.map((el, index) => [el, index]);

		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);

			if (order !== 0) return order;
			return a[1] - b[1];
		});
		if (topResults) {
			let filtered = stabilizedThis.filter((el, index) => {
				if (maxRows && index < maxRows) {
					return el[0];
				}
				return false;
			});
			let list = filtered.map((el) => el[0]);

			return list;
		}
		return stabilizedThis.map((el) => el[0]);
	};

	const handleRequestSort = (event, property) => {
		const isAsc =
			orderBy.toLowerCase() === property.toLowerCase() && order === 'asc';
		if (topResults) {
			setOrder('desc');
		} else {
			setOrder(!isAsc ? 'asc' : 'desc');
		}
		setOrderBy(property.toLowerCase());
	};
	function parseList(data) {
		const rows = [];
		const template = {};

		data.labels.map((el, index) => (template[el.toLowerCase()] = null));
		data.dataSet.map((row, rowIndex) => {
			rows.push({ ...template });
			row.values.map((value, valueIndex) => {
				rows[rowIndex][Object.keys(rows[rowIndex])[valueIndex]] = value;
				if (row.icons?.some((el) => el.index === valueIndex)) {
					if (rows[rowIndex][Object.keys(rows[rowIndex])[valueIndex]].length > 1)
						return false;
					rows[rowIndex][Object.keys(rows[rowIndex])[valueIndex]].unshift(
						row.icons?.find((obj) => obj.index === valueIndex)
					);
				}
				return value;
			});
			rows[rowIndex]['id'] = data.dataSet[rowIndex].id;
			return row;
		});
		return rows;
	}

	return (
		<TableContainer classes={{ root: classes.tableBody }}>
			<Table aria-label='customized table' classes={{ root: Style['table-root'] }}>
				<ListHeader
					headerData={data && data.labels}
					onRequestSort={handleRequestSort}
					order={order}
					orderBy={orderBy}
					data={data && data}
					topResults={topResults}
				/>

				<TableBody>
					{!data || loading ? (
						<Loader wrapped customStyle={{ height: 60, width: '100%' }} />
					) : (
						stableSort(currentData, getComparator(order, orderBy)).map(
							(row, rowIndex) => {
								if (maxRows && rowIndex >= maxRows) return null;
								return (
									<TableRow key={rowIndex} classes={{ root: Style['row-root'] }}>
										{data.labels.map((keyName, columnIndex) => {
											const cellValue = row[`${keyName.toLowerCase()}`];

											return data.customCells?.some(
												(el) => el.index === columnIndex && el.celltype === 'with-icon'
											) ? (
												<CellWithIcon
													icon={cellValue[0]}
													label={cellValue[1]}
													key={keyName + columnIndex}
													clickable={data.customCells?.some(
														(el) => el.index === columnIndex && el.celltype === 'clickable'
													)}
													callBack={callBack}
													row={row}
													customSize={
														data.cellsFixedSize?.some((el) => el.index === columnIndex)
															? data.cellsFixedSize?.find((obj) => obj.index === columnIndex)
															: false
													}
												/>
											) : data.customCells?.some(
													(el) =>
														el.index === columnIndex &&
														(el.celltype === 'button-nolabel' ||
															el.celltype === 'button' ||
															el.celltype === 'button-disableable')
											  ) ? (
												<CellWithButton
													cellValue={cellValue}
													key={keyName + columnIndex}
													callBack={callBack}
													row={row}
													canBeDisabled={data.customCells?.some(
														(el) =>
															el.index === columnIndex && el.celltype === 'button-disableable'
													)}
												/>
											) : data.customCells?.some(
													(el) => el.index === columnIndex && el.celltype === 'chips'
											  ) ? (
												<CellWithChips
													cellValue={cellValue}
													key={keyName + columnIndex}
													callBack={callBack}
													row={row}
												/>
											) : data.customCells?.some(
													(el) => el.index === columnIndex && el.celltype === 'btn-icon'
											  ) ? (
												<CellBtnIcon
													cellValue={cellValue}
													key={keyName + columnIndex}
													callBack={callBack}
													row={row}
												/>
											) : (
												<Cell
													cellValue={cellValue}
													key={keyName + columnIndex}
													alignLeft={data.customCells?.some(
														(el) => el.index === columnIndex && el.celltype === 'align-left'
													)}
													isDate={data.customCells?.some(
														(el) => el.index === columnIndex && el.celltype === 'date'
													)}
													isCurrency={data.customCells?.some(
														(el) => el.index === columnIndex && el.celltype === 'currency'
													)}
													customSize={
														data.cellsFixedSize?.some((el) => el.index === columnIndex)
															? data.cellsFixedSize?.find((obj) => obj.index === columnIndex)
															: false
													}
												/>
											);
										})}
									</TableRow>
								);
							}
						)
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
