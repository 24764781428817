import React, { useState, useEffect, useRef } from 'react';
import Highcharts from 'highcharts';

import Style from './barChart.module.scss';
import { barData as chartData, barChartOptions } from '../../data/chartData';

export function BarChart() {
	//eslint-disable-next-line no-unused-vars
	const [chart, setChart] = useState(null);
	//eslint-disable-next-line no-unused-vars
	const [initialized, setInitialized] = useState(false);
	const [labelData, setLabelData] = useState([]);
	//const [totalLabelData, setTotalLabelData] = useState([]);
	const refContainer = useRef();

	useEffect(() => {
		if (initialized)
			chart.update({
				xAxis: {
					labels: {
						//	align: 'right',
						formatter: function () {
							return `${this.value}<br/> ${labelData[this.pos]}`;
							//this.chart.series[0].stackedYData[this.pos]
						},
					},
				},
			});
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [labelData]);

	// const setData = (data, type) => {
	// 	//
	// };

	useEffect(() => {
		setChart(
			Highcharts.chart(refContainer.current, {
				...barChartOptions,
				xAxis: {
					labels: {
						...barChartOptions.xAxis.labels,
						formatter: function () {
							//setTotalLabelData(this.chart.series[0].stackedYData);
							return `${this.value}<br/> ${
								//labelData[this.pos]
								this.chart.series[0].stackedYData[this.pos]
							}`;

							//this.chart.series(name).data(category).total
							//value: "Leads" - category pos - index
						},
					},
					lineWidth: 0,
					categories: chartData.categories,
				},
				tooltip: {
					formatter: function () {
						return (
							'<b>' +
							this.x +
							'</b><br/>' +
							this.series.name +
							': ' +
							this.y +
							'<br/>' +
							'Total: ' +
							this.point.stackTotal
						);
					},
				},
				plotOptions: {
					...barChartOptions.plotOptions,
					series: {
						point: {
							events: {
								mouseOver: function () {
									return setLabelData(this.y);
								},
								mouseOut: function () {
									return setLabelData(this.total);
								},
							},
						},
					},
				},
				series: chartData.series,
			})
		);
		setInitialized(true);
	}, []);

	return (
		<>
			<div
				className={Style.highchartMe}
				ref={refContainer}
				style={{
					width: '100%',
					//height: 272,
				}}
			/>
		</>
	);
}
