/***
 *
 *   AUTHENTICATION
 *   Auth provider to manage auth functions throughout
 *   the application. <PrivateRoute> component to
 *   protect internal application routes from unauthenticated
 *   access.
 *
 **********/

import React, { useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import Axios from 'axios';

const permissions = require('./permissions');

// auth context
export const AuthContext = React.createContext();

export function AuthProvider(props) {
	const cache = JSON.parse(localStorage.getItem('user'));
	const [user, setUser] = useState(cache);

	// let { menuItems } = useNavItems();

	//const subscription = useAPI(user ? '/api/account/subscription' : null);

	// useEffect(() => {
	// 	// update subscription status
	// 	if (!subscription.loading) update({ subscription: subscription.data });
	// }, [subscription]);

	// function signin(res) {
	// 	if (res.data) {
	// 		localStorage.setItem('user', JSON.stringify(res.data));
	// 		const dashboardMenu = menuItems.filter(obj => obj.name === "Dashboard")?.[0];

	// 		window.location =
	// 		dashboardMenu.items.length>0 ? dashboardMenu.items[0].link : '/profile';
	// 	}

	// }

	async function signinWip(res) {
		//TODO get org data /api/org

		if (res.data) {
			if (res.data.user) {
				localStorage.setItem('organization', JSON.stringify(res.data.organization));
				localStorage.setItem(
					'accessToken',
					JSON.stringify(res.data.user.accessToken)
				);
			} else {
				localStorage.setItem('user', JSON.stringify(res.data));
				localStorage.setItem('accessToken', JSON.stringify(res.data.accessToken));
			}
		}
	}
	// async function signinInv(res) {
	// 	//TODO get org data /api/org

	// 	if (res.data) {
	// 		if (res.data.user) {
	// 			localStorage.setItem('organization', JSON.stringify(res.data.organization));
	// 			localStorage.setItem(
	// 				'accessToken',
	// 				JSON.stringify(res.data.user.accessToken)
	// 			);
	// 		} else {
	// 			localStorage.setItem('user', JSON.stringify(res.data));
	// 			localStorage.setItem('accessToken', JSON.stringify(res.data.accessToken));
	// 		}
	// 		History.push('/profile');
	// 	}
	// }

	function signupWip(res) {
		if (res.data) {
			localStorage.setItem('organization', JSON.stringify(res.data.organization));
			localStorage.setItem('user', JSON.stringify(res.data.user));
			localStorage.setItem(
				'accessToken',
				JSON.stringify(res.data.user.accessToken)
			);
		}
	}
	// function invitesWip(res) {
	// 	//TODO
	// }

	function pwdChangeWip(res) {
		localStorage.setItem('accessToken', JSON.stringify(res.data.accessToken));
		Axios.defaults.headers.common['Authorization'] =
			'Bearer ' + res.data.accessToken;
	}
	async function signout() {
		localStorage.clear();
		window.location = '/signin';
	}

	function update(data) {
		if (localStorage.getItem('user')) {
			let user = JSON.parse(localStorage.getItem('user'));
			for (let key in data) {
				if (typeof data[key] === 'object') {
					for (let innerKey in data[key]) {
						user[key][innerKey] = data[key][innerKey];
					}
				} else {
					user[key] = data[key];
				}
			}

			localStorage.setItem('user', JSON.stringify(user));
			setUser(user);
		}
	}

	return (
		<AuthContext.Provider
			value={{
				user: user,
				//signin: signin,
				signinWip: signinWip,
				signupWip: signupWip,
				pwdChangeWip: pwdChangeWip,
				signout: signout,
				update: update,
				permission: permissions[user?.permission],
			}}
			{...props}
		/>
	);
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
	return (
		<Route
			{...props}
			render={(data) => {
				// check user
				const user = JSON.parse(localStorage.getItem('user'));
				const path = window.location.pathname;

				if (user?.token) {
					if (permissions[props.permission][props.permission]) {
						// user has no subscription
						if (user.subscription !== 'active' && user.plan !== 'master') {
							if (
								!path.includes('/account/billing') &&
								!path.includes('/account/upgrade')
							) {
								return <Redirect to='/account/billing' />;
							}
						} //

						// user is good
						return <props.render {...props} />;
					}
				}

				// user is not authenticated
				return <Redirect to='/signin' />;
			}}
		/>
	);
}
