import React, { useState, useRef, useEffect } from 'react';
import Highcharts from 'highcharts';

export function ChartAlt(props) {
	const { chartData = false, customStyle = { width: '100%' } } = props;
	const [chart, setChart] = useState(null);

	const [initialized, setInitialized] = useState(false);
	const refContainer = useRef();
	let chrt = false;
	useEffect(() => {
		if (chartData) {
			if (chrt && initialized) {
				chart.destroy();

				setInitialized(false);
			}
			Highcharts.setOptions({
				time: {
					useUTC: false,
				},
			});

			chrt = Highcharts.chart(refContainer.current, chartData);
			setChart(chrt);
			Highcharts.wrap(Highcharts.Tooltip.prototype, 'refresh', function (proceed) {
				if (!arguments?.[1]?.[0])
					return proceed.apply(this, [].slice.call(arguments, 1));
				let args = arguments,
					points = args[1],
					point = points[0];

				let chart = point.series.chart;

				chart.series.forEach((series) => {
					if (series === point.series || series.data.length < 1) return;

					let current,
						dist,
						distance = Number.MAX_VALUE;

					series.points.forEach((p) => {
						dist = Math.abs(p.x - point.x);
						if (dist < distance) {
							distance = dist;
							current = p;
						}
					});
					if (points.indexOf(current) === -1) points.push(current);
				});
				//if (!isAnyDataEmpty(chartData))
				proceed.apply(this, [].slice.call(args, 1));
			});

			setInitialized(true);
		}

		return () => {
			//if (chart) chart.destroy();
			if (chrt && initialized) {
				chrt.destroy();

				setInitialized(false);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartData]);

	return (
		<>
			<div ref={refContainer} style={customStyle} />
		</>
	);
}
