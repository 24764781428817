import React from 'react'; // useState
import { makeStyles } from '@material-ui/core/styles';

import { Drawer, Typography } from '@material-ui/core';

import AppNav from './appNav';

import NavUser from './user';
import Style from './navMUI.module.scss';

import Logo from 'views/website/images/logoLight.png';

//import Style from './navMUI.module.scss'; //TODO

const drawerWidth = '16em';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		fontSize: '16px',
	},
	drawerPaper: {
		width: drawerWidth,
		backgroundColor: theme.palette.background.sidebar,
		border: 0,
	},
	drawerDivider: {
		// marginTop: 17
	},
	// necessary for content to be below app bar
	toolbar: {
		// ...theme.mixins.toolbar,
		// [theme.breakpoints.up('md')]: {
		// 	minHeight: 80,
		// },
	},
	content: {
		flexGrow: 1,
		//backgroundColor: '#ffffff', //theme.palette.background.sidebar,
		padding: theme.spacing(3),
	},
}));

export function AppNavMUI() {
	const classes = useStyles();

	return (
		<Drawer
			className={Style.drawer}
			variant='permanent'
			classes={{
				paper: Style.drawerpaper,
			}}
			anchor='left'
		>
			<div
			//style={{ height:"100%"}}
			>
				<div className={classes.toolbar}>
					{/* <Typography
					variant='h2'
					gutterBottom
					align='center'
					color='textPrimary'
					style={{ marginTop: '0.5625em' }}
				>
					RP
				</Typography> */}

					<img
						style={{
							width: '100%',
							height: 'auto',
							//padding: '0px 20px',
							//paddingTop: '10%',
							marginTop: '0.5625em',
						}}
						src={Logo}
						alt='logo'
					/>
				</div>
				<AppNav />
			</div>

			<NavUser />
		</Drawer>
	);
}
