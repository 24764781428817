import React, { useState } from 'react';
import {
	Typography,
	Grid,
	TableCell,
	Icon,
	Avatar,
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	Chip,
	IconButton,
} from '@material-ui/core';
import Style from './table.module.scss';
import { getDate, currencyFormatter } from 'components/lib';

export const Cell = function (props) {
	const {
		cellValue = [],
		isDate = false,
		isCurrency = false,
		alignLeft = false,
		customSize = false,
	} = props;
	const formatCurrency = (value) => {
		if (value === -1) return '-';
		return value !== undefined && !isNaN(value)
			? currencyFormatter.format(parseInt(value))
			: value;
	};
	return cellValue.length > 1 ? (
		<TableCell
			align={alignLeft ? 'left' : 'center'}
			classes={{ root: Style['table-cell-root'] }}
			style={
				customSize
					? {
							width: customSize.size,
							//minwidth: customSize.size,
							maxwidth: customSize.size,
					  }
					: {}
			}
		>
			<Grid
				container
				direction='row'
				alignItems='center'
				justify='center'
				spacing={1}
			>
				<Grid item>
					<Typography>{cellValue[0] === '' ? '-' : cellValue[0]}</Typography>
				</Grid>
				<Grid item>
					<Typography color='textSecondary'>
						{cellValue[1] === '' ? '-' : cellValue[1]}
					</Typography>
				</Grid>
			</Grid>
		</TableCell>
	) : cellValue.length < 2 ? (
		<TableCell
			align={alignLeft ? 'left' : 'center'}
			classes={{ root: Style['table-cell-root'] }}
			style={
				customSize
					? {
							width: customSize.size,
							//minwidth: customSize.size,
							maxwidth: customSize.size,
					  }
					: {}
			}
		>
			<Typography>
				{isDate
					? getDate(cellValue[0], true)
					: isCurrency
					? formatCurrency(cellValue[0])
					: cellValue[0] === null &&
					  cellValue[0] === undefined &&
					  cellValue[0] === false
					? '-'
					: cellValue[0]}
			</Typography>
		</TableCell>
	) : null;
};

export const CellWithChips = function (props) {
	const { cellValue } = props;
	return (
		<TableCell align='left' classes={{ root: Style['table-cell-root'] }}>
			<Grid container direction='row' alignItems='center' spacing={1}>
				{cellValue[0].map((chip, chipIndex) => {
					return (
						<Grid item key={chipIndex} style={{ padding: 3 }}>
							<Chip
								label={chip}
								color='primary'
								style={{
									fontSize: '12px',
									height: '2em',
									fontFamily: 'AvenirRoman',
									lineHeight: 'normal',
								}}
							/>
						</Grid>
					);
				})}
			</Grid>
		</TableCell>
	);
};

export const CellWithIcon = function (props) {
	const {
		icon = {},
		label,
		classStyle,
		clickable,
		callBack = () => {},
		row = false,
		customSize = false,
	} = props;
	return (
		<TableCell
			component='th'
			scope='row'
			classes={{ root: Style['table-cell-root'] }}
			style={
				customSize && clickable
					? {
							width: customSize.size,
							//minwidth: customSize.size,
							maxwidth: customSize.size,
							cursor: 'pointer',
					  }
					: clickable
					? { cursor: 'pointer' }
					: customSize
					? {
							width: customSize.size,
							//minwidth: customSize.size,
							maxwidth: customSize.size,
					  }
					: {}
			}
			onClick={() => (clickable ? callBack('IconCell', row) : {})}
		>
			<Grid
				container
				direction='row'
				justify='flex-start'
				alignItems='center'
				spacing={1}
			>
				{icon.type === 'avatar' ? (
					<Grid
						item
						//xs={4}
					>
						<Avatar
							className={classStyle}
							src={icon.link}
							//alt={label}
						/>
					</Grid>
				) : icon.type === 'icon' ? (
					<Grid item style={{ marginRight: 10 }}>
						<Icon fontSize='large' style={{ color: icon.color }}>
							{icon.link}
						</Icon>
					</Grid>
				) : null}
				<Grid
					item //xs={8}
					zeroMinWidth
					style={{ maxWidth: 230 }}
				>
					<Typography>{label ? label : '- -'}</Typography>
				</Grid>
			</Grid>
		</TableCell>
	);
};

export const CellWithButton = function (props) {
	const { cellValue, callBack, row, canBeDisabled } = props;
	const [open, setOpen] = useState(false);

	const handleInitClick = (buttonEl, row) => {
		setOpen(true);
	};

	const handleClose = (buttonEl, row) => {
		callBack(buttonEl, row);
		setOpen(false);
	};
	//onClick={() => callBack(buttonEl, row)}
	return (
		<TableCell
			align={cellValue?.length > 2 ? 'right' : 'center'}
			classes={{ root: Style['table-cell-root'] }}
			key={Math.random()}
			style={
				cellValue?.length > 2
					? { padding: 0, width: 60, paddingRight: 10 }
					: { padding: 0, width: 160 }
			}
		>
			<div
				style={
					cellValue?.length > 2 ? { display: 'flex', flexDirection: 'column' } : {}
				}
			>
				{cellValue.map((buttonEl, btnIndex) => {
					return buttonEl.length > 2 ? (
						<React.Fragment key={Math.random()}>
							<Dialog
								open={open}
								onClose={handleClose}
								aria-labelledby='alert-dialog-title'
								aria-describedby='alert-dialog-description'
								style={{ fontSize: 16 }}
								key={btnIndex + 1}
							>
								<DialogContent>
									<Typography gutterBottom align='center' variant='h5'>
										{buttonEl[2]}
									</Typography>
								</DialogContent>
								<DialogActions>
									<Button onClick={handleClose} color='primary'>
										No
									</Button>
									<Button
										onClick={() => handleClose(buttonEl, row)}
										color='secondary'
										autoFocus
									>
										Yes
									</Button>
								</DialogActions>
							</Dialog>
							<Button
								onClick={() => handleInitClick(buttonEl, btnIndex)}
								variant='contained'
								color={buttonEl[1] ? buttonEl[1] : 'default'}
								key={Math.random()}
								style={{ margin: '2px 5px' }}
							>
								{buttonEl[0]}
							</Button>
						</React.Fragment>
					) : (
						<Button
							onClick={() => callBack(buttonEl, row)}
							variant='contained'
							color={buttonEl[1] ? buttonEl[1] : 'default'}
							key={Math.random()}
							style={{ margin: '2px 5px' }}
							disabled={canBeDisabled ? (row.id ? false : true) : false}
						>
							{buttonEl[0]}
						</Button>
					);
				})}
			</div>
		</TableCell>
	);
};

export const CellBtnIcon = function (props) {
	const { cellValue = [], callBack = () => {}, row } = props;

	return (
		<TableCell
			align='right'
			classes={{ root: Style['table-cell-root'] }}
			key={Math.random()}
			style={{
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			{cellValue?.map((buttonEl, btnIndex) => {
				return (
					<IconButton
						onClick={() => callBack(buttonEl, row)}
						variant='contained'
						color={buttonEl[1] ? buttonEl[1] : 'default'}
						key={Math.random()}
						//	style={{ margin: '0 5px' }}
					>
						{buttonEl[2]}
					</IconButton>
				);
			})}
		</TableCell>
	);
};
