import React from 'react';
import { Grid, Switch } from '@material-ui/core';
import { HeaderMUIGen } from '../lib';
import Style from './contentSection.module.scss';

//props: disableHeader: by default header enabled

//TODO: refactoring

// const DefaultSection = function (props) {
// 	const {
// 		handleClick,
// 		customClass,
// 		children,
// 		title,
// 		disableHeader,
// 		disableButton,
// 	} = props;
// 	return (
// 		<>
// 			{disableHeader || (
// 				<HeaderMUIGen
// 					title={title}
// 					type='contentSection'
// 					btn={!disableButton}
// 					btnTitle='View all'
// 					btnOnClick={() => handleClick()}
// 				/>
// 			)}
// 			<Grid
// 				container
// 				direction='row'
// 				alignItems='center'
// 				justify='center'
// 				style={{ marginTop: 5 }}
// 				className={customClass}
// 			>
// 				{children}
// 			</Grid>
// 		</>
// 	);
// }; //TODO REF
const LargeSection = function (props) {
	const {
		handleClick,
		children,
		dropdown,
		selectLabel,
		title,
		fullHeight = false,
	} = props;
	return (
		<>
			<HeaderMUIGen
				type='contentSectionAlt'
				btnTitle='View all'
				btnOnClick={() => handleClick()}
				dropdown={dropdown}
				selectLabel={selectLabel}
				title={title}
			/>
			<Grid
				container
				direction='row'
				alignItems={fullHeight ? 'stretch' : 'center'}
				justify='space-around'
				classes={{ root: Style['largeCard-content-root'] }}
			>
				{children}
			</Grid>
		</>
	);
};

const LinechartSection = function (props) {
	const {
		handleClick,
		children,
		//dropdown,
		//selectLabel,
		toggleSwitch,
		switchState,
	} = props;

	return (
		<>
			<HeaderMUIGen
				btnTitle='View all'
				btnOnClick={() => handleClick()}
				title={' '}
			>
				<Switch
					checked={switchState}
					onChange={toggleSwitch}
					name='checkedA'
					inputProps={{ 'aria-label': 'primary checkbox' }}
				/>
			</HeaderMUIGen>
			<Grid
				container
				direction='row'
				alignItems='center'
				justify='space-evenly'
				classes={{ root: Style['largeCard-content-chart-root'] }}
			>
				{children}
			</Grid>
		</>
	);
};

const BarChartSection = function (props) {
	const { handleClick, children, dropdown, selectLabel, title = false } = props;

	return (
		<>
			{title && (
				<HeaderMUIGen
					//type='contentSectionMid'
					btnTitle='View all'
					btnOnClick={() => handleClick()}
					dropdown={dropdown}
					selectLabel={selectLabel}
					title={title}
				/>
			)}
			<Grid
				container
				direction='column'
				alignItems='center'
				justify='center'
				classes={{ root: Style['largeCard-content-root'] }}
			>
				{children}
			</Grid>
		</>
	);
};
export function ContentSection(props) {
	const handleClick = () => console.log('content btn');
	const {
		customClass,
		children,
		title = false,
		type = 'content',
		dropdown,
		selectLabel,
		disableHeader,
		disableButton,
		toggleSwitch,
		switchState,
		fullHeight = false,
		headerEl = false,
	} = props;
	return (
		<Grid
			container
			direction='column'
			justify='space-between'
			alignItems='center'
			classes={{
				root: Style[`${type?.toLocaleLowerCase()}-card-wrapper-root`],
			}}
		>
			{type === 'large' || type === 'home' ? (
				<LargeSection
					customClass={customClass}
					children={children}
					title={title}
					dropdown={dropdown}
					selectLabel={selectLabel}
					fullHeight={fullHeight}
				/>
			) : type === 'lineChart' ? (
				<LinechartSection
					customClass={customClass}
					children={children}
					title={title}
					dropdown={dropdown}
					selectLabel={selectLabel}
					toggleSwitch={toggleSwitch}
					switchState={switchState}
				/>
			) : type === 'barChart' ? (
				<BarChartSection
					customClass={customClass}
					children={children}
					title={title}
					dropdown={dropdown}
					selectLabel={selectLabel}
				/>
			) : (
				// <DefaultSection
				// 	handleClick={() => handleClick()}
				// 	customClass={customClass}
				// 	children={children}
				// 	title={title}
				// 	disableHeader={disableHeader}
				// 	disableButton={disableButton}
				// />
				<>
					{disableHeader || (
						<HeaderMUIGen
							title={title}
							type='contentSection'
							btn={!disableButton}
							btnTitle='View all'
							btnOnClick={() => handleClick()}
						>
							{headerEl && headerEl}
						</HeaderMUIGen>
					)}
					<Grid
						container
						direction='row'
						alignItems='center'
						justify='center'
						style={{ marginTop: 5 }}
						className={customClass}
					>
						{children}
					</Grid>
				</>
			)}
		</Grid>
	);
}

/*

<>




{disableHeader || (
	<HeaderMUIGen
		title={title}
		type='contentSection'
		btn={!disableButton}
		btnTitle='View all'
		btnOnClick={() => handleClick()}
	/>
)}
<Grid
	container
	direction='row'
	alignItems='center'
	justify='center'
	style={{ marginTop: 5 }}
	className={customClass}
>
	{children}
</Grid>


<HeaderMUIGen
	type='contentSectionAlt'
	btnTitle='View all'
	btnOnClick={() => handleClick()}
	dropdown={dropdown}
	selectLabel={selectLabel}
	title={title}
/>
<Grid
	container
	direction='row'
	alignItems='center'
	justify='space-around'
	classes={{ root: Style['largeCard-content-root'] }}
>
	{children}
</Grid>


<HeaderMUIGen
	btnTitle='View all'
	btnOnClick={() => handleClick()}
	title={' '}
>
	<Switch
		checked={switchState}
		onChange={toggleSwitch}
		name='checkedA'
		inputProps={{ 'aria-label': 'primary checkbox' }}
	/>
</HeaderMUIGen>
<Grid
	container
	direction='row'
	alignItems='center'
	justify='space-evenly'
	classes={{ root: Style['largeCard-content-chart-root'] }}
>
	{children}
</Grid>


<Grid
	container
	direction='column'
	alignItems='center'
	justify='center'
	classes={{ root: Style['largeCard-content-root'] }}
>
	{children}
</Grid>
*/
