import React from 'react';
import { OSADetail } from './OSADetailed';
import { PageWrapper } from 'components/lib';

export function OSA(props) {
	return (
		<PageWrapper>
			<OSADetail apiCall='/api/metrics/?osa=osa' />
		</PageWrapper>
	);
}
