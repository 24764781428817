import React, { useEffect, useState } from 'react';
import { Grid, Divider, CircularProgress } from '@material-ui/core';

import {
	ContentSection,
	TableList,
	useCheckAuth,
	useAgentInfo,
	PageWrapper,
	SwitchBtns,
	SwitchBtnsPlchld,
	useAPI,
} from 'components/lib';

import { initState, compileList } from './ISAHelpers';
import { useHistory } from 'react-router-dom';
import { ISA } from '../Overview/MngComponents';
import { compileISA } from '../Overview/MngHelpers';
import { BarChartSection } from '../../agent/ISA/ISAComponents';
import {
	generateChart,
	generateChartFooter,
} from 'views/dashboard/agent/ISA/ISAHelpers';

const MngInitState = {
	loading: false,
	ISA: null,
	timeFrame: false,
};

export function ISAs(props) {
	useCheckAuth();
	useAPI('/auth/check');

	const metrics = useAPI('/api/metrics/leaderboard/ISA');

	//const user = JSON.parse(localStorage.getItem('user'));
	// const managerMetrics = useAPI(
	// 	user.roles?.some((el) => el.name === 'ISA Manager')
	// 		? '/api/metrics/management/ISA'
	// 		: null
	// );

	const managerMetrics = useAPI('/api/metrics/management/ISA');

	const [state, setState] = useState(initState);
	const [stateData, setStateData] = useState(false);
	// const [agent, setAgent] = useState(false);
	// const [viewIndex, setViewIndex] = React.useState(false);

	const { updateAgent, updateListOfAgents, listOfAgentsShort } = useAgentInfo();
	const history = useHistory();

	const [mngState, setMngState] = useState(MngInitState);
	const [mngStateDates, setMngStateDates] = useState(MngInitState);

	useEffect(() => {
		if (managerMetrics.loading) setMngStateDates({ ...mngState, loading: true });
		if (managerMetrics && managerMetrics.data) {
			const currentData30 = managerMetrics.data['30']?.now;
			const oldData30 = managerMetrics.data['30']?.old;
			const currentData90 = managerMetrics.data['90']?.now;
			const oldData90 = managerMetrics.data['90']?.old;
			const currentData365 = managerMetrics.data['365']?.now;
			const oldData365 = managerMetrics.data['365']?.old;
			//console.log('mngr isa metrics', managerMetrics.data);
			const ISA = {
				30:
					currentData30 && oldData30 ? compileISA(currentData30, oldData30) : false,
				90:
					currentData90 && oldData90 ? compileISA(currentData90, oldData90) : false,
				365:
					currentData365 && oldData365
						? compileISA(currentData365, oldData365)
						: false,
			};

			const chartData = {
				30: generateChart(currentData30),
				90: generateChart(currentData90),
				365: generateChart(currentData365),
			};
			const ISABarChartFooter = {
				30: generateChartFooter(
					currentData30
					//data.staleLeads
				),
				90: generateChartFooter(
					currentData90
					// data.staleLeads
				),
				365: generateChartFooter(
					currentData365
					//data.staleLeads
				),
			};

			setMngStateDates({
				...mngState,
				ISA,
				chartData,
				ISABarChartFooter,
				loading: false,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [managerMetrics]);

	const handleTimeSelector = async (index, val) => {
		let indx = parseInt(index);
		switch (indx) {
			case 0:
				await setMngState((previousState) => {
					return {
						...previousState,
						chartData: mngStateDates.chartData[val],
						ISABarChartFooter: mngStateDates.ISABarChartFooter[val],
						loading: false,
					};
				});
				break;
			case 1:
				await setMngState((previousState) => {
					return { ...previousState, ISA: mngStateDates.ISA[val], timeFrame: val };
				});
				break;
			case 2:
				const ISALeaderboard = compileList(stateData[val], state);
				await setState((previousState) => {
					return { ...previousState, ISALeaderboard, loading: false };
				});

				break;
			default:
				break;
		}
	};

	const leadsClb = (type, row) => {
		updateAgent({ agentId: row.id, agentName: row.agent[1], agentType: 'isa' });
		history.push('/manager/agent');
	};
	useEffect(() => {
		if (metrics.data) {
			const metricsDta = metrics.data;
			//console.log('metr?', metrics);

			if (!listOfAgentsShort) {
				updateListOfAgents(Object.entries(metrics.data)[0][1]);
			}

			setStateData(metricsDta);
		}
		if (!metrics.data) setState({ ...state, loading: metrics.loading });
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [metrics]);

	return (
		<PageWrapper>
			<Divider style={{ marginBottom: '0.5em', height: 0 }} />
			<ContentSection
				title='Sales Funnel'
				disableButton
				headerEl={
					mngStateDates.chartData ? (
						<SwitchBtns clb={handleTimeSelector} indx='0' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				<BarChartSection state={mngState} />
			</ContentSection>
			{/* {user.roles?.some((el) => el.name === 'ISA Manager') && ( */}
			<ContentSection
				title='ISAs Performance'
				disableButton
				headerEl={
					mngStateDates.ISA ? (
						<SwitchBtns clb={handleTimeSelector} indx='1' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				<ContentSection type='large'>
					<Grid
						item
						container
						direction='row'
						justify='space-around'
						alignItems='center'
						style={{ height: 180 }}
					>
						<ISA
							data={mngState.ISA}
							loading={managerMetrics.loading}
							timeFrame={mngState.timeFrame}
						/>
					</Grid>
				</ContentSection>
			</ContentSection>
			{/* )} */}
			<ContentSection
				disableButton
				title=' '
				headerEl={
					stateData ? (
						<SwitchBtns clb={handleTimeSelector} indx='2' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				{state.loading ? (
					<CircularProgress />
				) : (
					<TableList
						data={state.ISALeaderboard}
						initialOrderBy='appt held'
						initialOrder='desc'
						callBack={leadsClb}
					/>
				)}
			</ContentSection>
		</PageWrapper>
	);
}
