import React from 'react';
import { ContentSection, BarChartAlt as BarChart } from 'components/lib';

import { Grid, CircularProgress, Typography } from '@material-ui/core';

export const BarChartSection = (props) => {
	const { state } = props;
	return (
		<ContentSection type='large'>
			<div style={{ padding: '0px 15px', width: '100%', minHeight: 240 }}>
				<ContentSection type='barChart' title='Profit breakdown'>
					{state.loading ? (
						<Grid
							item
							container
							justify='center'
							alignItems='center'
							style={{ height: '100%', minHeight: 240 }}
						>
							<CircularProgress />
						</Grid>
					) : (
						<>
							<BarChart
								chartData={state.RevenueChrt}
								customStyle={{
									width: '100%',
									minHeight: 190,

									maxHeight: 190,
								}}
								currency
							/>

							<Grid
								item
								container
								justify='space-evenly'
								alignItems='flex-start'
								style={{
									height: '100%',
									minHeight: 30,
									width: '100%',
									paddingBottom: 10,
								}}
							>
								<Grid
									item
									xs={4}
									container
									direction='column'
									justify='flex-start'
									alignItems='center'
								>
									<Typography variant='h5'># transactions</Typography>
									<Typography variant='h5' color='textSecondary'>
										{state.barChartFooter.total}
									</Typography>
								</Grid>
								<Grid
									item
									xs={4}
									container
									direction='column'
									justify='flex-start'
									alignItems='center'
								>
									<Typography variant='h5'># transactions</Typography>
									<Typography variant='h5' color='textSecondary'>
										{state.barChartFooter.buyersCount}
									</Typography>
								</Grid>
								<Grid
									item
									xs={4}
									container
									direction='column'
									justify='flex-start'
									alignItems='center'
								>
									<Typography variant='h5'># transactions</Typography>
									<Typography variant='h5' color='textSecondary'>
										{state.barChartFooter.sellersCount}
									</Typography>
								</Grid>
							</Grid>
						</>
					)}
				</ContentSection>
			</div>
		</ContentSection>
	);
};
