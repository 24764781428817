// import { Signup } from 'views/auth/signup';
// import { SignupUser } from 'views/auth/signupuser';
// import { Signin } from 'views/auth/signin';
// import { ForgotPassword } from 'views/auth/forgotpassword';
// import { ResetPassword } from 'views/auth/resetpassword';

// import { SignupUser as SignupMUI } from 'views/auth/signup';
import { SignupInvitedUser } from 'views/auth/signupInvite';
import { Signin as SigninMUI } from 'views/auth/signin';
import { ResetPassword as ResetPasswordMUI } from 'views/auth/resetpassword';
import { ForgotPassword as ForgotPasswordMUI } from 'views/auth/forgotpassword';

export default [
	// {
	// 	path: '/master',
	// 	view: Signin,
	// 	layout: 'auth',
	// 	title: 'Sign in to Gravity',
	// },
	// {
	// 	path: '/signup',
	// 	view: Signup,
	// 	layout: 'auth',
	// 	title: 'Sign up to Gravity',
	// },
	// {
	// 	path: '/signup/user',
	// 	view: SignupUser,
	// 	layout: 'auth',
	// 	title: 'Sign up to Gravity',
	// },
	// {
	// 	path: '/signin',
	// 	view: Signin,
	// 	layout: 'auth',
	// 	title: 'Welcome to Gravity',
	// },
	// {
	// 	path: '/forgotpassword',
	// 	view: ForgotPassword,
	// 	layout: 'auth',
	// 	title: 'Forgot Your Password?',
	// },
	// {
	// 	path: '/resetpassword',
	// 	view: ResetPassword,
	// 	layout: 'auth',
	// 	title: 'Reset Your Password',
	// },

	//MUI override
	// {
	// 	path: '/signup',
	// 	view: SignupMUI,
	// 	layout: 'authMUI',
	// 	title: 'Register',
	// },

	{
		path: '/auth/invite/:inviteId',
		view: SignupInvitedUser,
		layout: 'authMUI',
		title: 'Register Invited',
	},

	{
		path: '/signin',
		view: SigninMUI,
		layout: 'authMUI',
		title: 'Signin',
	},
	{
		path: '/auth/restorePass/:resetId',
		view: ResetPasswordMUI,
		layout: 'authMUI',
		title: 'Reset Your Password',
	},
	{
		path: '/forgotpassword',
		view: ForgotPasswordMUI,
		layout: 'authMUI',
		title: 'Forgot Your Password?',
	},
];
