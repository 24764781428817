import React, { useRef, useEffect } from 'react';
import Highcharts from 'highcharts';

export function ChartBare(props) {
	const { chartData = false, customStyle = { width: '100%' } } = props;
	const refContainer = useRef();

	useEffect(() => {
		if (chartData) {
			Highcharts.chart(refContainer.current, chartData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartData]);
	return <div ref={refContainer} style={customStyle} />;
}
