import { Invites } from 'views/accountSettings/usersManagment/invites';
import { Members } from 'views/accountSettings/usersManagment/members';
import { Profile } from 'views/userProfile/profile';
import { MemberPage } from 'views/accountSettings/usersManagment/memberPage';

export default [
	{
		path: '/users_managment/invites',
		view: Invites,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Invites Managment',
	},
	{
		path: '/users_managment/members',
		view: Members,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Members Managment',
	},
	{
		path: '/users_managment/members/personal',
		view: MemberPage,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Member personal page',
	},

	{
		path: '/profile',
		view: Profile,
		layout: 'appMUI',
		//permission: 'user',
		title: 'Account Settings',
	},
];
