import React from 'react';
import { Grid, Divider, Typography } from '@material-ui/core';

import { ContentSection, NavTabs, TableList, BarChart } from 'components/lib';
import { leadsDta, mockSalesDataSecond } from 'data/mockData';
import { SalesLeads, SalesCalls, SalesAppointments, SalesEtc } from './index';

//swith between the screens via router is removed, because of bug and we need to show demo of completed components

const salesTabs = [
	{ label: 'Overview', link: '/sales/leads' },
	{ label: 'Leads', link: '/sales/leads' },
	{ label: 'Calls', link: '' },
	{ label: 'Appointments', link: '/sales/appointments' },
	{ label: 'Etc', link: '' },
];

export function SalesOverview(props) {
	const [viewIndex, setViewIndex] = React.useState(0);
	const handleViewChange = (event) => {
		setViewIndex(event);
	};

	return (
		<Grid
			// container
			// direction='column'
			// justify='flex-start'
			// style={{
			// 	paddingTop: '5em',
			// 	//paddingTop: '5em',
			// 	//maxWidth: '72em',
			// }}
			container
			direction='column'
			justify='flex-start'
			alignItems='flex-start'
			style={{
				paddingTop: '5em',
				minHeight: '100vh',
				maxWidth: '72em',
			}}
		>
			<NavTabs changeView={handleViewChange} items={salesTabs} />
			<Divider style={{ marginBottom: '3em', height: 0 }} />
			{viewIndex === 1 ? (
				<SalesLeads />
			) : viewIndex === 2 ? (
				<SalesCalls />
			) : viewIndex === 3 ? (
				<SalesAppointments />
			) : viewIndex === 4 ? (
				<SalesEtc />
			) : (
				<>
					<ContentSection type='barChart'>
						<BarChart />
						<Divider
							variant='fullWidth'
							style={{
								width: '100%',
								backgroundColor: '#171C22',
								marginBottom: '0.1em',
								height: 2,
							}}
						/>
						<Grid
							container
							direction='row'
							justify='space-around'
							alignItems='center'
						>
							{mockSalesDataSecond.map((i, index) => {
								return (
									<Grid item key={index}>
										<Typography variant='h4' gutterBottom align='center'>
											{i.number1}
										</Typography>

										<Typography color='textSecondary' variant='h5' align='center'>
											{i.label}
										</Typography>
									</Grid>
								);
							})}
						</Grid>
						<Divider style={{ marginTop: 30 }} />
					</ContentSection>
					<ContentSection disableButton title='Sales Funnel Table'>
						<TableList data={leadsDta} />
					</ContentSection>
				</>
			)}
			{/* 
			<ComparisonCard /> */}
			{/* <TableList data={osaDta} /> */}
		</Grid>
	);
}
