import React, { useEffect, useState } from 'react';

import {
	useCheckAuth,
	useAgentInfo,
	PageWrapper,
	useAPI,
} from 'components/lib';

import { OSADetail } from 'views/dashboard/agent/OSA/OSADetailed';

export function OSAAgent(props) {
	useCheckAuth();
	useAPI('/auth/check');
	const [agent, setAgent] = useState(false);
	const { agentId } = useAgentInfo();
	useEffect(() => {
		if (agentId) {
			setAgent(agentId);
			return;
		}
		window.location = '/osas';
	}, [agentId]);

	return (
		<PageWrapper>
			{agent && (
				<OSADetail
					apiCall={agent ? `/api/metrics/OSA/${agent}` : false}
					managerView
				/>
			)}
		</PageWrapper>
	);
}
