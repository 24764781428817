/**
 * Context provider to manage currently selected agent from the list of agents in order to make unique api call for it and generate custom agent page
 */

import React, { useContext, useState } from 'react';

const AgentContext = React.createContext({
	agentId: false,
	agentName: false,
	agentType: false,
	listOfAgentsShort: false,
	updateAgent: () => {},
	updateListOfAgents: () => {},
});

export const useAgentInfo = () => {
	return useContext(AgentContext);
};

export const AgentProvider = (props) => {
	const [agentState, setAgentState] = useState({
		agentName: false,
		agentId: false,
		agentType: false,
		listOfAgentsShort: false,
	});

	const sortList = (list) => {
		if (!list) return false;
		try {
			//sort names
			list.sort((a, b) => {
				//grab first character of name
				const A = a.name.charAt(0).toLowerCase();

				const B = b.name.charAt(0).toLowerCase();

				//compare
				if (A < B) {
					return -1;
				}
				if (A > B) {
					return 1;
				}
				// names must be equal
				return 0;
			});

			return list;
		} catch (error) {
			return list;
		}
	};

	const updateAgent = (agentUpdate) => {
		setAgentState({ ...agentState, ...agentUpdate });
	};
	const updateListOfAgents = (list) => {
		if (!list) return;
		const shortList = list.map((agent) => {
			return {
				id: agent.id,
				name: `${agent.firstName} ${agent.lastName}`,
				avatarId: agent.avatarId,
			};
		});
		const shortListSorted = sortList(shortList);
		setAgentState({
			...agentState,
			listOfAgentsShort: shortListSorted ? shortListSorted : shortList,
		});
	};
	return (
		<AgentContext.Provider
			value={{
				agentId: agentState.agentId,
				agentName: agentState.agentName,
				agentType: agentState.agentType,
				listOfAgentsShort: agentState.listOfAgentsShort,
				updateListOfAgents,
				updateAgent,
			}}
		>
			{props.children}
		</AgentContext.Provider>
	);
};
