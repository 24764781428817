/***
 *
 *   SIGN UP (invited user)
 *   Signup form for invited users
 *
 **********/

import React, { useContext, useState, useEffect } from 'react';

import {
	Form,
	ContentSection,
	CardMUI as Card,
	useNavItems,
	Animate,
	AuthContext,
	useAPI,
	History,
} from 'components/lib';
import { Grid, CircularProgress } from '@material-ui/core';

export function SignupInvitedUser(props) {
	const [state, setState] = useState({
		loading: true,
		invite: null,
	});

	const context = useContext(AuthContext);
	const url = window.location.href;
	const inviteId = url.substr(url.lastIndexOf('/') + 1);
	let requestInviteData = useAPI(`/auth/invite/${inviteId}`);

	let { getRoutes } = useNavItems();

	useEffect(() => {
		if (!requestInviteData) return;
		let inviteData = {
			creatorName: null,
			companyName: null,
		};

		if (requestInviteData.data) {
			if (requestInviteData.data.isUsed) {
				History.push('/');
			}
			inviteData = {
				creatorName: `${
					requestInviteData.data.creator.firstName
						? requestInviteData.data.creator.firstName
						: ''
				} ${
					requestInviteData.data.creator.lastName
						? requestInviteData.data.creator.lastName
						: ''
				}`,
				companyName: 'Reefpoint', //requestInviteData.data.organization.name,
			};

			setState({ ...state, invite: inviteData, loading: false });
		}
	}, [requestInviteData]);

	const afterAuthFn = (res) => {
		context.signinWip(res);
		let tree = getRoutes();
		window.location = tree.length > 0 ? tree[0].link : '/profile';
	};

	return (
		<Animate type='pop'>
			<Grid
				container
				direction='column'
				justify='flex-start'
				style={{
					padding: '4em 0',
					alignItems: 'center',
					minHeight: '100vh',
				}}
			>
				{state.loading ? (
					<CircularProgress />
				) : (
					<ContentSection type='home' title='Register Invited User'>
						<Card>
							<div>
								invitation from {state.invite.creatorName} to {state.invite.companyName}
							</div>
							<Form
								data={{
									firstName: {
										label: 'First Name',
										value: '',
										type: 'text',
										required: true,
										errorMessage: 'Please enter your First Name',
									},
									lastName: {
										label: 'Last Name',
										value: '',
										type: 'text',
										required: true,
										errorMessage: 'Please enter your Last Name',
									},
									email: {
										label: 'E-Mail Address',
										//value: email,
										type: 'email',
										required: true,
									},
									password: {
										label: 'Password',
										type: 'password',
										required: true,
										compareTo: true,
									},
									repeatedPassword: {
										label: 'Confirm Password',
										type: 'password',
										required: true,
										compare: true,
										errorMessage: 'passwords does not match',
									},
								}}
								url={`/auth/invite/${inviteId}`}
								method='POST'
								buttonText='Create Account'
								callback={(res) => afterAuthFn(res)}
							/>
						</Card>
					</ContentSection>
				)}
			</Grid>
		</Animate>
	);
}
