import React, { useState, useEffect } from 'react';
import {
	Grid,
	Divider,
	Typography,
	Chip,
	CircularProgress,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import {
	ContentSection,
	TableList,
	useCheckAuth,
	SwitchBtns,
	SwitchBtnsPlchld,
	useAPI,
	Wrapper,
} from 'components/lib';

import { StatsBlock, BarChartSection } from './ISAComponents';
import {
	initState,
	statsTemplate,
	generateStats,
	generateChart,
	generateChartFooter,
	generateLeadsList,
	generateLeaderboardList,
} from './ISAHelpers';

import Style from 'components/global.scss';

export function ISADetailed(props) {
	const { apiCall = false, managerView = false } = props;
	useCheckAuth();
	apiCall || (window.location = '/profile');
	const metrics = useAPI(apiCall);
	//'/api/metrics/?isa=isa&tag=leaderboard&field=appointmentCount&order=DESC'

	const classes = useStyles();
	// let listRef = useRef(null);
	const [user, setUser] = useState(false);
	const [state, setState] = useState(initState);
	const [stateData, setStateData] = useState(false);

	let newUser = false;
	useEffect(() => {
		setUser({ ...JSON.parse(localStorage.getItem('user')) });
	}, [newUser]);

	useEffect(() => {
		if (metrics.data) {
			try {
				const data = metrics.data;
				const currentData30 = data['30'];
				const currentData90 = data['90'];
				const currentData365 = data['365'];
				//console.log('dt', data);

				const statsCalls = generateStats(statsTemplate, {
					current: data.thisMonthRatio,
					previous: data.lastMonthRatio,
					average: data.avgRatio,
				});
				const commisionCards = generateStats(
					statsTemplate,
					{
						current: data.commissionThisMonth,
						previous: data.commissionLastMonth,
						average: data.commissionAvg,
					},
					true
				);
				const revenueCards = generateStats(
					statsTemplate,
					{
						current: data.revenueThisMonth,
						previous: data.revenueLastMonth,
						average: data.revenueAvg,
					},
					true
				);

				const chartData = {
					30: generateChart(currentData30),
					90: generateChart(currentData90),
					365: generateChart(currentData365),
				};
				const ISABarChartFooter = {
					30: generateChartFooter(currentData30, data.staleLeads),
					90: generateChartFooter(currentData90, data.staleLeads),
					365: generateChartFooter(currentData365, data.staleLeads),
				};

				const AssignedLeadsList = {
					30: generateLeadsList(currentData30),
					90: generateLeadsList(currentData90),
					365: generateLeadsList(currentData365),
				};

				const LeaderboardList = {
					30: generateLeaderboardList(data.leaderboard['30']),
					90: generateLeaderboardList(data.leaderboard['90']),
					365: generateLeaderboardList(data.leaderboard['365']),
				};

				const closedCount = {
					30: currentData30.closedCount,
					90: currentData90.closedCount,
					365: currentData365.closedCount,
				};

				setStateData((previousState) => {
					return {
						...previousState,
						chartData,
						ISABarChartFooter,
						AssignedLeadsList,
						LeaderboardList,
						closedCount,
					};
				});

				setState((previousState) => {
					return {
						...previousState,
						loading: false,
						statsCalls,
						commisionCards,
						revenueCards,
					};
				});
			} catch (e) {
				console.log('err in ISA', e);
				setState({
					...state,
					loading: false,
				});
			}
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [metrics]);

	const leadsClb = (el, row) => {
		if (row.id) {
			const url = `https://reefpointrealty.followupboss.com/2/people/view/${row.id}`;
			window.open(url, '_blank');
		}
	};
	const handleTimeSelector = async (index, val) => {
		let indx = parseInt(index);
		switch (indx) {
			case 0:
				await setState((previousState) => {
					return {
						...previousState,
						chartData: stateData.chartData[val],
						ISABarChartFooter: stateData.ISABarChartFooter[val],
						closedCount: stateData.closedCount[val],
						selectedTimeframe: val,
						loading: false,
					};
				});
				break;
			case 1:
				await setState((previousState) => {
					return {
						...previousState,
						LeaderboardList: stateData.LeaderboardList[val],
						loading: false,
					};
				});

				break;
			case 2:
				await setState((previousState) => {
					return {
						...previousState,
						AssignedLeadsList: stateData.AssignedLeadsList[val],
						loading: false,
					};
				});
				break;
			default:
				break;
		}
	};
	return (
		<>
			{managerView && (
				<Wrapper classes={{ root: classes.selectHeader }}>select</Wrapper>
			)}
			<Divider style={{ marginBottom: '0.5em', height: 0 }} />
			{managerView ||
				(state.loading ? (
					<CircularProgress />
				) : state.closedCount ? (
					<Grid item container direction='row' spacing={1}>
						<Grid item>
							<Typography variant='h3'>{`Hey ${user.firstName} - you've changed`}</Typography>
						</Grid>
						<Grid item>
							<Chip
								label={state.closedCount}
								color='primary'
								className={classes.root}
							/>
						</Grid>
						<Grid item>
							<Typography variant='h3'>
								{state.selectedTimeframe
									? `lives in the last ${
											state.selectedTimeframe === '365'
												? 'year'
												: state.selectedTimeframe + ' days'
									  }!`
									: 'lives!'}
							</Typography>
						</Grid>
					</Grid>
				) : (
					<div style={{ height: 40 }} />
				))}

			<ContentSection disableButton>
				<ContentSection disableButton title='Stats'>
					<StatsBlock state={state} />
				</ContentSection>
			</ContentSection>

			<ContentSection
				title='Your Sales Funnel'
				disableButton
				headerEl={
					stateData.chartData ? (
						<SwitchBtns clb={handleTimeSelector} indx='0' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				<BarChartSection state={state} />
			</ContentSection>

			<ContentSection
				disableButton
				title='Leaderboard'
				headerEl={
					stateData.LeaderboardList ? (
						<SwitchBtns clb={handleTimeSelector} indx='1' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				<TableList
					data={state.LeaderboardList}
					initialOrderBy='appntmnts'
					initialOrder='desc'
					maxRows={3}
					topResults={true}
				/>
			</ContentSection>

			<ContentSection
				disableButton
				title='Leads Assigned'
				headerEl={
					stateData.AssignedLeadsList ? (
						<SwitchBtns clb={handleTimeSelector} indx='2' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				{state.loading ? (
					<Grid item container justify='center' alignItems='center'>
						<CircularProgress />
					</Grid>
				) : (
					<TableList
						data={state.AssignedLeadsList}
						callBack={leadsClb}
						initialOrderBy='updated at'
						initialOrder='desc'
					/>
				)}
			</ContentSection>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		fontSize: '1.6em',
		'& > *': {
			//margin: theme.spacing(0.5),
		},
	},
	selectHeader: {
		width: '100%',
		height: 43,
		padding: 5,
		marginTop: 3,

		position: 'fixed',
		zIndex: 3,
		left: 0,
		paddingLeft: Style['sidebar-width1'],
	},
}));
