import React, { useEffect } from 'react';
import { Grid, Divider } from '@material-ui/core';
import JotformEmbed from 'react-jotform-embed';

import { useCheckAuth, useAPI } from 'components/lib';

export default function Form3() {
	useCheckAuth();
	useAPI('/auth/check');
	useEffect(() => {
		//component did mount
	});
	return (
		<>
			<Grid
				container
				direction='row'
				justify='center'
				style={{
					//maxWidth: "70em",
					paddingTop: '7em',
					minHeight: '100vh',
					width: '100%',
				}}
			>
				<JotformEmbed
					//style={{ width: '100%' }}
					src='https://form.jotformeu.com/202746834973163'
				/>
				<Divider style={{ marginBottom: '3em', height: 0 }} />
			</Grid>
		</>
	);
}
