import React, { useEffect, useState } from 'react';
import {
	Grid,
	Typography,
	Avatar,
	Paper,
	Button,
	Divider,
} from '@material-ui/core';

import {
	ContentSection,
	useCheckAuth,
	PageWrapper,
	ChartAlt as LineChart,
	Wrapper,
	Loader,
	History,
	apiAxiosCall,
	percIncrease,
	useAgentInfo,
} from 'components/lib';

import { BarChart } from 'components/charts/barChartNew';

import { makeStyles } from '@material-ui/core/styles';

import CompariosonStyle from './agentView.module.scss';
import { isNumber } from 'highcharts';

import { projectionLineChartTemplate } from 'data/chartData';

/**
 * TODO
 * loaders
 */

const KPISummaryCard = (props) => {
	const classes = useStyles();
	const {
		title = false,
		subtitle = false,
		number = false,
		ratio = false,
	} = props;

	const SummaryCardClasses = {
		root: classes.SummeryCardRoot,
		//paper: classes.SummeryCardRoot,
	};

	return (
		<Grid item xs={6}>
			<Wrapper classes={SummaryCardClasses}>
				<Grid
					container
					direction='row'
					justify='flex-start'
					alignItems='center'
					spacing={2}
				>
					<Grid item>
						<Typography variant='subtitle1'>{title}</Typography>
					</Grid>
					<Grid item>
						<Typography color='textSecondary' variant='h6'>
							{subtitle}
						</Typography>
					</Grid>
					<Grid item>{number}</Grid>
					<Grid item>
						<TrendIcon ratio={ratio} />
					</Grid>
				</Grid>
			</Wrapper>
		</Grid>
	);
};

const AgentCard = (props) => {
	const {
		picture = false,
		name = false,
		id = false,
		number = false,
		callBackFn = () => {},
	} = props;
	return (
		<Grid
			item
			container
			direction='column'
			justify='flex-start'
			alignItems='center'
			spacing={1}
			xs={1}
			style={{ minHeight: 130, cursor: 'pointer' }}
			onClick={() => callBackFn(id)}
		>
			<Grid item>
				<Avatar
					style={{
						height: '52px',
						width: '52px',
					}}
					src={picture ? process.env.REACT_APP_API_URI + `/image/${picture}` : ''}
				></Avatar>
			</Grid>
			<Grid item>
				<Typography variant='h5' align='center'>
					{name}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant='h5' align='center'>
					{number}
				</Typography>
			</Grid>
		</Grid>
	);
};

const BarChartSection = (props) => {
	const { state } = props;
	const { barChartData = false, barChartFooter = false } = state;
	return (
		<ContentSection type='barChart'>
			{state.loading ? (
				<Grid
					item
					container
					justify='center'
					alignItems='center'
					style={{ height: '100%', minHeight: 230 }}
				>
					<Loader />
				</Grid>
			) : (
				<BarChart
					chartData={barChartData}
					customStyle={{
						width: '100%',
						minHeight: 230,
					}}
					small
				/>
			)}
			{/* {barChartFooter && ( */}
			<>
				<Divider
					variant='fullWidth'
					style={{
						width: '100%',
						backgroundColor: '#171C22',
						marginBottom: '0.1em',
						height: 2,
					}}
				/>
				<Grid
					container
					direction='row'
					justify='space-between'
					alignItems='center'
					style={{ padding: '0 3px', minHeight: 46 }}
				>
					{barChartFooter &&
						barChartFooter.map((i, index) => {
							return (
								<Grid
									item
									key={index}
									style={{
										maxWidth: 80,
										minWidth: 75,
									}}
								>
									<Typography
										variant='h5'
										gutterBottom
										align='center'
										style={{ width: '100%' }}
									>
										{i.value}
									</Typography>

									<Typography
										color='textSecondary'
										variant='h6'
										align='center'
										style={{
											width: '100%',
											transform: 'rotate(-40deg)',
											//height: 26,
											marginTop: 12,
											position: 'relative',
											fontSize: 10,
										}}
									>
										{i.label}
									</Typography>
								</Grid>
							);
						})}
				</Grid>
			</>
			{/* )} */}
			<Divider style={{ marginTop: 30 }} />
		</ContentSection>
	);
};

const kpiTemplate = [
	{
		title: 'Total Leads',
		subtitle: 'Over past 30 days',
		number: false,
		ratio: false,
	},
	{
		title: 'Leads Not Contacted',
		subtitle: 'All agents',
		number: false,
		ratio: false,
	},
	{
		title: 'Yesterday’s Calls',
		subtitle: 'Excl. weekends',
		number: false,
		ratio: false,
	},
	{
		title: 'Total Calls',
		subtitle: 'Over past 30 days',
		number: false,
		ratio: false,
	},
	{
		title: 'Conversations',
		subtitle: 'Over past 30 days',
		number: false,
		ratio: false,
	},
	{
		title: 'Communications',
		subtitle: 'Texts/Emails: past 30 days',
		number: false,
		ratio: false,
	},
	{
		title: 'Appointments',
		subtitle: 'Scheduled in next 30 days',
		number: false,
		ratio: false,
	},
	{
		title: 'Appt Shows',
		subtitle: 'Over past 30 days',
		number: false,
		ratio: false,
	},
	{
		title: 'Average Wait Time',
		subtitle: 'Before lead is contacted',
		number: false,
		ratio: false,
	},
	{
		title: 'Closed vs Pending',
		subtitle: 'Closed over past 30',
		number: false,
		ratio: false,
	},
];

const kpiRawTemplate = {
	//left
	leads: {
		title: 'Total Leads',
		subtitle: 'Over past 30 days',
		number: false,
		ratio: false,
		trend: false,
	},
	yesterdayCalls: {
		title: 'Yesterday’s Calls',
		subtitle: 'Excl. weekends',
		number: false,
		ratio: false,
		trend: false,
	},
	conversationsCount: {
		title: 'Conversations',
		subtitle: 'Over past 30 days',
		number: false,
		ratio: false,
		trend: false,
	},
	appointmentCount: {
		title: 'Appointments',
		subtitle: 'Scheduled in next 30 days',
		number: false,
		ratio: false,
		trend: false,
	},
	averageWaitTime: {
		title: 'Average Wait Time',
		subtitle: 'Before lead is contacted',
		number: false,
		ratio: false,
		trend: false,
	},
	//right
	notContactedCount: {
		title: 'Leads Not Contacted',
		subtitle: 'All agents',
		number: false,
		ratio: false,
		trend: false,
	},
	callsCount: {
		title: 'Total Calls',
		subtitle: 'Over past 30 days',
		number: false,
		ratio: false,
		trend: false,
	},
	communicationsCount: {
		title: 'Communications',
		subtitle: 'Texts/Emails: past 30 days',
		number: false,
		ratio: false,
		trend: false,
	},
	showingsCount: {
		title: 'Appt Shows',
		subtitle: 'Over past 30 days',
		number: false,
		ratio: false,
		trend: false,
	},
	closedPendingRatio: {
		title: 'Closed vs Pending',
		subtitle: 'Closed over past 30',
		number: false,
		ratio: false,
		trend: true,
	},
};
const initState = {
	//
	kpi: kpiTemplate,
	loading: false,
	leaderboard: [],
	barChartData: false,
	barChartFooter: false,
	LineChrt: false,
};

const TrendIcon = (props) => {
	const { ratio = false } = props;
	return (
		ratio && (
			<Paper
				elevation={0}
				classes={{ root: CompariosonStyle['comparison-paper-container'] }}
			>
				<Typography color='textPrimary' variant='h5'>
					{isNumber(ratio.percentage)
						? //  `${ratio.trendVal > 0 ? '+' : ratio.trendVal < 0 ? '-' : ''}
						  `${ratio.percentage}%`
						: '-'}
				</Typography>
			</Paper>
		)
	);
};

export function ISAManagerOverview(props) {
	useCheckAuth();
	const classes = useStyles();
	const { updateAgent, updateListOfAgents } = useAgentInfo();
	const [state, setState] = useState(initState);

	const calculateTrend = (newVal, oldVal) => {
		let percentage = percIncrease(newVal, oldVal);
		let trendVal = Math.sign(percentage);
		return { percentage, trendVal };
	};

	const generateKPI = (data) => {
		if (!data) return false;
		const { now, old } = data;
		const newData = {
			//left column
			leads: now.leadsCount,
			leadsOld: old.leadsCount,
			yesterdayCalls: now.yesterdayCalls,
			yesterdayCallsOld: old.yesterdayCalls,
			conversationsCount: now.conversationsCount,
			conversationsCountOld: old.conversationsCount,
			appointmentCount: now.appointmentCount,
			appointmentCountOld: old.appointmentCount,
			averageWaitTime: now.averageWaitTime,
			averageWaitTimeOld: old.averageWaitTime,
			//right column
			notContactedCount: now.notContactedCount,
			notContactedCountOld: old.notContactedCount,
			callsCount: now.callsCount,
			callsCountOld: old.callsCount,
			communicationsCount: now.communicationsCount,
			communicationsCountOld: old.communicationsCount,
			showingsCount: now.showingsCount,
			showingsCountOld: old.showingsCount,
			closedPendingRatio: now.closedPendingRatio,
			closedPendingRatioOld: old.closedPendingRatio,
		};
		let templateRaw = { ...kpiRawTemplate };
		for (const key in newData) {
			const index = Object.keys(newData).indexOf(key);

			if (index % 2 == 0) {
				//console.log('check', newData[key], newData[`${key}Old`]);
				let num, ratio;
				if (templateRaw[key] && templateRaw[key].trend) {
					num = newData[key] === '-' ? 0 : parseFloat(newData[key].split(':')[0]);
					let numOld = newData[`${key}Old`].split(':')[0];
					ratio = calculateTrend(num, parseFloat(numOld));
				} else {
					num = newData[key];
					ratio = calculateTrend(newData[key], newData[`${key}Old`]);
				}

				templateRaw[key].number = num;
				templateRaw[key].ratio = ratio;
			}
		}
		return [
			templateRaw.leads,
			templateRaw.notContactedCount,
			templateRaw.yesterdayCalls,
			templateRaw.callsCount,
			templateRaw.conversationsCount,
			templateRaw.communicationsCount,
			templateRaw.appointmentCount,
			templateRaw.showingsCount,
			templateRaw.averageWaitTime,
			templateRaw.closedPendingRatio,
		];
	};

	const sortList = (list) => {
		if (!list) return [];
		try {
			//sort names
			list.sort((a, b) => {
				//grab first character of name
				const A = a.showingsCount; //check why its the bottom ones?

				const B = b.showingsCount;

				//compare
				if (A < B) {
					return 1;
				}
				if (A > B) {
					return -1;
				}
				// names must be equal
				return 0;
			});
			return list;
		} catch (error) {
			return [];
		}
	};

	const generateLeaderboard = (data) => {
		if (!data) return [];
		const dataToSelectFrom = data['90']; //timeframe changed from 30 to 90
		//sort
		let sortedList = sortList(dataToSelectFrom);
		//remove excess
		sortedList.length = 6;
		//compile short list
		const leaderBoard = sortedList.map((agent) => {
			return {
				id: agent.id,
				name: `${agent.firstName} ${agent.lastName}`,
				avatarId: agent.avatarId,
				number: agent.showingsCount, //
			};
		});
		return leaderBoard;
	};

	const generateChart = (data) => {
		if (data) {
			return {
				categories: [
					'Leads',
					'Calls',
					'Appntmnts',
					'ApptH',
					'Agrmnts',
					'Escrow',
					'Closed',
				],

				series: [
					{
						name: '',
						data: [
							data.leadsCount ? data.leadsCount : 0,
							data.callsCount ? data.callsCount : 0,
							data.appointmentCount ? data.appointmentCount : 0,
							data.showingsCount ? data.showingsCount : 0,
							data.agreementCount ? data.agreementCount : 0,
							data.escrowCount ? data.escrowCount : 0,
							data.closedCount ? data.closedCount : 0,
						],
						color: '#6179E5',
					},
				],
			};
		}
		return false;
	};

	const compileProjectedLineSeries = (actualData, projectionData, latestDta) => {
		if (actualData && projectionData && latestDta) {
			try {
				const series = [
					{
						name: 'Calls : Appt Ratio',
						data: [...actualData, ...projectionData],
						color: '#6179E5',
						events: {
							legendItemClick: function (e) {
								e.preventDefault();
							},
						},
						marker: { symbol: 'circle' },
						zoneAxis: 'x',
						zones: [
							{
								value: latestDta,
							},
							{
								dashStyle: 'dot',
							},
						],
					},
				];
				return series;
			} catch (e) {
				console.log(e);
			}
		}
		return false;
	};

	const generateLineChart = (actualData, projectionData) => {
		if (actualData) {
			const latestDta = actualData[actualData.length - 1][0];
			const series = compileProjectedLineSeries(
				actualData,
				projectionData,
				latestDta
			);
			const newGraph = {
				...projectionLineChartTemplate,
				chart: {
					...projectionLineChartTemplate.chart,
					height: 312,
				},
				tooltip: {
					...projectionLineChartTemplate.tooltip,
					formatter: function () {
						const unixDate = new Date(this.x);
						const date = `${unixDate.toDateString()}`;
						let label = this.points.reduce(function (s, point) {
							return (
								s +
								`<div style="width:100%;"><span style="color:${
									point.series.color
								}">\u25CF</span> ${point.series.name}: ${point.y.toFixed(1)}</div>`
							);
						}, `<div style="color:#8B8BA1;width:100%;text-align:center;margin:0;padding:0;">${date}</div>`);
						if (this.points.length > 1) {
							const labelSum = this.points.reduce(function (s, point) {
								return s.y + point.y;
							});

							const labelFooter = `<div style="height:2px;width:100%;background-color: #283038;padding:0;margin:3px 0;"></div><div style="color:#8B8BA1;width:100%;text-align:center;">${labelSum.toFixed(
								1
							)}</div>`;
							label = label + labelFooter;
						}

						return label;
					},
				},
				xAxis: {
					...projectionLineChartTemplate.xAxis,
					plotLines: [
						{
							color: '#8B8BA1',
							width: 3,
							value: latestDta, // var.
							zIndex: 1,
						},
					],
				},
				yAxis: {
					...projectionLineChartTemplate.yAxis,
					min: 0,
				},
				series,
			};
			return newGraph;
		}
		return false;
	};

	const generateChartFooter = (data, staleLeads) => {
		if (!data) return false;

		const footer = [
			{ label: 'Stale leads', value: '-' },
			{ label: 'Clls:Lds', value: '-' },
			{ label: 'Clls:Apptmnts', value: '-' },
			{ label: 'Apptmnts:ApptH', value: '-' },
			{ label: 'ApptH:Agrmnts', value: '-' },
			{ label: 'ApptH:ESCROW', value: '-' },
			{ label: 'ApptH:Clsd', value: '-' },
		];
		const footerData = [
			data.staleLeads !== undefined ? data.staleLeads : '-',
			data.callLeadRatio !== undefined ? data.callLeadRatio : '-',
			data.callApptRatio !== undefined ? data.callApptRatio : '-',
			data.apptShowRatio !== undefined ? data.apptShowRatio : '-',
			data.showAgrmntRatio !== undefined ? data.showAgrmntRatio : '-',
			data.showEscrowRatio !== undefined ? data.showEscrowRatio : '-',
			data.showClosedRatio !== undefined ? data.showClosedRatio : '-',
		];

		footerData.forEach((el, elIndex) => {
			footer[elIndex].value = el;
		});

		return footer;
	};

	const populateState = (data) => {
		try {
			const kpi = generateKPI(data);
			const leaderboard = generateLeaderboard(data.leaderBoard);
			//console.log('data.leaderBoard', data.leaderBoard);

			const barChartData = generateChart(data.salesFunnel);
			const barChartFooter = generateChartFooter(data.salesFunnel);

			const LineChrt = generateLineChart(
				data.callApptRatioGraph,
				data.predictions
			);

			setState((prevState) => {
				return {
					...prevState,
					loading: false,
					kpi,
					leaderboard,
					barChartData,
					barChartFooter,
					LineChrt,
				};
			});
		} catch (error) {
			console.log('error in mng overview', error);
			setState((prevState) => {
				return {
					...prevState,
					loading: false,
				};
			});
		}
	};

	useEffect(() => {
		setState({ ...initState, loading: true });
		apiAxiosCall(`/api/metrics/management/ISA/summary`).then((res) => {
			if (!res.err) {
				populateState(res.data);
			}
		});
		apiAxiosCall('/api/metrics/leaderboard/ISA').then((res) => {
			if (!res.err) {
				updateListOfAgents(Object.entries(res.data)[0][1]);
			}
		});
	}, []);

	const agentCallbackFn = (id) => {
		updateAgent({ agentId: id });
		History.push('/manager/agent');
	};

	return (
		<PageWrapper>
			<Grid
				container
				direction='row'
				justify='space-between'
				alignItems='flex-start'
				spacing={1}
			>
				<Grid item xs={6}>
					<ContentSection
						disableButton
						headerEl={
							<div style={{ display: 'flex' }}>
								<Typography variant='h4'>{`Call:appt`}</Typography>
								<Typography
									variant='h4'
									color='textSecondary'
									style={{ marginLeft: 4 }}
								>
									(last 90 days)
								</Typography>
							</div>
						}
					>
						<ContentSection type='large'>
							{state.loading ? (
								<Loader wrapped customStyle={{ height: '100%', minHeight: 312 }} />
							) : (
								<LineChart
									chartData={state.LineChrt}
									customStyle={{ width: '100%', minHeight: 312 }}
								/>
							)}
						</ContentSection>
					</ContentSection>
				</Grid>
				<Grid item xs={6}>
					<ContentSection title='ISA’s Sales funnel' disableButton>
						<BarChartSection state={state} />
					</ContentSection>
				</Grid>
			</Grid>

			<ContentSection
				disableButton
				headerEl={
					<div style={{ display: 'flex' }}>
						<Typography variant='h4'>KPI Summary</Typography>
						{/* <Typography variant='h4' color='textSecondary' style={{ marginLeft: 4 }}>
							(compared to the last 30 days)
						</Typography> */}
					</div>
				}
			>
				<Grid
					container
					direction='row'
					justify='space-between'
					alignItems='flex-start'
					spacing={1}
				>
					{state.kpi?.map((item) => {
						return (
							<KPISummaryCard
								title={item.title}
								subtitle={item.subtitle}
								number={item.number}
								ratio={item.ratio}
								key={Math.random()}
							/>
						);
					})}
				</Grid>
			</ContentSection>
			<ContentSection
				title='Top Agents'
				disableButton
				headerEl={
					<Button
						variant='contained'
						color='primary'
						onClick={() => (window.location = '/isas')}
					>
						See all
					</Button>
				}
			>
				<Wrapper classes={{ root: classes.AgentsWrapper }}>
					<Grid
						classes={{ root: classes.AgentsWrapper }}
						container
						direction='row'
						justify='space-evenly'
						alignItems='center'
					>
						{state.leaderboard.map((agent) => {
							return (
								<AgentCard
									picture={agent.avatarId}
									name={agent.name}
									number={agent.number}
									id={agent.id}
									key={Math.random()}
									callBackFn={agentCallbackFn}
								/>
							);
						})}
					</Grid>
				</Wrapper>
			</ContentSection>
		</PageWrapper>
	);
}

const useStyles = makeStyles((theme) => ({
	SummeryCardRoot: { minHeight: 52, padding: 10 },
	AgentsWrapper: { minHeight: 167, padding: 10, width: '100%' },
}));
