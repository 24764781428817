import React, { useEffect, useState } from 'react';
import { Grid, Divider, Button } from '@material-ui/core';

import {
	ContentSection,
	useCheckAuth,
	ChartAlt as LineChart,
	PageWrapper,
	SwitchBtns,
	SwitchBtnsPlchld,
	Wrapper,
	Loader,
	useAPI,
	History,
} from 'components/lib';

import {
	SalesStats,
	SalesFunnel,
	ISA,
	OSA,
	ChartStats,
	SalesStatsBarChart,
} from './MngComponents';
import {
	initState,
	compileSellFunnel,
	compileISA,
	compileOSA,
	compileChart,
	compileSellers,
	generateChartStats,
	compileSellersBarChart,
	compileBarChart,
} from './MngHelpers';

export function ManagerDashboard(props) {
	useCheckAuth();
	useAPI('/auth/check');

	const [mngState, setMngState] = useState(initState);
	const [mngStateDates, setMngStateDates] = useState(initState);

	const metrics = useAPI('/api/metrics/management/?days=30,90,365');

	useEffect(() => {
		if (metrics.loading) setMngStateDates({ ...mngStateDates, loading: true });
		if (metrics.data) {
			const currentData30 = metrics.data['30']?.now;
			const oldData30 = metrics.data['30']?.old;
			const currentData90 = metrics.data['90']?.now;
			const oldData90 = metrics.data['90']?.old;
			const currentData365 = metrics.data['365']?.now;
			const oldData365 = metrics.data['365']?.old;
			//console.log('mngr metrics', metrics.data);

			const sales = {
				30:
					currentData30 && oldData30
						? compileSellers(currentData30, oldData30)
						: false,
				90:
					currentData90 && oldData90
						? compileSellers(currentData90, oldData90)
						: false,
				365:
					currentData365 && oldData365
						? compileSellers(currentData365, oldData365)
						: false,
			};

			const salesBar = {
				30:
					currentData30 && oldData30
						? compileSellersBarChart(currentData30, oldData30)
						: false,
				90:
					currentData90 && oldData90
						? compileSellersBarChart(currentData90, oldData90)
						: false,
				365:
					currentData365 && oldData365
						? compileSellersBarChart(currentData365, oldData365)
						: false,
			};

			const salesFunnel = {
				30:
					currentData30 && oldData30
						? compileSellFunnel(currentData30, oldData30)
						: false,
				90:
					currentData90 && oldData90
						? compileSellFunnel(currentData90, oldData90)
						: false,
				365:
					currentData365 && oldData365
						? compileSellFunnel(currentData365, oldData365)
						: false,
			};
			const RevenueChrt = {
				30:
					currentData30 && oldData30
						? compileChart([
								{
									name: 'Seller',
									data: currentData30.revenue.sellers,
									color: '#C361E5',
									events: {
										legendItemClick: function (e) {
											e.preventDefault();
										},
									},
									marker: { symbol: 'circle' },
								},
								{
									name: 'Buyer',
									data: currentData30.revenue.buyers,
									color: '#6179E5',
									events: {
										legendItemClick: function (e) {
											e.preventDefault();
										},
									},
									marker: { symbol: 'circle' },
								},
						  ])
						: false,
				90:
					currentData90 && oldData90
						? compileChart([
								{
									name: 'Seller',
									data: currentData90.revenue.sellers,
									color: '#C361E5',
									events: {
										legendItemClick: function (e) {
											e.preventDefault();
										},
									},
									marker: { symbol: 'circle' },
								},
								{
									name: 'Buyer',
									data: currentData90.revenue.buyers,
									color: '#6179E5',
									events: {
										legendItemClick: function (e) {
											e.preventDefault();
										},
									},
									marker: { symbol: 'circle' },
								},
						  ])
						: false,
				365:
					currentData365 && oldData365
						? compileChart([
								{
									name: 'Seller',
									data: currentData365.revenue.sellers,
									color: '#C361E5',
									events: {
										legendItemClick: function (e) {
											e.preventDefault();
										},
									},
									marker: { symbol: 'circle' },
								},
								{
									name: 'Buyer',
									data: currentData365.revenue.buyers,
									color: '#6179E5',
									events: {
										legendItemClick: function (e) {
											e.preventDefault();
										},
									},
									marker: { symbol: 'circle' },
								},
						  ])
						: false,
			};

			const SalesChrt = {
				30:
					currentData30 && oldData30
						? compileChart([
								{
									name: 'sales volume',
									data: currentData30.sales.graph,
									color: '#6179E5',
									events: {
										legendItemClick: function (e) {
											e.preventDefault();
										},
									},
									//	tickInterval: 10 * 24 * 3600 * 1000,
								},
						  ])
						: false,
				90:
					currentData90 && oldData90
						? compileChart([
								{
									name: 'sales volume',
									data: currentData90.sales.graph,
									color: '#6179E5',
									events: {
										legendItemClick: function (e) {
											e.preventDefault();
										},
									},
								},
						  ])
						: false,
				365:
					currentData365 && oldData365
						? compileChart([
								{
									name: 'sales volume',
									data: currentData365.sales.graph,
									color: '#6179E5',
									events: {
										legendItemClick: function (e) {
											e.preventDefault();
										},
									},
								},
						  ])
						: false,
			};
			const SalesBarChrt = {
				30:
					currentData30 && oldData30
						? compileBarChart([
								{
									name: 'sellers',
									data: currentData30.count.graphs.sellers,
									color: '#C361E5',
								},
								{
									name: 'buyers',
									data: currentData30.count.graphs.buyers,
									color: '#6179E5',
								},
						  ])
						: false,
				90:
					currentData90 && oldData90
						? compileBarChart([
								{
									name: 'sellers',
									data: currentData90.count.graphs.sellers,
									color: '#C361E5',
								},
								{
									name: 'buyers',
									data: currentData90.count.graphs.buyers,
									color: '#6179E5',
								},
						  ])
						: false,
				365:
					currentData365 && oldData365
						? compileBarChart([
								{
									name: 'sellers',
									data: currentData365.count.graphs.sellers,
									color: '#C361E5',
								},
								{
									name: 'buyers',
									data: currentData365.count.graphs.buyers,
									color: '#6179E5',
								},
						  ])
						: false,
			};
			const ProfitChrt = {
				30:
					currentData30 && oldData30
						? compileChart([
								{
									name: 'Seller',
									data: currentData30.profit.sellers,
									color: '#C361E5',
									events: {
										legendItemClick: function (e) {
											e.preventDefault();
										},
									},
									marker: { symbol: 'circle' },
								},
								{
									name: 'Buyer',
									data: currentData30.profit.buyers,
									color: '#6179E5',
									events: {
										legendItemClick: function (e) {
											e.preventDefault();
										},
									},
									marker: { symbol: 'circle' },
								},
						  ])
						: false,
				90:
					currentData90 && oldData90
						? compileChart([
								{
									name: 'Seller',
									data: currentData90.profit.sellers,
									color: '#C361E5',
									events: {
										legendItemClick: function (e) {
											e.preventDefault();
										},
									},
									marker: { symbol: 'circle' },
								},
								{
									name: 'Buyer',
									data: currentData90.profit.buyers,
									color: '#6179E5',
									events: {
										legendItemClick: function (e) {
											e.preventDefault();
										},
									},
									marker: { symbol: 'circle' },
								},
						  ])
						: false,
				365:
					currentData365 && oldData365
						? compileChart([
								{
									name: 'Seller',
									data: currentData365.profit.sellers,
									color: '#C361E5',
									events: {
										legendItemClick: function (e) {
											e.preventDefault();
										},
									},
									marker: { symbol: 'circle' },
								},
								{
									name: 'Buyer',
									data: currentData365.profit.buyers,
									color: '#6179E5',
									events: {
										legendItemClick: function (e) {
											e.preventDefault();
										},
									},
									marker: { symbol: 'circle' },
								},
						  ])
						: false,
			};

			const ISA = {
				30:
					currentData30 && oldData30
						? compileISA(currentData30.isa, oldData30.isa)
						: false,
				90:
					currentData90 && oldData90
						? compileISA(currentData90.isa, oldData90.isa)
						: false,
				365:
					currentData365 && oldData365
						? compileISA(currentData365.isa, oldData365.isa)
						: false,
			};
			const OSA = {
				30:
					currentData30 && oldData30
						? compileOSA(currentData30.osa, oldData30.osa)
						: false,
				90:
					currentData90 && oldData90
						? compileOSA(currentData90.osa, oldData90.osa)
						: false,
				365:
					currentData365 && oldData365
						? compileOSA(currentData365.osa, oldData365.osa)
						: false,
			};
			const ProfitStats = {
				30: generateChartStats(currentData30.profit, oldData30.profit),
				90: generateChartStats(currentData90.profit, oldData90.profit),
				365: generateChartStats(currentData365.profit, oldData365.profit),
			};
			const RevenueStats = {
				30: generateChartStats(currentData30.revenue, oldData30.revenue),
				90: generateChartStats(currentData90.revenue, oldData90.revenue),
				365: generateChartStats(currentData365.revenue, oldData365.revenue),
			};

			setMngStateDates({
				...mngStateDates,
				sales,
				salesFunnel,
				RevenueChrt,
				SalesChrt,
				ProfitChrt,
				ISA,
				OSA,
				ProfitStats,
				RevenueStats,
				SalesBarChrt,
				salesBar,
				loading: false,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [metrics]);

	const handleTimeSelector = async (index, val) => {
		let indx = parseInt(index);

		switch (indx) {
			case 0:
				await setMngState((previousState) => {
					// let timeFrame = { ...previousState.timeFrame };
					// timeFrame[index] = val;
					return {
						...previousState,
						sales: mngStateDates.sales[val],
						SalesChrt: mngStateDates.SalesChrt[val],
						timeFrame: { ...previousState.timeFrame, [indx]: val },
					};
				});
				break;
			case 1:
				await setMngState((previousState) => {
					// let timeFrame = { ...previousState.timeFrame };
					// timeFrame[index] = val;
					return {
						...previousState,
						RevenueChrt: mngStateDates.RevenueChrt[val],
						timeFrame: { ...previousState.timeFrame, [indx]: val },
						RevenueStats: mngStateDates.RevenueStats[val],
					};
				});

				break;
			case 2:
				await setMngState((previousState) => {
					return {
						...previousState,
						ProfitChrt: mngStateDates.ProfitChrt[val],
						timeFrame: { ...previousState.timeFrame, [indx]: val },
						ProfitStats: mngStateDates.ProfitStats[val],
					};
				});
				break;
			case 3:
				await setMngState((previousState) => {
					return {
						...previousState,
						salesFunnel: mngStateDates.salesFunnel[val],
						timeFrame: { ...previousState.timeFrame, [indx]: val },
					};
				});
				break;
			case 4:
				await setMngState((previousState) => {
					return {
						...previousState,
						ISA: mngStateDates.ISA[val],
						timeFrame: { ...previousState.timeFrame, [indx]: val },
					};
				});
				break;
			case 5:
				await setMngState((previousState) => {
					return {
						...previousState,
						OSA: mngStateDates.OSA[val],
						timeFrame: { ...previousState.timeFrame, [indx]: val },
					};
				});
				break;

			case 6:
				await setMngState((previousState) => {
					return {
						...previousState,
						salesBar: mngStateDates.salesBar[val],
						SalesBarChrt: mngStateDates.SalesBarChrt[val],
					};
				});
				break;
			default:
				break;
		}
	};

	return (
		<PageWrapper>
			<Divider style={{ marginBottom: '0.5em', height: 0 }} />

			<ContentSection
				title='Closings'
				disableButton
				headerEl={
					mngStateDates.SalesBarChrt && mngStateDates.salesBar ? (
						<SwitchBtns clb={handleTimeSelector} indx='6' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				<ContentSection type='large' fullHeight>
					<Grid item xs={8} container direction='column' justify='center'>
						{mngStateDates.loading ? (
							<Loader wrapped />
						) : (
							<LineChart chartData={mngState.SalesBarChrt} />
						)}
					</Grid>
					<Grid item>
						<Divider
							orientation='vertical'
							style={{ width: '1px', backgroundColor: '#171C22' }}
						/>
					</Grid>
					<SalesStatsBarChart
						data={mngState.salesBar}
						loading={mngStateDates.loading}
						timeFrame={mngState.timeFrame[0]}
					/>
				</ContentSection>
			</ContentSection>

			<ContentSection
				title='Sales Volume'
				disableButton
				headerEl={
					mngStateDates.SalesChrt && mngStateDates.sales ? (
						<SwitchBtns clb={handleTimeSelector} indx='0' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				<ContentSection type='large' fullHeight>
					<Grid item xs={8} container direction='column' justify='center'>
						{mngStateDates.loading ? (
							<Loader wrapped />
						) : (
							<LineChart chartData={mngState.SalesChrt} />
						)}
					</Grid>
					<Grid item>
						<Divider
							orientation='vertical'
							style={{ width: '1px', backgroundColor: '#171C22' }}
						/>
					</Grid>
					<SalesStats
						data={mngState.sales}
						loading={mngStateDates.loading}
						timeFrame={mngState.timeFrame[0]}
					/>
				</ContentSection>
			</ContentSection>

			<Grid
				container
				direction='row'
				justify='space-between'
				alignItems='center'
				spacing={2}
			>
				<Grid item xs={6}>
					<ContentSection
						title='Revenue'
						disableButton
						headerEl={
							mngStateDates.RevenueChrt ? (
								<SwitchBtns clb={handleTimeSelector} indx='1' />
							) : (
								<SwitchBtnsPlchld />
							)
						}
					>
						<ContentSection disableButton>
							<Wrapper customStyle={{ minWidth: '100%', minHeight: 345 }}>
								<Grid
									container
									direction='column'
									justify='center'
									alignItems='stretch'
								>
									<Grid
										item
										container
										direction='row'
										justify='space-evenly'
										alignItems='center'
									>
										<ChartStats
											data={mngState.RevenueStats}
											timeFrame={mngState.timeFrame[1]}
										/>
									</Grid>
									<Divider style={{ backgroundColor: '#171C22', marginTop: '0.4em' }} />
									{mngStateDates.loading ? (
										<Loader wrapped customStyle={{ height: 200 }} />
									) : (
										<LineChart chartData={mngState.RevenueChrt} />
									)}
								</Grid>
							</Wrapper>
						</ContentSection>
					</ContentSection>
				</Grid>
				<Grid item xs={6}>
					<ContentSection
						title='Profit'
						disableButton
						headerEl={
							mngStateDates.ProfitChrt ? (
								<SwitchBtns clb={handleTimeSelector} indx='2' />
							) : (
								<SwitchBtnsPlchld />
							)
						}
					>
						<ContentSection disableButton>
							<Wrapper customStyle={{ minWidth: '100%', minHeight: 345 }}>
								<Grid
									container
									direction='column'
									justify='center'
									alignItems='stretch'
								>
									<Grid
										item
										container
										direction='row'
										justify='space-evenly'
										alignItems='center'
									>
										<ChartStats
											data={mngState.ProfitStats}
											timeFrame={mngState.timeFrame[2]}
										/>
									</Grid>
									<Divider style={{ backgroundColor: '#171C22', marginTop: '0.4em' }} />
									{mngStateDates.loading ? (
										<Loader wrapped customStyle={{ height: 200 }} />
									) : (
										<LineChart chartData={mngState.ProfitChrt} />
									)}
								</Grid>
							</Wrapper>
						</ContentSection>
					</ContentSection>
				</Grid>
			</Grid>

			<ContentSection
				title='Sales Funnel'
				disableButton
				headerEl={
					mngStateDates.salesFunnel ? (
						<SwitchBtns clb={handleTimeSelector} indx='3' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				<ContentSection type='large' fullHeight>
					<Grid
						item
						container
						direction='row'
						justify='space-around'
						alignItems='center'
						style={{ height: 180 }}
					>
						<SalesFunnel
							data={mngState.salesFunnel}
							loading={mngStateDates.loading}
							timeFrame={mngState.timeFrame[3]}
						/>
					</Grid>

					<Divider
						variant='fullWidth'
						style={{
							width: '100%',
							backgroundColor: '#171C22',
							padding: 0,
							height: 2,
							margin: 0,
						}}
					/>

					<Grid
						item
						style={{ maxHeight: 70, minHeight: 70 }}
						container
						direction='row'
						justify='space-around'
						alignItems='center'
					>
						<Button variant='outlined' size='large' disabled>
							Detailed View
						</Button>
					</Grid>
				</ContentSection>
			</ContentSection>

			<ContentSection
				title='Agents Performance'
				disableButton
				headerEl={
					mngStateDates.OSA ? (
						<SwitchBtns clb={handleTimeSelector} indx='5' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				<ContentSection type='large' fullHeight>
					<Grid
						item
						container
						direction='row'
						justify='space-around'
						alignItems='center'
						style={{ height: 180 }}
					>
						<OSA
							data={mngState.OSA}
							loading={mngStateDates.loading}
							timeFrame={mngState.timeFrame[5]}
						/>
					</Grid>

					<Divider
						variant='fullWidth'
						style={{
							width: '100%',
							backgroundColor: '#171C22',
							// marginBottom: '0.1em',
							padding: 0,
							height: 2,
							margin: 0,
						}}
					/>

					<Grid
						item
						style={{ maxHeight: 70, minHeight: 70 }}
						container
						direction='row'
						justify='space-around'
						alignItems='center'
					>
						<Button
							variant='outlined'
							size='large'
							onClick={() => History.push('/osas')}
						>
							Detailed View
						</Button>
					</Grid>
				</ContentSection>
			</ContentSection>
			<ContentSection
				title='ISAs Performance'
				disableButton
				headerEl={
					mngStateDates.ISA ? (
						<SwitchBtns clb={handleTimeSelector} indx='4' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				<ContentSection type='large' fullHeight>
					<Grid
						item
						container
						direction='row'
						justify='space-around'
						alignItems='center'
						style={{ height: 180 }}
					>
						<ISA
							data={mngState.ISA}
							loading={mngStateDates.loading}
							timeFrame={mngState.timeFrame[4]}
						/>
					</Grid>

					<Divider
						variant='fullWidth'
						style={{
							width: '100%',
							backgroundColor: '#171C22',
							padding: 0,
							height: 2,
							margin: 0,
						}}
					/>

					<Grid
						item
						style={{ maxHeight: 70, minHeight: 70 }}
						container
						direction='row'
						justify='space-around'
						alignItems='center'
					>
						<Button
							variant='outlined'
							size='large'
							onClick={() => History.push('/isas')}
						>
							Detailed View
						</Button>
					</Grid>
				</ContentSection>
			</ContentSection>
		</PageWrapper>
	);
}
