import React from 'react';
import MenuBookIcon from '@material-ui/icons/MenuBook';

import { currencyFormatter, getDate, dataValidator } from 'components/lib';

export const statsCardTemplate = [
	{ label: 'This Month', number1: '  -  ' },
	{ label: 'Last Month', number1: '  -  ' },
	{ label: 'Average', number1: '  -  ' },
];

export const barChartFooterTemplate = [
	{ label: 'Open Dispstn', value: ' ' },
	{ label: 'Apptmnts:ApptH', value: ' ' },
	{ label: 'ApptH:Agrmnts', value: ' ' },
	{ label: 'ApptH:ESCROW', value: ' ' },
	{ label: 'ApptH:Closed', value: ' ' },
];

const leadsListTemplateGeneric = {
	headerName: '',
	labels: ['customer', 'type', 'stage', 'lead received', 'last contact'],

	dataSet: [],
	customCells: [
		{ index: 0, celltype: 'clickable' },
		{ index: 0, celltype: 'with-icon' },
		{ index: 3, celltype: 'date' },
		{ index: 4, celltype: 'date' },
	],
	coumnsWithoutSorting: [5],
	cellsFixedSize: [{ index: 0, size: 310 }],
};
const leadsListTemplateWithForms = {
	headerName: '',
	labels: [
		'Customer',
		'Type',
		'Stage',
		'Lead Received',
		'Last Contact',
		'Action Form',
	],
	dataSet: [],
	customCells: [
		{ index: 0, celltype: 'clickable' },
		{ index: 0, celltype: 'with-icon' },
		{ index: 3, celltype: 'date' },
		{ index: 4, celltype: 'date' },
		{ index: 5, celltype: 'btn-icon' },
	],
	coumnsWithoutSorting: [5],
	cellsFixedSize: [{ index: 0, size: 310 }],
};

const leaderboardListTemplate = {
	headerName: '',
	labels: [
		'agent',
		//'appt held',
		//'aggrmnts',
		'escrow',
		'closed',
	],
	dataSet: [],
	customCells: [{ index: 0, celltype: 'with-icon' }],
	coumnsWithoutSorting: [0],
	cellsFixedSize: [{ index: 0, size: 310 }],
};
const listTabsTemplate = [
	{ label: `Active`, chip: 0 },
	{ label: 'Closed', chip: 0 },
	{ label: 'Nurture', chip: 0 },
	{ label: 'Pending', chip: 0 },
	{ label: 'Open Dispositions', chip: 0 },
];
export const initState = {
	loading: true,
	closedCount: false,
	//alert: false,
	missingDispositions: -1,

	chartData: {},
	OSABarChartFooter: barChartFooterTemplate,
	revenueCards: [
		{ label: 'This Month', number1: ' ' },
		{ label: 'Last Month', number1: ' ' },
		{ label: 'Average', number1: ' ' },
	],

	AssignedLeadsListActive: leadsListTemplateGeneric,
	AssignedLeadsListNurture: leadsListTemplateGeneric,
	AssignedLeadsListClosed: leadsListTemplateGeneric,
	AssignedLeadsListPending: leadsListTemplateGeneric,
	AssignedLeadsListOpenDispo: leadsListTemplateWithForms,

	OSALeaderboard: leaderboardListTemplate,

	apptSignRatioCards: statsCardTemplate,
	commisionCards: statsCardTemplate,
	osaListTabs: listTabsTemplate,
};

export const chartData = {
	categories: [
		'Appointments',
		//'Appointments Held',
		//'Agreements',
		'Escrow',
		'Closed',
	],
	series: [
		{
			name: 'WIP',
			data: [48, 40, 32, 20, 16, 10],
			color: '#6179E5',
		},
	],
};

export const generateStats = (
	template = false,
	data = false,
	isCurrency = false
) => {
	if (template && data) {
		const stats = template.map((t) => {
			return { ...t };
		});
		stats.map((item, index) => {
			const label = item.label.toLocaleLowerCase();
			let value = false;
			switch (label) {
				case 'this month':
					value = data.current;

					if (isCurrency) {
						return (item.number1 =
							value !== undefined && !isNaN(value)
								? currencyFormatter.format(parseInt(value))
								: item.number1);
					} else {
						return (item.number1 = value
							? value.length > 7
								? value.replace(/\s/g, '')
								: value
							: item.number1);
					}
				case 'last month':
					value = data.previous;
					if (isCurrency) {
						return (item.number1 =
							value !== undefined && !isNaN(value)
								? currencyFormatter.format(parseInt(value))
								: item.number1);
					}
					return (item.number1 = value
						? value.length > 7
							? value.replace(/\s/g, '')
							: value
						: item.number1);
				case 'average':
					value = data.average;
					if (isCurrency) {
						return (item.number1 =
							value !== undefined && !isNaN(value)
								? currencyFormatter.format(parseInt(value))
								: item.number1);
					}
					return (item.number1 = value
						? value.length > 7
							? value.replace(/\s/g, '')
							: value
						: item.number1);
				default:
					break;
			}
			return item;
		});
		return stats;
	}
	return false;
};

export const generateChartFooter = (data, staleLeads) => {
	if (!data) return false;

	const footer = barChartFooterTemplate.map((t) => {
		return { ...t };
	});
	const footerData = [
		data.missingDispositions !== undefined ? data.missingDispositions : '-',
		data.apptShowRatio !== undefined ? data.apptShowRatio : '-',
		data.showAgrmntRatio !== undefined ? data.showAgrmntRatio : '-',
		data.showEscrowRatio !== undefined ? data.showEscrowRatio : '-',
		data.showClosedRatio !== undefined ? data.showClosedRatio : '-',
	];

	footerData.forEach((el, elIndex) => {
		footer[elIndex].value = el;
	});

	return footer;
};

export const generateLeadsList = (data, listType = false) => {
	if (!data) return false;
	if (listType) listType = listType.toLocaleLowerCase();
	const template = JSON.parse(
		JSON.stringify(
			listType === 'opendispo'
				? leadsListTemplateWithForms
				: leadsListTemplateGeneric
		)
	);
	const leadsData = [];
	data.leads.map((lead, leadIndex) => {
		if (lead) {
			let readyData = {
				icons: [
					{
						index: 0,
						type: 'avatar',
						link: lead.picture
							? process.env.REACT_APP_API_URI + `/image/${lead.picture}`
							: '',
						color: '#fff',
					},
				],
				values: [
					[lead.name ? lead.name : null],
					[lead.leadType ? lead.leadType : null],
					[lead.stage ? lead.stage : null],
					[lead.created ? getDate(lead.created, true) : null],
					[lead.lastContact ? getDate(lead.lastContact, true) : null],
					listType === 'opendispo'
						? [['form', 'primary', <MenuBookIcon />, lead.link ? lead.link : null]]
						: null,
				],
				id: lead.fubId ? lead.fubId : null,
			};

			if (listType === 'active' && lead.stage !== 'Closed') {
				return leadsData.push(readyData);
			}
			if (lead.missingDisposition && listType === 'opendispo') {
				return leadsData.push(readyData);
			}
			if (lead.hasNurtureTag && listType === 'nurture') {
				return leadsData.push(readyData);
			}
			if (lead.stage === 'Closed' && listType === 'closed') {
				return leadsData.push(readyData);
			}
			if (lead.stage === 'Pending' && listType === 'pending') {
				return leadsData.push(readyData);
			}
		}
		return lead;
	});

	template.dataSet = leadsData;
	return template;
};

export const generateLeaderboardList = (data) => {
	if (!data) return false;

	const template = JSON.parse(JSON.stringify(leaderboardListTemplate));

	const leadersData = [];
	data.map((agent, agentIndex) => {
		leadersData.push({
			icons: [
				{
					index: 0,
					type: 'avatar',
					link: agent.avatarId
						? process.env.REACT_APP_API_URI + `/image/${agent.avatarId}`
						: '',
					color: '#fff',
				},
			],
			values: [
				[
					dataValidator(
						{ firstName: agent.firstName, lastName: agent.firstName },
						'name-short'
					),
				],
				//	[dataValidator(agent.appointmentCount)],
				//	[dataValidator(agent.agreementCount)],
				[dataValidator(agent.escrowCount)],
				[dataValidator(agent.closedCount)],
			],
		});
		return agent;
	});
	template.dataSet = leadersData;
	return template;
};

export const generateChart = (data) => {
	if (data) {
		return {
			categories: [
				'Appointments',
				'Appointments Held',
				'Agreements',
				'Escrow',
				'Closed',
			],
			series: [
				{
					name: 'WIP',
					data: [
						data.appointmentCount ? data.appointmentCount : 0,
						data.showingsCount ? data.showingsCount : 0,
						data.agreementCount ? data.agreementCount : 0,
						data.escrowCount ? data.escrowCount : 0,
						data.closedCount ? data.closedCount : 0,
					],
					color: '#6179E5',
				},
			],
		};
	}
	return false;
};

export const countListTabsVals = (data) => {
	if (!data) return false;
	const timeTemplate = {
		30: listTabsTemplate.map((t) => {
			return { ...t };
		}),
		90: listTabsTemplate.map((t) => {
			return { ...t };
		}),
		365: listTabsTemplate.map((t) => {
			return { ...t };
		}),
	};
	Object.keys(timeTemplate).forEach((time) => {
		timeTemplate[time].map((el) => {
			return (el.chip = data[el.label][time].dataSet.length);
		});
	});
	return timeTemplate;
};
