import React, { useState, useEffect, useRef } from 'react';
import Highcharts from 'highcharts';

import Style from 'components/charts/barChart.module.scss';
import { currencyFormatterCompact } from 'components/lib';

// export const chartData = {
// 	categories: ['Leads', 'Calls', 'Appntmnts', 'Showings', 'Agrmnts', 'Closed'],

// 	series: [
// 		{
// 			name: 'Other',
// 			data: [10, 4, 2, 2, 5, 1],
// 			color: '#6179E5',
// 		},
// 	],
// };

export const barChartOptions = {
	chart: {
		type: 'column',
		backgroundColor: 'transparent',
	},

	legend: {
		enabled: false,
		align: 'right',
		verticalAlign: 'top',
		margin: 0,

		width: '30%',
		layout: 'horizontal',
		itemStyle: { color: '#fff' },
		dashStyle: 'dot',
	},
	title: {
		text: '',
		enabled: false,
	},
	xAxis: {
		labels: {
			style: {
				color: '#fff',
				fontSize: '1.2em',
				fontWeight: 900,
				fontFamily: 'AvenirHeavy, AvenirRoman',
			},
		},
		lineWidth: 0,
		categories: null,
	},

	yAxis: {
		labels: {
			enabled: false,
		},
		gridLineWidth: 0,
		allowDecimals: false,
		//min: 0,
		title: {
			enabled: false,
		},
		type: 'logarithmic', //this
	},

	plotOptions: {
		column: {
			stacking: 'normal',

			borderColor: 'transparent',
			enableMouseTracking: false,
		},
		columnrange: {},
	},
	series: null,
	credits: {
		enabled: false,
	},
};

export function BarChart(props) {
	const {
		chartData = false,
		customStyle = {
			width: '100%',
			minHeight: 400,
		},
		currency = false,
		small = false,
	} = props;
	const [chartDatas, setChartData] = useState([]);
	useEffect(() => {
		setChartData(chartData);
	}, [chartData]);
	const [chart, setChart] = useState(null);

	const [initialized, setInitialized] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [labelData, setLabelData] = useState([]);
	//const [totalLabelData, setTotalLabelData] = useState([]);
	const refContainer = useRef();

	useEffect(() => {
		if (initialized)
			chart.update({
				xAxis: {
					labels: {
						//	align: 'right',
						formatter: function () {
							return `${this.value}<br/> ${labelData[this.pos]}`;
							//this.chart.series[0].stackedYData[this.pos]
						},
					},
				},
			});
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [labelData]);

	// const setData = (data, type) => {
	// 	//
	// };

	useEffect(() => {
		if (chartData) {
			setChart(
				Highcharts.chart(refContainer.current, {
					...barChartOptions,

					xAxis: {
						labels: {
							...barChartOptions.xAxis.labels,
							formatter: function () {
								//setTotalLabelData(this.chart.series[0].stackedYData);
								return `${this.value}<br/> ${
									currency
										? currencyFormatterCompact.format(
												this.chart.series[0].stackedYData[this.pos]
										  )
										: //labelData[this.pos]
										  this.chart.series[0].stackedYData[this.pos]
								}`;

								//this.chart.series(name).data(category).total
								//value: "Leads" - category pos - index
							},
							style: {
								color: '#fff',
								fontSize: !small ? '1.2em' : '11px',
								fontWeight: 900,
								fontFamily: 'AvenirHeavy, AvenirRoman',
							},
						},

						lineWidth: 0,
						categories: chartDatas.categories,
						//type: 'logarithmic',
					},
					tooltip: {
						enabled: false,
						formatter: function () {
							return (
								'<b>' +
								this.x +
								'</b><br/>' +
								this.series.name +
								': ' +
								this.y +
								'<br/>' +
								'Total: ' +
								this.point.stackTotal
							);
						},
					},
					plotOptions: {
						...barChartOptions.plotOptions,
						series: {
							point: {},
						},
					},
					series: chartDatas.series,
				})
			);
			setInitialized(true);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartDatas]);

	return (
		<>
			<div className={Style.highchartMe} ref={refContainer} style={customStyle} />
		</>
	);
}
