import { lineDataMngTemplate, SalesColoredBarChart } from 'data/chartData';

import { currencyFormatterCompact } from 'components/lib';

export const initState = {
	loading: false,
	sales: false,
	salesBar: false,
	salesFunnel: null,
	SalesChrt: null,
	SalesBarChrt: null,
	RevenueChrt: null,
	ProfitChrt: null,
	ProfitStats: null,
	RevenueStats: null,
	ISA: null,
	OSA: null,
	timeFrame: {
		0: false,
		1: false,
		2: false,
		3: false,
		4: false,
		5: false,
	},
};

export const compileSellFunnel = (currentData, oldData) => {
	return {
		leads: currentData.leadsCount,
		leadsPrice: currentData.leadsPrice,
		apptSh: currentData.showingsCount,
		apptsPrice: currentData.showingsPrice,
		closing: currentData.closedCount,
		closedPrice: currentData.closedPrice,

		leadsOld: oldData.leadsCount,
		leadsPriceOld: oldData.leadsPrice,
		apptShOld: oldData.showingsCount,
		apptsPriceOld: oldData.showingsPrice,
		closingOld: oldData.closedCount,
		closedPriceOld: oldData.closedPrice,

		leadApptRatio: currentData.leadApptRatio,
		leadApptRatioOld: oldData.leadApptRatio,

		apptClosedRatio: currentData.apptClosedRatio,
		apptClosedRatioOld: oldData.apptClosedRatio,
	};
};

export const compileISA = (currentData, oldData) => {
	return {
		calls: currentData.callsCount,
		callsOld: oldData.callsCount,
		conversations: currentData.conversationsCount,
		conversationsOld: oldData.conversationsCount,
		apptH: currentData.showingsCount,
		apptHOld: oldData.showingsCount,
		callConvRatio: currentData.callConvRatio,
		callConvRatioOld: oldData.callConvRatio,
		convShowRatio: currentData.convShowRatio,
		convShowRatioOld: oldData.convShowRatio,

		callsPrice: currentData.callsPrice,
		callsPriceOld: oldData.callsPrice,

		conversationsPrice: currentData.conversationsPrice,
		conversationsPriceOld: oldData.conversationsPrice,

		apptHPrice: currentData.showingsPrice,
		apptHOldPrice: oldData.showingsPrice,
	};
};
export const compileOSA = (currentData, oldData) => {
	return {
		apptH: currentData.showingsCount,
		apptHOld: oldData.showingsCount,

		showAgreeRatio: currentData.showAgrmntRatio,
		showAgreeRatioOld: oldData.showAgrmntRatio,

		agrmntSgnd: currentData.agreementCount,
		agrmntSgndOld: oldData.agreementCount,

		closed: currentData.closedCount,
		closedOld: oldData.closedCount,

		agreeClosedRatio: currentData.agreeClosedRatio,
		agreeClosedRatioOld: oldData.agreeClosedRatio,

		showingsPrice: currentData.showingsPrice,
		showingsPriceOld: oldData.showingsPrice,

		agreementPrice: currentData.agreementPrice,
		agreementPriceOld: oldData.agreementPrice,

		closedPrice: currentData.closedPrice,
		closedPriceOld: oldData.closedPrice,
	};
};

export const compileChart = (series) => {
	const chart = {
		...lineDataMngTemplate,
		series,

		yAxis: {
			...lineDataMngTemplate.yAxis,
			labels: {
				...lineDataMngTemplate.yAxis.labels,
				formatter: function () {
					const label = this.axis.defaultLabelFormatter.call(this);
					return `$${label}`;
				},
			},
		},
		xAxis: {
			...lineDataMngTemplate.xAxis,
			offset: 2,
			labels: {
				...lineDataMngTemplate.xAxis.labels,

				y: 18,
			},
		},
	};

	return chart;
};

export const compileBarChart = (series) => {
	//
	let template = Object.assign({}, SalesColoredBarChart);
	const chart = {
		...template,

		series,
	};
	return chart;
};

//SalesBarChrt: null,
export const compileSellers = (currentData, oldData) => {
	const sellersNow = currentData.sales.sellers;
	const buyersNow = currentData.sales.buyers;
	const sellersOld = oldData.sales.sellers;
	const buyersOld = oldData.sales.buyers;
	return {
		sales: currentData.sales.volume,
		buyers: buyersNow,
		sellers: sellersNow,
		total: 0,
		//currencyFormatterCompact.format()
		salesRaw: currentData.sales.volume,
		salesRawOld: oldData.sales.volume,
		buyersOld: buyersOld,
		sellersOld: sellersOld,
		totalOld: oldData.total,
	};
};

export const compileSellersBarChart = (currentData, oldData) => {
	const sellersNow = currentData.count.sellers;
	const buyersNow = currentData.count.buyers;
	const sellersOld = oldData.count.sellers;
	const buyersOld = oldData.count.buyers;
	const totalNow = currentData.count.total;
	const totalOld = oldData.count.total;
	return {
		buyers: buyersNow,
		sellers: sellersNow,
		total: totalNow,

		buyersOld: buyersOld,
		sellersOld: sellersOld,
		totalOld: totalOld,
	};
};

export const generateChartStats = (currentData, oldData) => {
	if (!currentData && !oldData) return false;

	try {
		return {
			buyers: currentData.buyerSum,
			sellers: currentData.sellerSum,
			total: currentData.totalSum,

			buyersOld: oldData.buyerSum,
			sellersOld: oldData.sellerSum,
			totalOld: oldData.totalSum,
		};
	} catch (e) {
		console.log(e);
	}
};
