import React, { useEffect, useState } from 'react';
import {
	useCheckAuth,
	useAgentInfo,
	PageWrapper,
	useAPI,
} from 'components/lib';

import { ISADetailed } from 'views/dashboard/agent/ISA/ISADetailed';

export function ISAAgent(props) {
	useCheckAuth();
	const [agent, setAgent] = useState(false);
	const { agentId } = useAgentInfo();

	useEffect(() => {
		if (agentId) {
			setAgent(agentId);
			return;
		}
		window.location = '/isas';
	}, [agentId]);

	return (
		<PageWrapper>
			{agent && (
				<ISADetailed
					apiCall={agent ? `/api/metrics/ISA/${agent}` : false}
					managerView
				/>
			)}
		</PageWrapper>
	);
}
