import { currencyFormatterCompact, getDate } from 'components/lib';
import { projectionLineChartTemplate } from 'data/chartData';

export const initState = {
	loading: false,

	listTabs: [
		{ label: `All`, chip: 0 },
		{ label: 'Buyers', chip: 0 },
		{ label: 'Sellers', chip: 0 },
	],
	barChartFooter: false,
	listAll: {
		headerName: '',
		labels: ['address', 'type', 'status', 'commision', 'closing date'],
		dataSet: [],
		customCells: [
			{ index: 0, celltype: 'with-icon' },
			{ index: 3, celltype: 'currency' },
			{ index: 4, celltype: 'date' },
		],
		coumnsWithoutSorting: [0],
		cellsFixedSize: [{ index: 0, size: 310 }],
	},
	listBuyers: {
		headerName: '',
		labels: ['address', 'type', 'status', 'commision', 'closing date'],
		dataSet: [],
		customCells: [
			{ index: 0, celltype: 'with-icon' },
			{ index: 3, celltype: 'currency' },
			{ index: 4, celltype: 'date' },
		],
		coumnsWithoutSorting: [0],
		cellsFixedSize: [{ index: 0, size: 310 }],
	},
	listSellers: {
		headerName: '',
		labels: ['address', 'type', 'status', 'commision', 'closing date'],
		dataSet: [],
		customCells: [
			{ index: 0, celltype: 'with-icon' },
			{ index: 3, celltype: 'currency' },
			{ index: 4, celltype: 'date' },
		],
		coumnsWithoutSorting: [0],
		cellsFixedSize: [{ index: 0, size: 310 }],
	},

	SalesChrt: null,
	RevenueChrt: null,
	transactionsStats: null,
	revenueStats: null,
};

export const generateList = (data, list, type = false) => {
	if (data) {
		let listData = [];
		data.map((element, elenentIndex) => {
			if (!type || element.type === type) {
				const address = `
				${element.address.street ? element.address.street : '-'},
				${element.address.city ? element.address.city : '-'}, 
				${element.address.state ? element.address.state : '-'}, 
				${element.address.postal ? element.address.postal : '-'}`;
				//console.log('123', getDate(element.closingDate, true, true));
				listData.push({
					icons: [
						{
							index: 0,
							type: 'icon',
							link: 'house',
							color: element.type === 'seller' ? '#C361E5' : '#6179E5',
						},
					],
					values: [
						[address],
						[element.type],
						[element.status],
						[element.commission ? element.commission : -1],
						//[0],
						[element.closingDate],
						//[getDate(element.closingDate, true)],
					],
					// // id: elenent.id ? elenent.id : null,
				});
			}
			return element;
		});
		let newList = { ...list };
		if (listData.length > 0) newList.dataSet = listData;
		return newList;
	}
	return false;
};

const compileProjectedLineSeries = (actualData, projectionData, latestDta) => {
	if (actualData && projectionData && latestDta) {
		try {
			let sellersActual = actualData['sellers'],
				buyersActual = actualData['buyers'],
				sellersProj = projectionData['sellers'],
				buyersProj = projectionData['buyers'];
			const sellerData = [...sellersActual, ...sellersProj],
				buyerData = [...buyersActual, ...buyersProj];
			const series = [
				{
					name: 'Sellers',
					data: sellerData,
					color: '#C361E5',
					events: {
						legendItemClick: function (e) {
							e.preventDefault();
						},
					},
					marker: { symbol: 'circle' },
					zoneAxis: 'x',
					zones: [
						{
							value: latestDta,
						},
						{
							dashStyle: 'dot',
						},
					],
				},
				{
					name: 'Buyers',
					data: buyerData,
					color: '#6179E5',
					events: {
						legendItemClick: function (e) {
							e.preventDefault();
						},
					},
					marker: { symbol: 'circle' },
					zoneAxis: 'x',
					zones: [
						{
							value: latestDta,
						},
						{
							dashStyle: 'dot',
						},
					],
				},
			];
			return series;
		} catch (e) {
			console.log(e);
		}
	}
	return false;
};

export const generateLineChart = (actualData, projectionData) => {
	if (actualData) {
		let sellers = actualData['sellers'],
			buyers = actualData['buyers'];
		let lastSeller = sellers[sellers.length - 1][0], //refactor
			lastBuyer = buyers[buyers.length - 1][0];
		const latestDta = Math.max(lastSeller, lastBuyer);
		const series = compileProjectedLineSeries(
			actualData,
			projectionData,
			latestDta
		);
		const newGraph = {
			...projectionLineChartTemplate,
			xAxis: {
				...projectionLineChartTemplate.xAxis,
				plotLines: [
					{
						color: '#8B8BA1',
						width: 3,
						value: latestDta, // var.
						zIndex: 1,
					},
				],
			},
			yAxis: {
				...projectionLineChartTemplate.yAxis,
				labels: {
					...projectionLineChartTemplate.yAxis.labels,
					formatter: function () {
						const label = this.axis.defaultLabelFormatter.call(this);
						return `$${label}`;
					},
				},
			},
			xAxis: {
				...projectionLineChartTemplate.xAxis,
				offset: 2,
				labels: {
					...projectionLineChartTemplate.xAxis.labels,

					y: 22,
				},
			},
			series,
		};
		return newGraph;
	}
	return false;
};

export const generateBarChart = (data) => {
	if (data) {
		const chartData = {
			categories: ['Total Profit', 'Buyers', 'Sellers'],
			series: [],
		};
		chartData.series = [
			{
				name: 'Profit breakdown',
				data: [
					data.allProfit ? data.allProfit : 0,
					data.buyersProfit ? data.buyersProfit : 0,
					data.sellersProfit ? data.sellersProfit : 0,
				],
				color: '#6179E5',
			},
		];
		return chartData;
	}
	return false;
};

export const transactionsStatsTemp = [
	{ label: '# transactions', number1: '-' },
	{ label: 'Cancelled deals', number1: '-' },
];
export const revenueStatsTemp = [
	{ label: 'Profit generated', number1: '-' },
	{ label: 'Profit projected', number1: '-' },
];

export function generateStats(
	template = false,
	num1,
	num2,
	isCurrency = false
) {
	if (template && num1 !== undefined && num2 !== undefined) {
		const stats = template.map((t) => {
			return { ...t };
		});

		stats[0]['number1'] = isCurrency
			? typeof num1 === 'number'
				? currencyFormatterCompact.format(num1)
				: num1
			: num1;
		stats[1]['number1'] = isCurrency
			? typeof num2 === 'number'
				? currencyFormatterCompact.format(num2)
				: num2
			: num2;

		return stats;
	}
	return false;
}
