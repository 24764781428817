//OVERVIEW

import {
	projectionMultipleLineChartTemplate as projectionLineChartTemplate,
	MarketingColoredBarChart,
	MarketingPieChart,
} from 'data/chartData';

import {
	percIncrease,
	currencyFormatter,
	currencyFormatterCompact,
} from 'components/lib';
import pointer from 'icons/pointer.svg';
import radioTv from 'icons/radioTv.svg';
import externalSource from 'icons/externalSource.svg';

const compileProjectedLineSeries = (actualData, projectionData, latestDta) => {
	if (actualData && projectionData && latestDta) {
		try {
			const series = [];
			for (const key in actualData) {
				if (Object.hasOwnProperty.call(actualData, key)) {
					series.push({
						name: key,
						data: [...actualData[key], ...projectionData[key]],
						color:
							key === 'Digital'
								? '#C361E5'
								: key === 'TV/Radio'
								? '#61E5CD'
								: key === 'Print'
								? '#6179E5'
								: '#E5B061',

						events: {
							legendItemClick: function (e) {
								e.preventDefault();
							},
						},
						marker: { symbol: 'circle' },
						zoneAxis: 'x',
						zones: [
							{
								value: latestDta,
							},
							{
								dashStyle: 'dot',
							},
						],
					});
				}
			}
			return series;
		} catch (e) {
			console.log(e);
		}
	}
	return false;
};
export const generateLineChart = (actualData, projectionData) => {
	if (actualData) {
		const latestDta =
			actualData[Object.keys(actualData)[0]][
				actualData[Object.keys(actualData)[0]].length - 1
			][0];
		const series = compileProjectedLineSeries(
			actualData,
			projectionData,
			latestDta
		);
		const newGraph = {
			...projectionLineChartTemplate,
			chart: {
				...projectionLineChartTemplate.chart,
				height: 312,
			},
			tooltip: {
				...projectionLineChartTemplate.tooltip,
				formatter: function () {
					const unixDate = new Date(this.x);
					const date = `${unixDate.toDateString()}`;
					let label = this.points.reduce(function (s, point) {
						return (
							s +
							`<div style="width:100%;"><span style="color:${
								point.series.color
							}">\u25CF</span> ${point.series.name}: ${point.y.toFixed(1)}</div>`
						);
					}, `<div style="color:#8B8BA1;width:100%;text-align:center;margin:0;padding:0;">${date}</div>`);

					return label;
				},
			},
			xAxis: {
				...projectionLineChartTemplate.xAxis,
				plotLines: [
					{
						color: '#8B8BA1',
						width: 3,
						value: latestDta,
						zIndex: 1,
					},
				],
			},
			series,
		};
		return newGraph;
	}
	return false;
};

//

export const calculateTrend = (newVal, oldVal, diffType) => {
	if (diffType && (typeof newVal === 'string' || typeof oldVal === 'string'))
		return { percentage: 0, trendVal: 0 };
	let percentage = percIncrease(newVal, oldVal);
	let trendVal = Math.sign(percentage);
	return { percentage, trendVal };
};

export const copmpileMarketingSpend = (currentData, oldData) => {
	if (currentData === undefined || oldData === undefined) return false;
	const currency =
		typeof currentData === 'number'
			? currencyFormatter.format(currentData)
			: currentData;
	const percentage = calculateTrend(currentData, oldData, true).percentage;
	return {
		label: 'Total marketing spent',
		number1: currency,
		number2: `${percentage}`,
	};
};

//

export const generateStatusCards = (data) => {
	if (!data) return false;
	const list = [];
	const columnsLabels = Object.keys(data);
	const rowLabels = Object.keys(data[columnsLabels[0]]);

	rowLabels.forEach((el) => {
		const icon =
			el === 'Digital' ? pointer : el === 'TV/Radio' ? radioTv : externalSource;
		let row = { label: el, picture: icon };
		let index = 1;
		for (const property in data) {
			row[`num${index}`] = data[property][el];
			index++;
		}

		list.push(row);
	});
	return list;
};

export const generatePieChartLegend = (data) => {
	const seriesData = [];
	for (const key in data) {
		if (Object.hasOwnProperty.call(data, key)) {
			seriesData.push({
				name: key,
				value: currencyFormatterCompact.format(data[key]),
				icon:
					key === 'Digital'
						? pointer
						: key === 'TV/Radio'
						? radioTv
						: externalSource,
				color:
					key === 'Digital'
						? '#C361E5'
						: key === 'TV/Radio'
						? '#61E5CD'
						: key === 'Print'
						? '#6179E5'
						: '#E5B061',
			});
		}
	}

	//sort chart series
	function insertAndShift(arr, from, to) {
		let cutOut = arr.splice(from, 1)[0];
		arr.splice(to, 0, cutOut);
	}
	const sortFn = () => {
		const seriesDataClone = JSON.parse(JSON.stringify(seriesData));
		seriesDataClone.some((el, index) => {
			if (el.name === 'Digital') {
				insertAndShift(seriesData, index, 0);
			} else if (el.name === 'TV/Radio') {
				insertAndShift(seriesData, index, 1);
			} else if (el.name === 'SOI') {
				insertAndShift(seriesData, index, 2);
			} else if (el.name === 'Unknown') {
				insertAndShift(seriesData, index, 3);
			}
		});
	};
	sortFn();

	return seriesData;
};

export const generatePieChart = (data) => {
	if (!data) return false;

	let temp = JSON.parse(JSON.stringify(MarketingPieChart));

	let seriesData = [];

	for (const key in data) {
		if (Object.hasOwnProperty.call(data, key)) {
			seriesData.push({
				name: key,
				y: data[key],
				color:
					key === 'Digital'
						? '#C361E5'
						: key === 'TV/Radio'
						? '#61E5CD'
						: key === 'Print'
						? '#6179E5'
						: '#E5B061',
			});
		}
	}

	//sort chart series
	function insertAndShift(arr, from, to) {
		let cutOut = arr.splice(from, 1)[0];
		arr.splice(to, 0, cutOut);
	}

	const sortFn = () => {
		const seriesDataClone = JSON.parse(JSON.stringify(seriesData));
		seriesDataClone.some((el, index) => {
			if (el.name === 'Digital') {
				insertAndShift(seriesData, index, 0);
			}
			if (el.name === 'TV/Radio') {
				insertAndShift(seriesData, index, 1);
			}
			if (el.name === 'SOI') {
				insertAndShift(seriesData, index, 2);
			}
			if (el.name === 'Unknown') {
				insertAndShift(seriesData, index, 3);
			}
		});
	};

	sortFn();

	temp.series = [{ data: seriesData }];

	return temp;
};

export const generateBarChart = (data) => {
	if (!data) return false;

	//let temp = JSON.parse(JSON.stringify(MarketingColoredBarChart));
	let temp = Object.assign({}, MarketingColoredBarChart);

	let seriesData = [];

	for (const key in data) {
		if (Object.hasOwnProperty.call(data, key)) {
			seriesData.push({
				name: key,
				y: typeof data[key] === 'number' ? data[key] : 0,
				color:
					key === 'Digital'
						? '#C361E5'
						: key === 'TV/Radio'
						? '#61E5CD'
						: key === 'Print'
						? '#6179E5'
						: '#E5B061',
			});
		}
	}

	//sort chart series
	function insertAndShift(arr, from, to) {
		let cutOut = arr.splice(from, 1)[0];
		arr.splice(to, 0, cutOut);
	}
	const sortFn = () => {
		const seriesDataClone = JSON.parse(JSON.stringify(seriesData));

		seriesDataClone.some((el, index) => {
			if (el.name === 'Digital') {
				insertAndShift(seriesData, index, 0);
			} else if (el.name === 'TV/Radio') {
				insertAndShift(seriesData, index, 1);
			} else if (el.name === 'SOI') {
				insertAndShift(seriesData, index, 2);
			} else if (el.name === 'Unknown') {
				insertAndShift(seriesData, index, 3);
			}
		});
	};
	sortFn();
	temp.series = [{ data: seriesData }];

	return temp;
};

export const generatePerformanceList = (currentData, oldData) => {
	const sortable = Object.entries(currentData)
		.sort(([, a], [, b]) => b.count - a.count)
		.slice(0, 10);

	const firstElementsRaw = sortable.slice(0, 3);
	const lastElementsRaw = sortable.slice(
		sortable.length - 4,
		sortable.length - 1
	);

	const parseSource = (data) => {
		const sources = data.map((element) => {
			const newVal = isNaN(parseInt(element[1].count))
				? 0
				: parseInt(element[1].count);
			let oldValue = oldData.filter(
				(oldEl) => oldEl.newSource === element[1].newSource
			);
			oldValue =
				oldValue.length > 0 && !isNaN(parseInt(oldValue[0].count))
					? parseInt(oldValue[0].count)
					: 0;

			return {
				label: element[1].newSource,
				trend: calculateTrend(newVal, oldValue).trendVal,
				percent: calculateTrend(newVal, oldValue).percentage,
				categoryIcon:
					element[1].category === 'Digital'
						? pointer
						: element[1].category === 'TV/Radio'
						? radioTv
						: externalSource,
				val: newVal,
			};
		});
		return sources;
	};

	const best = parseSource(firstElementsRaw);
	const worst = parseSource(lastElementsRaw);

	return { best, worst };
};
