import { dataValidator } from 'components/lib';

export const initState = {
	loading: true,

	ISALeaderboard: {
		headerName: ' ',
		labels: [
			'agent',
			//"total leads"
			'leads', //'current leads',
			'calls',
			'Appntmnts',
			'appt held',
			'aggrmnts',
			'escrow',
			'closed',
		],
		iconInColumns: [0],
		dataSet: [],
		customCells: [
			{ index: 0, celltype: 'clickable' },
			{ index: 0, celltype: 'with-icon' },
		],
		coumnsWithoutSorting: [],
		cellsFixedSize: [{ index: 0, size: 310 }],
	},
};

export const compileList = (data, state) => {
	if (data) {
		//console.log('data ISA', data);
		let ISALeaderboardData = [];
		data.map((agent, agentIndex) => {
			return ISALeaderboardData.push({
				icons: [
					{
						index: 0,
						type: 'avatar',
						link: agent.avatarId
							? process.env.REACT_APP_API_URI + `/image/${agent.avatarId}`
							: '',
						color: '#fff',
					},
				],
				values: [
					[
						dataValidator(
							{ firstName: agent.firstName, lastName: agent.firstName },
							'name-short'
						),
					],
					[dataValidator(agent.leadsCount)],
					[dataValidator(agent.callsCount)],
					[dataValidator(agent.appointmentCount)],
					[dataValidator(agent.showingsCount)],
					[dataValidator(agent.agreementCount)],
					[dataValidator(agent.escrowCount)],
					[dataValidator(agent.closedCount)],
				],
				id: agent.id ? agent.id : null,
			});
		});
		let ISALeaderboard = { ...state.ISALeaderboard };
		if (ISALeaderboardData.length > 0)
			ISALeaderboard.dataSet = ISALeaderboardData;
		return ISALeaderboard;
	}
	return false;
};
