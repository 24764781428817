import React from 'react';

import { TableList, ComparisonCard, ContentSection } from 'components/lib';
import {
	CallsListData,
	mockCallsData,
	CallsPlaceholdrStr,
} from 'data/mockData';

export function SalesCalls() {
	return (
		<>
			<ContentSection type='large' dropdown selectLabel='compare to'>
				<ComparisonCard
					data={mockCallsData}
					placeholderString={CallsPlaceholdrStr}
					width='100%'
				/>
			</ContentSection>
			<ContentSection disableHeader>
				<TableList data={CallsListData} />
			</ContentSection>
		</>
	);
}
