/***
 *
 *   PROFILE
 *   Update the user profile
 *
 **********/

import React, { useEffect, useState } from 'react';
import { History, useMemberInfo } from 'components/lib';

import {
	Grid,
	CircularProgress,
	Button,
	Paper,
	Typography,
	Divider,
	InputBase,
	Input,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import MaskedInput from 'react-text-mask';

function TextMaskCustom(props) {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={[
				'+',
				/[1-9]/,
				'(',
				/[1-9]/,
				/\d/,
				/\d/,
				')',
				' ',
				/\d/,
				/\d/,
				/\d/,
				'-',
				/\d/,
				/\d/,
				/\d/,
				/\d/,
			]}
			placeholderChar={'\u2000'}
			showMask
		/>
	);
}

export function ProfileInfo(props) {
	const classes = useStyles();
	const [user, setUser] = useState(false);
	const [optionals, setOptionals] = useState({
		disableInput: true,
		message: false,
		loader: false,
		error: false,
	});

	let { userData, isMembmer = false, isCompany = false } = props;

	const { updateMember } = useMemberInfo();

	async function apiCall(method, url, data) {
		let res = await Axios({
			method: method,
			url: process.env.REACT_APP_API_URI + url,
			data,
		}).catch((error) => {
			return error;
		});
		return res;
	}
	const generateInitials = (name) => {
		if (!name) return;
		let initials = name.match(/\b\w/g) || [];
		return initials;
	};

	useEffect(() => {
		if (!userData) return;

		setUser(
			isCompany
				? {
						name: userData.name,
						email: userData.email,
						phone: userData.phone,
						initials: generateInitials(userData.name),
				  }
				: {
						id: userData.id,
						firstName: userData.firstName,
						lastName: userData.lastName,
						accountType: userData.roles?.[0]?.name,
						phone: userData.phone,
						fubId: userData.detail?.fubId,
						//email: getUser.email,
				  }
		);
		setOptionals({ disableInput: false });
	}, [userData]);

	const inputChangeHandler = (event) => {
		let data = { ...user };
		const inp = event.target;
		let nam = inp.name;
		let val = inp.value;

		//PROVIDE ERROR HANDLING!
		if (val === undefined && inp.defaultValue) {
			data[nam].val = inp.defaultValue;
			setUser(data);
		}

		// if (inp.required) {
		if (!val) {
			//inp.valid = false;
			//errors.push(false);

			return setOptionals({ disableInput: true });
		}
		// }
		setOptionals({ disableInput: false });

		setUser({ ...user, [nam]: val });
	};

	const updatePage = () => {
		setOptionals({ message: false });
		if (isMembmer) {
			updateMember();
		} else if (isCompany) {
			History.push('/account/settings');
		} else {
			History.push('/profile');
		}
	};
	const submit = async (event) => {
		event.preventDefault();
		setOptionals({ loader: true, disableInput: true, error: false });

		let sendUser = user;
		if (sendUser.phone) sendUser.phone = sendUser.phone.replace(/\D+/g, '');
		if (sendUser.fubId) {
			const fubId = parseInt(sendUser.fubId);
			delete sendUser.fubId;
			sendUser.detail = { fubId };
		}

		let res = isCompany
			? await apiCall('PUT', `/api/organization`, sendUser)
			: await apiCall(isMembmer ? 'PUT' : 'PATCH', `/api/user`, sendUser);

		if (res?.data && res.status === 200) {
			if (!isMembmer && !isCompany) {
				let oldUser = { ...userData };
				let changedUser = res.data;
				let newUser = { ...oldUser, ...changedUser };
				localStorage.setItem('user', JSON.stringify(newUser));
			}

			setOptionals({
				message: `${isCompany ? 'company' : 'user'} info updated`,
				loader: false,
				disableInput: false,
			});
			setUser(
				isCompany
					? {
							name: res.data.name,
							email: res.data.email,
							phone: res.data.phone,
							initials: generateInitials(res.data.name),
					  }
					: {
							firstName: userData.firstName,
							lastName: userData.lastName,
							accountType: userData.roles?.[0]?.name,
							phone: userData.phone,
					  }
			);

			setTimeout(updatePage, 2000);
		} else {
			setOptionals({
				message: 'something went wrong',
				loader: false,
				error: true,
			});
			setTimeout(updatePage, 4000);
		}
		//History.push('/profile');
	};
	return (
		<>
			<Grid item xs={3}>
				<Paper
					style={{
						minHeight: 240,
						maxHeight: 240,
						minWidth: 200,
						width: '100%',
						borderRadius: 10,
					}}
				>
					<form noValidate autoComplete='off' onSubmit={submit}>
						<Grid
							container
							direction='column'
							justify='space-between'
							//alignItems='center'
							style={{ padding: 10, minHeight: 240 }}
						>
							<Grid item>
								<Typography
									color='textPrimary'
									variant='h5'
									style={{ marginBottom: '1em' }}
								>
									{`${isCompany ? 'Company' : 'Personal'} Info`}
								</Typography>
								<Grid item container direction='row' justify='space-between'>
									<Typography color='textSecondary' variant='h6'>
										{`${isCompany ? 'Company' : 'First'} Name`}
									</Typography>
									{!user ? (
										<CircularProgress size={20} />
									) : (
										<InputBase
											name={isCompany ? 'name' : 'firstName'}
											className={classes.input}
											defaultValue={isCompany ? user.name : user.firstName}
											inputProps={{ 'aria-label': 'naked' }}
											onChange={inputChangeHandler}
										/>
									)}
								</Grid>
								<Divider style={{ backgroundColor: '#0d1014', margin: '5px 0' }} />
								<Grid item container direction='row' justify='space-between'>
									<Typography color='textSecondary' variant='h6'>
										{isCompany ? 'Logo Initials' : `Last Name`}
									</Typography>
									{!user ? (
										<CircularProgress size={20} />
									) : !isCompany ? (
										<InputBase
											name='lastName'
											className={classes.input}
											defaultValue={user.lastName}
											inputProps={{ 'aria-label': 'naked' }}
											onChange={inputChangeHandler}
										/>
									) : (
										<Typography color='textSecondary' variant='h6'>
											{user.initials}
										</Typography>
									)}
								</Grid>
								<Divider style={{ backgroundColor: '#0d1014', margin: '5px 0' }} />

								{isMembmer && (
									<>
										<Grid item container direction='row' justify='space-between'>
											<Typography color='textSecondary' variant='h6'>
												{`Followup Boss Id`}
											</Typography>
											{!user ? (
												<CircularProgress size={20} />
											) : (
												<InputBase
													name='fubId'
													value={user.fubId && user.fubId}
													className={classes.input}
													//defaultValue={user.detail?.fubId}
													//inputProps={{ 'aria-label': 'naked' }}
													onChange={inputChangeHandler}
												/>
											)}
										</Grid>
										<Divider style={{ backgroundColor: '#0d1014', margin: '5px 0' }} />
									</>
								)}

								{!isMembmer && (
									<>
										<Grid item container direction='row' justify='space-between'>
											<Typography color='textSecondary' variant='h6'>
												{isCompany ? 'Email' : 'Account Type'}
											</Typography>
											{!user ? (
												<CircularProgress size={20} />
											) : (
												<Typography color='textSecondary' variant='h6'>
													{isCompany ? user.email : user.accountType}
												</Typography>
											)}
										</Grid>
										<Divider style={{ backgroundColor: '#0d1014', margin: '5px 0' }} />
									</>
								)}
								<Grid
									item
									container
									direction='row'
									justify='space-between'
									style={{ marginBottom: 10 }}
								>
									<Typography color='textSecondary' variant='h6'>
										Phone Number
									</Typography>
									<Input
										value={user.phone}
										onChange={inputChangeHandler}
										className={classes.inputSecond}
										name='phone'
										id='formatted-text-mask-input'
										inputComponent={TextMaskCustom}
									/>
								</Grid>
							</Grid>
							<Grid item container direction='row' justify='flex-end'>
								<Grid item xs={7}>
									{optionals.message && (
										<Typography
											color='textSecondary'
											variant='h5'
											style={{ color: optionals.error ? 'red' : 'green' }}
										>
											{optionals.message}
										</Typography>
									)}
								</Grid>
								<Grid item xs={5} style={{ position: 'relative' }}>
									<Button
										variant='contained'
										color='primary'
										component='label'
										disabled={optionals.disableInput}
									>
										update info
										<input type='submit' style={{ display: 'none' }} />
									</Button>
									{optionals.loader && (
										<CircularProgress
											size={20}
											style={{
												position: 'absolute',
												top: '50%',
												left: '50%',
												marginTop: -12,
												marginLeft: -12,
											}}
										/>
									)}
								</Grid>
							</Grid>
						</Grid>
					</form>
				</Paper>
			</Grid>
		</>
	);
}

const useStyles = makeStyles(() => ({
	input: {
		'& > *': {
			textAlign: 'end',
			padding: '3px 0 0px',
			'&:-webkit-autofill,:-webkit-autofill:hover,:-webkit-autofill:focus,:-webkit-autofill:active': {
				'-webkit-box-shadow': `0 0 0 50px #283038 inset`,
				'-webkit-text-fill-color': '#fff',
			},
		},
	},
	inputSecond: {
		'& > *': {
			width: 100,
		},
		'&::before': {
			borderBottom: 0,
		},
	},
}));
