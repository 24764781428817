import React from 'react';

import { Grid, Typography, Avatar, Divider } from '@material-ui/core';

import { Loader, Wrapper, ChartBare } from 'components/lib';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import pointer from 'icons/pointer.svg';
import radioTv from 'icons/radioTv.svg';
import externalSource from 'icons/externalSource.svg';

export const ListRow = (props) => {
	const { isHeader = false, data = [] } = props;
	const color = isHeader ? 'textSecondary' : 'textPrimary';
	const variant = isHeader ? 'h6' : 'subtitle1';
	return (
		<Grid
			item
			container
			direction='row'
			justify='space-between'
			alignItems='center'
		>
			<Grid
				item
				xs={4}
				container
				direction='row'
				justify='flex-start'
				alignItems='center'
			>
				<>
					{isHeader || (
						<img
							style={{
								height: 24,
								width: 24,
								marginRight: 5,
							}}
							src={data.picture}
						/>
					)}
					<Typography color={color} variant={variant}>
						{isHeader ? 'SOURCE' : data.label}
					</Typography>
				</>
			</Grid>
			<Grid
				item
				xs={1}
				container
				direction='row'
				justify='flex-start'
				alignItems='center'
			>
				<Typography color={color} variant={variant}>
					{isHeader ? '0-3' : data.num1}
				</Typography>
			</Grid>
			<Grid
				item
				xs={1}
				container
				direction='row'
				justify='flex-start'
				alignItems='center'
			>
				<Typography color={color} variant={variant}>
					{isHeader ? '3-6' : data.num2}
				</Typography>
			</Grid>
			<Grid
				item
				xs={1}
				container
				direction='row'
				justify='flex-start'
				alignItems='center'
			>
				<Typography color={color} variant={variant}>
					{isHeader ? '6-12' : data.num3}
				</Typography>
			</Grid>
			<Grid
				item
				xs={1}
				container
				direction='row'
				justify='flex-start'
				alignItems='center'
			>
				<Typography color={color} variant={variant}>
					{isHeader ? '12+' : data.num4}
				</Typography>
			</Grid>
			{isHeader && (
				<Grid item xs={12}>
					<Divider
						style={{ backgroundColor: '#171C22', height: 1, margin: '6px 0px' }}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export const PerformanceListRow = (props) => {
	const { isHeader = false, data = {} } = props;
	const color = isHeader ? 'textSecondary' : 'textPrimary';
	const variant = isHeader ? 'h6' : 'h6';
	/*
	{ label: 'label', picture: false, index: false, arrow: 1 } 
	
	label
trend
percent
categoryIcon
val
	
	
	*/
	return (
		<Grid
			item
			container
			direction='column'
			justify='flex-start'
			alignItems='flex-start'
		>
			{isHeader ? (
				<Grid
					item
					container
					direction='row'
					justify='flex-start'
					alignItems='center'
					style={{ marginBottom: 5 }}
				>
					<Typography color={color} variant={variant}>
						{data.label}
					</Typography>
				</Grid>
			) : (
				<>
					<Grid
						item
						container
						direction='row'
						justify='flex-start'
						alignItems='center'
					>
						<Grid
							item
							container
							direction='row'
							justify='flex-start'
							alignItems='center'
							xs={3}
						>
							<Grid item>
								<ArrowDownwardIcon
									style={
										data.trend === 0
											? { opacity: 0 }
											: data.trend === 1
											? { transform: 'rotate(0.5turn)', color: '#14909B' }
											: { color: '#E56161' }
									}
								/>
							</Grid>
							<Grid
								item
								// container
								// direction='column'
								//justify='center'
								//alignItems='center'
								//xs={1}
								style={{ marginLeft: 1, maxWidth: 33, minWidth: 33 }}
							>
								<Typography
									color='textSecondary'
									style={{ fontSize: '0.6em' }}
									align='center'
								>
									{`${data.percent}%`}
								</Typography>
								<Typography
									color='textPrimary'
									style={{ fontSize: '0.6em' }}
									align='center'
								>
									{data.val}
								</Typography>
							</Grid>
						</Grid>

						{isHeader || (
							<Grid item xs={2}>
								<Avatar
									style={{
										height: 24,
										width: 24,
										marginLeft: 3,
										//margin: '0px 5px',
									}}
									src={data.categoryIcon}
								></Avatar>
							</Grid>
						)}
						<Grid item xs={6}>
							<Typography color={color} variant={variant}>
								{data.label}
							</Typography>
						</Grid>
					</Grid>
				</>
			)}
		</Grid>
	);
};

export const BarChartSection = (props) => {
	const { chart = false, loading = true } = props;

	return (
		<Wrapper customStyle={{ width: '100%', boxShadow: 'none' }}>
			{loading ? (
				<Grid
					item
					container
					justify='center'
					alignItems='center'
					style={{ height: '100%', minHeight: 200 }}
				>
					<Loader />
				</Grid>
			) : (
				<ChartBare
					chartData={chart}
					customStyle={{ width: '100%', minHeight: 200 }}
				/>
			)}
		</Wrapper>
	);
};

export const ChartLabelIcons = (props) => {
	return (
		<>
			<Grid item>
				<img
					alt=''
					src={pointer}
					style={{ verticalAlign: 'middle', width: 30, height: 30 }}
				/>
			</Grid>
			<Grid item>
				<img
					alt=''
					src={radioTv}
					style={{ verticalAlign: 'middle', width: 30, height: 30 }}
				/>
			</Grid>
			<Grid item>
				<img
					alt=''
					src={externalSource}
					style={{ verticalAlign: 'middle', width: 30, height: 30 }}
				/>
			</Grid>
			<Grid item>
				<img
					alt=''
					src={externalSource}
					style={{ verticalAlign: 'middle', width: 30, height: 30 }}
				/>
			</Grid>
		</>
	);
};

export const PieChartLegendRow = (props) => {
	const { el } = props;
	return (
		<Grid
			item
			container
			direction='row'
			justify='flex-start'
			alignItems='center'
			spacing={2}
		>
			<Grid
				item
				container
				direction='row'
				justify='flex-start'
				alignItems='center'
				xs={8}
			>
				<img
					alt=''
					src={el.icon}
					style={{
						verticalAlign: 'middle',
						width: 18,
						height: 18,
						marginRight: 4,
					}}
				/>
				<Typography variant='h5' align='center'>
					{el.name}
				</Typography>
			</Grid>
			<Grid item xs={4}>
				<Typography variant='h6' align='center' color='textSecondary'>
					{el.value}
				</Typography>
			</Grid>
		</Grid>
	);
};
