import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';

//import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import MenuBookIcon from '@material-ui/icons/MenuBook';
// import VoiceChatOutlinedIcon from '@material-ui/icons/VoiceChatOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

export const appMenuItems = [
	{
		name: 'Account settings',
		Icon: SettingsOutlinedIcon,
		link: '/profile',
	},
];

/*
roles priority
Team Leader
OSA Manager
ISA Manager
OSA
ISA
Assistant
*/
