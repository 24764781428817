import React, { useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { makeStyles, withStyles } from '@material-ui/core/styles';

const StyledToggleButtonGroup = withStyles((theme) => ({
	grouped: {
		margin: theme.spacing(0.5),
		border: 'none',
		'&:not(:first-child)': {
			borderRadius: theme.shape.borderRadius,
		},
		'&:first-child': {
			borderRadius: theme.shape.borderRadius,
		},
	},
}))(ToggleButtonGroup);

export const SwitchBtnsPlchld = () => {
	return <></>; //TODO
};

export const SwitchBtns = (props) => {
	const {
		clb = () => {},
		indx = false,
		disabled = false,
		defaultOnDisable = false,
	} = props;
	const defVal = '90';
	const [state, setState] = React.useState(defVal);

	const handleChange = (event, newState) => {
		if (newState !== null) setState(newState);
	};

	useEffect(() => {
		if (disabled && defaultOnDisable) {
			return setState(defVal);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [disabled]);

	useEffect(() => {
		clb(indx, state);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	const classes = useStyles();

	return (
		<Grid
			item
			container
			direction='row'
			justify='center'
			alignItems='center'
			style={{ width: 200, height: 38 }}
		>
			<Paper elevation={0} className={classes.paper}>
				<StyledToggleButtonGroup
					size='small'
					value={state}
					exclusive
					onChange={handleChange}
				>
					<ToggleButton
						value='30'
						classes={{
							root: classes.containedBtn,
							selected: classes.selectedBtn,
							label: classes.BtnLabel,
						}}
						disabled={disabled}
					>
						Last 30 days
					</ToggleButton>
					<ToggleButton
						value='90'
						classes={{
							root: classes.containedBtn,
							selected: classes.selectedBtn,
							label: classes.BtnLabel,
						}}
						disabled={disabled}
					>
						90 days
					</ToggleButton>
					<ToggleButton
						value='365'
						classes={{
							root: classes.containedBtn,
							selected: classes.selectedBtn,
							label: classes.BtnLabel,
						}}
						disabled={disabled}
					>
						This Year
					</ToggleButton>
				</StyledToggleButtonGroup>
			</Paper>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	root: { color: 'green', margin: 0.5 },
	fontSizeSmall: { fontSize: '6px' },
	colorPrimary: { color: '#14909B' },
	colorSecondary: { color: '#E56161' },
	colorAction: { color: '#f3ae13' },
	colorDisabled: { color: '#171C22' },
	colorDisabledAlt: { color: '#283038' },

	cardRoot: {
		backgroundColor: 'transparent !important',
		padding: 10,
		minWidth: 180,
		minHeight: 100,
	},
	cardPaper: {
		backgroundColor: '#171c22b3',
		backdropFilter: 'blur(6px)',
	},

	containerSepaphore: {
		backgroundColor: '#171C22',
		borderRadius: 40,
		//padding: '0px 3px',
		minWidth: 80,
		minHeight: 24,
	},

	paper: {
		display: 'flex',
		border: `1px solid ${theme.palette.divider}`,
		flexWrap: 'wrap',
		backgroundColor: 'transparent',
		height: 28,
		alignItems: 'center',
	},
	divider: {
		margin: theme.spacing(1, 0.5),
	},
	containedBtn: {
		padding: '4px 5px',
		whiteSpace: 'nowrap',
		fontSize: '0.9285714285714285rem',
		height: 18,
	},
	selectedBtn: {
		padding: '4px 5px',
		fontSize: '0.9285714285714285rem',
		whiteSpace: 'nowrap',
		height: 18,
		color: '#283038 !important',
		backgroundColor: '#fff !important',
	},
	BtnLabel: {
		height: 10,
	},
	selectorBtn: {
		padding: 0,
	},
}));
