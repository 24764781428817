import React from 'react';
import {
	Grid,
	Divider,
	Typography,
	Chip,
	Paper,
	Button,
	Link,
	Avatar,
	CircularProgress,
} from '@material-ui/core';
import { ContentSection, StatsCard } from 'components/lib';
import { BarChart } from 'components/charts/barChartNew';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import Avat from 'views/website/images/avatRev.png';

export const OSAGreetings = (props) => {
	const classes = useStyles();
	const { state, user, selectedTimeframe } = props;

	return (
		<>
			{state.loading ? (
				<Grid item container justify='center' alignItems='center'>
					<CircularProgress />
				</Grid>
			) : state.closedCount !== undefined ? (
				<Grid item container direction='row' spacing={1}>
					<Grid item>
						<Typography variant='h3'>{`Hey ${user.firstName}${
							state.closedCount > 0
								? " - you've changed"
								: `, here is your summary for  ${
										selectedTimeframe !== undefined
											? selectedTimeframe === '365'
												? ' this year!'
												: `the last ${selectedTimeframe} days!`
											: 'the selected period.'
								  } `
						}`}</Typography>
					</Grid>
					{state.closedCount > 0 ? (
						<>
							<Grid item>
								<Chip
									label={state.closedCount}
									//onClick={() => alert('clicket on chip')}
									color='primary'
									className={classes.chip}
								/>
							</Grid>
							<Grid item>
								<Typography variant='h3'>{`lives in 
								${
									selectedTimeframe !== undefined
										? selectedTimeframe === '365'
											? ' this year!'
											: `the last ${selectedTimeframe} days!`
										: 'the selected period.'
								} `}</Typography>
							</Grid>
						</>
					) : (
						<></>
					)}
				</Grid>
			) : (
				<></>
			)}
		</>
	);
};

export const OSANotifLinks = () => {
	return (
		<Grid
			item
			container
			direction='row'
			spacing={1}
			style={{ margin: '10px 10px' }}
		>
			<Grid item>
				<Typography variant='h6' color='textSecondary'>
					You have
				</Typography>
			</Grid>
			<Grid item>
				<Link
					component='h6'
					variant='h6'
					color='textPrimary'
					gutterBottom={true}
					//onClick={() => scrollFn(listRef)}
					style={{ cursor: 'pointer' }}
					underline='always'
				>
					8 open dispositions
				</Link>
			</Grid>

			<Grid item>
				<Typography variant='h6' color='textSecondary'>
					to complete,
				</Typography>
			</Grid>
			<Grid item>
				<Link
					component='h6'
					variant='h6'
					color='textPrimary'
					gutterBottom={true}
					//onClick={() => scrollFn(listRef)}
					style={{ cursor: 'pointer' }}
					underline='always'
				>
					2 coaching sessions,
				</Link>
			</Grid>
			<Grid item>
				<Typography variant='h6' color='textSecondary'>
					and
				</Typography>
			</Grid>
			<Grid item>
				<Link
					component='h6'
					variant='h6'
					color='textPrimary'
					gutterBottom={true}
					//onClick={() => scrollFn(listRef)}
					style={{ cursor: 'pointer' }}
					underline='always'
				>
					3 overdue form
				</Link>
			</Grid>
			<Grid item>
				<Typography variant='h6' color='textSecondary'>
					to follow up on.
				</Typography>
			</Grid>
		</Grid>
	);
};

export const OSANotifMessage = (props) => {
	const classes = useStyles();
	const { state, scrollFn, listRef } = props;
	return (
		<Grid
			item
			style={{
				width: '100%',
				marginTop: 5,
				//filter: 'blur(.1rem)',
			}}
		>
			<Paper
				style={{
					padding: 10,
					width: '100%',
				}}
				className={state.missingDispositions > 9 ? classes.alertPaper : null}
			>
				<Grid
					item
					container
					direction='row'
					justify='space-between'
					alignItems='center'
				>
					<Grid item style={{ display: 'flex', alignItems: 'center' }}>
						{state.missingDispositions === 0 ? (
							<EmojiEmotionsOutlinedIcon
								style={{
									fontSize: '1.6em',
									paddingRight: 2,
									color: 'green',
								}}
							/>
						) : state.missingDispositions < 4 && state.missingDispositions > 0 ? (
							<ErrorIcon
								style={{ fontSize: '1.6em', paddingRight: 2, color: '#14909B' }}
							/>
						) : state.missingDispositions < 7 && state.missingDispositions > 0 ? (
							<ErrorIcon
								style={{ fontSize: '1.6em', paddingRight: 2, color: '#E5B061' }}
							/>
						) : state.missingDispositions < 10 && state.missingDispositions > 0 ? (
							<ErrorIcon style={{ fontSize: '1.6em', paddingRight: 2 }} />
						) : (
							<ErrorIcon
								color='secondary'
								style={{ fontSize: '1.6em', paddingRight: 2, opacity: 0 }}
							/>
						)}
						<Typography variant='h5'>
							{state.missingDispositions === 0
								? 'Hooray! You have no open dispositions, congrats!'
								: state.missingDispositions > 9
								? 'You have over 9 open dispositions. You will not receive any more leads until this number is below 9.'
								: state.missingDispositions > 0
								? 'After 9 open dispositions, you will stop receiving leads. Complete your open dispositions now to avoid this.'
								: ''}
						</Typography>
					</Grid>
					{state.missingDispositions > 0 && (
						<Grid item>
							<Button
								variant='contained'
								color='primary'
								onClick={() => scrollFn(listRef)}
								className={state.missingDispositions > 9 ? classes.alertBtn : null}
							>
								View Dispositions
							</Button>
						</Grid>
					)}
				</Grid>
			</Paper>
		</Grid>
	);
};

export const OSAStats = (props) => {
	const { state } = props;
	return (
		<Grid container spacing={1} direction='row' justify='space-between'>
			<Grid
				item
				style={{
					backgroundColor: '#283038',
					paddingTop: 15,
					paddingBottom: 15,
					borderRadius: 4,
					minHeight: 150,
					minWidth: 390,
				}}
			>
				<Typography variant='h5' style={{ marginLeft: 15 }}>
					Apptmnts : Sign Ratios
				</Typography>
				{state.loading ? (
					<Grid
						item
						container
						justify='center'
						alignItems='center'
						style={{ height: '100%' }}
					>
						<CircularProgress />
					</Grid>
				) : (
					<StatsCard Data={state.apptSignRatioCards} noElevation alt />
				)}
			</Grid>

			<Grid
				item
				style={{
					backgroundColor: '#283038',
					paddingTop: 15,
					paddingBottom: 15,
					borderRadius: 4,
					minHeight: 150,
					minWidth: 390,
				}}
			>
				<Typography variant='h5' style={{ marginLeft: 15 }}>
					Est. Commision
				</Typography>
				{state.loading ? (
					<Grid
						item
						container
						justify='center'
						alignItems='center'
						style={{ height: '100%' }}
					>
						<CircularProgress />
					</Grid>
				) : (
					<StatsCard Data={state.commisionCards} noElevation alt />
				)}
			</Grid>
			<Grid
				item
				//xs={4}
				style={{
					backgroundColor: '#283038',
					padding: 15,
					borderRadius: 4,
					minHeight: 150,
					maxWidth: 300,
					minWidth: 300,
				}}
				container
				direction='column'
				justify='center'
				alignItems='center'
			>
				<Avatar src={Avat}></Avatar>
				<Typography variant='h5' align='center'>
					“ I felt so safe and supported in my hunt for my new home. I’m so happy
					with what I found!”
				</Typography>
				<Typography variant='h5' color='textSecondary' align='center'>
					Kristin Watson
				</Typography>
			</Grid>
		</Grid>
	);
};

export const BarChartSection = (props) => {
	const { state } = props;
	const { chartData = false, OSABarChartFooter = false } = state;
	return (
		<ContentSection type='barChart'>
			{state.loading ? (
				<Grid
					item
					container
					justify='center'
					alignItems='center'
					style={{ height: '100%', minHeight: 400 }}
				>
					<CircularProgress />
				</Grid>
			) : (
				<BarChart chartData={chartData} />
			)}
			{OSABarChartFooter && (
				<>
					<Divider
						variant='fullWidth'
						style={{
							width: '100%',
							backgroundColor: '#171C22',
							marginBottom: '0.1em',
							height: 2,
						}}
					/>
					<Grid
						container
						direction='row'
						alignItems='center'
						justify='space-between'
						style={{ padding: '0 60px' }}
					>
						{OSABarChartFooter.map((i, index) => {
							return (
								<Grid item key={index} style={{ width: 120 }}>
									<Typography
										variant='h4'
										gutterBottom
										align='center'
										style={{ width: '100%' }}
									>
										{i.value}
									</Typography>

									<Typography
										color='textSecondary'
										variant='h5'
										align='center'
										style={{ width: '100%' }}
									>
										{i.label}
									</Typography>
								</Grid>
							);
						})}
					</Grid>
				</>
			)}
			<Divider style={{ marginTop: 30 }} />
		</ContentSection>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		fontSize: '1.6em',
		'& > *': {
			//margin: theme.spacing(0.5),
		},
	},
	alertPaper: {
		color: '#fff',
		backgroundColor: '#E56161',
	},
	alertBtn: {
		color: '#E56161',
		backgroundColor: '#fff',
	},
	chip: {
		fontSize: '1.6em',
		//cursor:"default"
	},
}));
