import React, { useEffect, useState } from 'react';
import { CircularProgress, Divider, Grid } from '@material-ui/core';

import {
	ContentSection,
	TableList,
	useCheckAuth,
	PageWrapper,
	useAgentInfo,
	SwitchBtns,
	SwitchBtnsPlchld,
	useAPI,
} from 'components/lib';

import { initState, compileList } from './OSAHelpers';
import { BarChartSection } from '../../agent/OSA/OSAComponents';
import { generateChart, generateChartFooter } from '../../agent/OSA/OSAHelpers';
import { useHistory } from 'react-router-dom';
import { OSA } from '../Overview/MngComponents';
import { compileOSA } from '../Overview/MngHelpers';

const MngInitState = {
	loading: false,
	OSA: null,
	timeFrame: false,
};

export function OSAs(props) {
	//hooks
	useCheckAuth();
	useAPI('/auth/check');
	const metrics = useAPI('/api/metrics/leaderboard/OSA'); //should recieve metrics data from the back end
	const managerMetrics = useAPI('/api/metrics/management/OSA'); // request mngr specific kpi data
	const [state, setState] = useState(initState); //component store with time specific data
	const [stateData, setStateData] = useState(false); // store with data for all time filters
	const { updateAgent } = useAgentInfo(); //importing fn from custom context to update selected agent data in the external store
	const history = useHistory(); // router hook will be used to redirect user on another page
	const [mngState, setMngState] = useState(MngInitState); //manager kpi parsed data and selected via timeframe
	const [mngStateDates, setMngStateDates] = useState(MngInitState); // all mngr kpi datas for all timeframes
	//clickable button callback from the list
	const leadsClb = (type, row) => {
		updateAgent({ agentId: row.id, agentName: row.agent[1], agentType: 'osa' });
		//when agent is set, start redirecting user to the agent page
		history.push('/osas/agent');
	};

	//hook firing up on metrics var change
	useEffect(() => {
		//if we have any data in the api response
		if (metrics.data) {
			try {
				const data = metrics.data;

				//console.log('data', data);

				const OSALeaderboard = {
					30: compileList(data['30']),
					90: compileList(data['90']),
					365: compileList(data['365']),
				};

				setStateData({
					...stateData,
					OSALeaderboard,
					loading: false,
				});
			} catch (e) {
				console.log('problem with OSAs data: ', e.message);
				setState({
					...state,
					loading: false,
				});
			}
		}
		//if we dont have any data from api call, then set loader to the api call response
		if (!metrics.data) setState({ ...state, loading: metrics.loading });
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [metrics]);

	useEffect(() => {
		if (managerMetrics.loading) setMngStateDates({ ...mngState, loading: true });
		if (managerMetrics && managerMetrics.data) {
			const data = managerMetrics.data;
			console.log('mngr', data);
			const currentData30 = data['30']?.now;
			const oldData30 = data['30']?.old;
			const currentData90 = data['90']?.now;
			const oldData90 = data['90']?.old;
			const currentData365 = data['365']?.now;
			const oldData365 = data['365']?.old;
			//compile timeframe data for the mngr kpi's
			const OSA = {
				30:
					currentData30 && oldData30 ? compileOSA(currentData30, oldData30) : false,
				90:
					currentData90 && oldData90 ? compileOSA(currentData90, oldData90) : false,
				365:
					currentData365 && oldData365
						? compileOSA(currentData365, oldData365)
						: false,
			};

			const OSABarChartFooter = {
				30: generateChartFooter(currentData30),
				90: generateChartFooter(currentData90),
				365: generateChartFooter(currentData365),
			};
			const chartData = {
				30: generateChart(currentData30),
				90: generateChart(currentData90),
				365: generateChart(currentData365),
			};

			setMngStateDates({
				...mngState,
				OSA,
				OSABarChartFooter,
				chartData,
				loading: false,
			});
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [managerMetrics]);

	//time filters buttons callback
	const handleTimeSelector = async (index, val) => {
		let indx = parseInt(index);
		// set actual data for the timeframe selected to the main state
		switch (indx) {
			case 0: //bar chart and chart footer
				await setMngState((previousState) => {
					//console.log('check!', stateData.chartData[val]);
					return {
						...previousState,
						chartData: mngStateDates.chartData[val],
						OSABarChartFooter: mngStateDates.OSABarChartFooter[val],
					};
				});
				break;
			case 1: //manager kpi's
				await setMngState((previousState) => {
					return { ...previousState, OSA: mngStateDates.OSA[val], timeFrame: val };
				});
				break;
			case 2: //OSA's lists
				await setState((previousState) => {
					return {
						...previousState,
						OSALeaderboard: stateData.OSALeaderboard[val],
						loading: false,
					};
				});
				break;
			default:
				break;
		}
	};

	return (
		<PageWrapper>
			<Divider style={{ marginBottom: '0.5em', height: 0 }} />
			<ContentSection
				title='Sales Funnel'
				disableButton
				headerEl={
					mngStateDates.chartData ? (
						<SwitchBtns clb={handleTimeSelector} indx='0' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				<BarChartSection state={mngState} />
			</ContentSection>
			<ContentSection
				title='Agents Performance'
				disableButton
				headerEl={
					mngStateDates.OSA ? (
						<SwitchBtns clb={handleTimeSelector} indx='1' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				<ContentSection type='large'>
					<Grid
						item
						container
						direction='row'
						justify='space-around'
						alignItems='center'
						style={{ height: 180 }}
					>
						<OSA
							data={mngState.OSA}
							loading={managerMetrics.loading}
							timeFrame={mngState.timeFrame}
						/>
					</Grid>
				</ContentSection>
			</ContentSection>
			<ContentSection
				//disableHeader
				title=' '
				disableButton
				headerEl={
					stateData.OSALeaderboard ? (
						<SwitchBtns clb={handleTimeSelector} indx='2' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				{state.loading ? (
					<CircularProgress />
				) : (
					<TableList
						data={state.OSALeaderboard}
						initialOrderBy='closed'
						initialOrder='desc'
						callBack={leadsClb}
					/>
				)}
			</ContentSection>
		</PageWrapper>
	);
}
