import { dataValidator } from 'components/lib';

export const initState = {
	loading: false,

	OSALeaderboard: {
		headerName: ' ',
		labels: [
			'agent',
			'appointments',
			'appt held',
			'aggrmnts',
			'escrow',
			'closed',
		],
		iconInColumns: [0],
		dataSet: [],
		customCells: [
			{ index: 0, celltype: 'clickable' },
			{ index: 0, celltype: 'with-icon' },
		],
		coumnsWithoutSorting: [],
		cellsFixedSize: [{ index: 0, size: 310 }],
	},
};

export const compileList = (data) => {
	if (!data) return false;

	//
	const listData = data.map((agent, agentIndex) => {
		//return OSALeaderboardData.push(
		return {
			icons: [
				{
					index: 0,
					type: 'avatar',
					link: agent.avatarId
						? process.env.REACT_APP_API_URI + `/image/${agent.avatarId}`
						: '',
					color: '#fff',
				},
			],
			values: [
				[
					dataValidator(
						{ firstName: agent.firstName, lastName: agent.firstName },
						'name-short'
					),
				],
				[dataValidator(agent.appointmentCount)],
				[dataValidator(agent.showingsCount)],

				[dataValidator(agent.agreementCount)],

				[dataValidator(agent.escrowCount)],
				[dataValidator(agent.closedCount)],
			],
			id: agent.id ? agent.id : null,
		};
		//);
	});
	//shallow copy template
	const template = { ...initState.OSALeaderboard };
	template.dataSet = listData;
	return template;
};
