import React, { useContext, useEffect, useState } from 'react';

import { appMenuItems } from './navOptions';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';

const NavContext = React.createContext({
	menuItems: appMenuItems,
	updateTree: () => {},
	getRoutes: () => {},
});

export const useNavItems = () => {
	return useContext(NavContext);
};

export const NavProvider = (props) => {
	const [menuItems, setMenuItems] = useState();
	const [activeRoute, setActiveRoute] = useState();
	//const [user, setUser] = useState(false);

	//let newUser = false;
	// useEffect(() => {
	// 	newUser = { ...JSON.parse(localStorage.getItem('user')) };
	// 	setUser(newUser);
	// }, [newUser]);
	const updateTree = (parentIndex, childIndex, activeRout) => {
		let newMenuItems = [...menuItems];
		if (activeRout) {
			setActiveRoute([parentIndex, childIndex]);
			if (activeRoute) {
				newMenuItems[activeRoute[0]].isActive = false;

				if (activeRoute[1] !== false)
					newMenuItems[activeRoute[0]].items[activeRoute[1]].isActive = false;
			}

			newMenuItems[parentIndex].isActive = true;

			if (childIndex !== false) {
				newMenuItems[parentIndex].items[childIndex].isActive = true;
				newMenuItems[parentIndex].isOpen = true;
			}

			return setMenuItems(newMenuItems);
		}

		const bool = newMenuItems[parentIndex].isOpen;
		newMenuItems[parentIndex].isOpen = !bool;

		return setMenuItems(newMenuItems);
	};

	//const removeMenuCategory = (category) => {};
	const expandMenuItems = (appMenuItemsExpanded) => {
		//map through menu items and add required options
		appMenuItemsExpanded.map((item, index) => {
			//is this rout active
			appMenuItemsExpanded[index].isActive = false;
			//if nav item has child items then set as expandable
			item.items &&
				(appMenuItemsExpanded[index].isExpandable =
					item.items && item.items.length > 0);
			//if this item is expandable, then it can be opened
			appMenuItemsExpanded[index].isExpandable === true &&
				(appMenuItemsExpanded[index].isOpen = false);

			//map through child items if any
			item.items &&
				item.items.map((childItem, childIndex) => {
					appMenuItemsExpanded[index].items[childIndex].isActive = false;
					return childItem;
				});
			return item;
		});

		return appMenuItemsExpanded;
	};

	useEffect(() => {
		//when provider is mounted
		//expand nav menu options that imported from navOptions

		const user = { ...JSON.parse(localStorage.getItem('user')) };

		let usrRoles = user.roles;

		let MainDasboardMenu = {
			name: 'Dashboard',
			Icon: DashboardOutlinedIcon,
			items: [],
		};
		let ISAManagmentMenu = {
			name: 'ISA Management',
			Icon: DashboardOutlinedIcon,
			items: [],
		};
		let OSAManagmentMenu = {
			name: 'Sales Management',
			Icon: DashboardOutlinedIcon,
			items: [],
		};
		let LeadsMarketingMenu = {
			name: 'Leads & Marketing',
			Icon: DashboardOutlinedIcon,
			items: [],
		};

		let usrManagment = {
			name: 'Members',
			Icon: GroupOutlinedIcon,
			link: '/users_managment/members',
			items: [],
		};

		let FormsMenu = {
			name: '',
			Icon: DashboardOutlinedIcon,
			items: [],
		}; //not used in this version

		let mainDashOverview = {
			name: 'Overview',
			link: '/overview',
		};
		let mainDashTransactions = {
			name: 'Transactions',
			link: '/trasactions',
		};

		let isaMenu = { name: 'ISA', link: '/isa' };
		let osaMenu = { name: 'Agent', link: '/osa' };

		let ISAManagmentOverview = {
			name: 'Overview',
			link: '/manager/overview',
		};
		let ISAManagmentISAs = {
			name: 'ISAs',
			link: '/isas',
		};
		let ISAManagmentLeads = {
			name: 'Leads',
			link: '/manager/leads',
		};
		let marketingOverview = {
			name: 'Overview',
			link: '/marketing/overview',
		};
		let marketingLeads = {
			name: 'Lead sources',
			link: '/marketing/leads',
		};

		let osasMenu = { name: 'Agents', link: '/osas' };

		const addMenuAndItems = (menuToModify, parentMenuToAdd, childItemsToAdd) => {
			let menu = [...menuToModify];
			let duplicate = false;
			menuToModify.forEach((item, index) => {
				if (item.name.toLowerCase() === parentMenuToAdd.name.toLowerCase()) {
					duplicate = true;
					let childItems = childItemsToAdd.filter((childItem, childIndex) => {
						return !menuToModify[index].items.some(
							(el) => el.name.toLowerCase() !== childItem.name.toLowerCase()
						);
					});
					menu[index].items.push(...childItems);
				}
			});
			if (!duplicate) {
				let addThis = { ...parentMenuToAdd };
				addThis.items = [...childItemsToAdd];
				menu.push(addThis);
			}
			return menu;
		};

		let generatedMenuItems = [];

		if (!usrRoles) return;

		if (usrRoles.some((el) => el.name.toLowerCase() === 'team leader')) {
			let menu = addMenuAndItems(generatedMenuItems, MainDasboardMenu, [
				mainDashOverview,
				mainDashTransactions,
			]);
			generatedMenuItems = menu;
			menu = addMenuAndItems(generatedMenuItems, OSAManagmentMenu, [osasMenu]);
			generatedMenuItems = menu;
			menu = addMenuAndItems(generatedMenuItems, ISAManagmentMenu, [
				ISAManagmentOverview,
				ISAManagmentISAs,
				ISAManagmentLeads,
			]);
			generatedMenuItems = menu;
			menu = addMenuAndItems(generatedMenuItems, LeadsMarketingMenu, [
				marketingOverview,
				marketingLeads,
			]);
			menu = addMenuAndItems(generatedMenuItems, usrManagment, []);

			generatedMenuItems = menu;
		}

		if (usrRoles.some((el) => el.name.toLowerCase() === 'isa manager')) {
			let menu = addMenuAndItems(generatedMenuItems, ISAManagmentMenu, [
				ISAManagmentOverview,
				ISAManagmentISAs,
				ISAManagmentLeads,
			]);
			generatedMenuItems = menu;
		}

		if (usrRoles.some((el) => el.name.toLowerCase() === 'sales manager')) {
			let menu = addMenuAndItems(generatedMenuItems, OSAManagmentMenu, [osasMenu]);
			generatedMenuItems = menu;
		}

		if (usrRoles.some((el) => el.name.toLowerCase() === 'marketing manager')) {
			let menu = addMenuAndItems(generatedMenuItems, ISAManagmentMenu, [
				ISAManagmentLeads,
			]);
			generatedMenuItems = menu;
			menu = addMenuAndItems(generatedMenuItems, LeadsMarketingMenu, [
				marketingOverview,
				marketingLeads,
			]);
			generatedMenuItems = menu;
		}

		if (usrRoles.some((el) => el.name.toLowerCase() === 'ops director')) {
			let menu = addMenuAndItems(generatedMenuItems, MainDasboardMenu, [
				mainDashOverview,
				mainDashTransactions,
			]);
			generatedMenuItems = menu;
			menu = addMenuAndItems(generatedMenuItems, OSAManagmentMenu, [osasMenu]);
			generatedMenuItems = menu;
			menu = addMenuAndItems(generatedMenuItems, ISAManagmentMenu, [
				ISAManagmentOverview,
				ISAManagmentISAs,
				ISAManagmentLeads,
			]);
			generatedMenuItems = menu;
			menu = addMenuAndItems(generatedMenuItems, LeadsMarketingMenu, [
				marketingOverview,
				marketingLeads,
			]);
			generatedMenuItems = menu;
		}

		if (usrRoles.some((el) => el.name.toLowerCase() === 'agent')) {
			let menu = addMenuAndItems(generatedMenuItems, MainDasboardMenu, [osaMenu]);
			generatedMenuItems = menu;
		}

		if (usrRoles.some((el) => el.name.toLowerCase() === 'isa')) {
			let menu = addMenuAndItems(generatedMenuItems, MainDasboardMenu, [isaMenu]);
			generatedMenuItems = menu;
		}

		if (usrRoles.some((el) => el.name.toLowerCase() === 'client care m')) {
			let menu = addMenuAndItems(generatedMenuItems, MainDasboardMenu, [
				mainDashOverview,
				mainDashTransactions,
			]);
			generatedMenuItems = menu;
			menu = addMenuAndItems(generatedMenuItems, OSAManagmentMenu, [osasMenu]);
			generatedMenuItems = menu;
			menu = addMenuAndItems(generatedMenuItems, ISAManagmentMenu, [
				ISAManagmentOverview,
				ISAManagmentISAs,
				ISAManagmentLeads,
			]);
			generatedMenuItems = menu;
			menu = addMenuAndItems(generatedMenuItems, LeadsMarketingMenu, [
				marketingOverview,
				marketingLeads,
			]);
			generatedMenuItems = menu;
		}

		if (
			usrRoles.some((el) => el.name.toLowerCase() === 'chief mindset officer')
		) {
			let menu = addMenuAndItems(generatedMenuItems, OSAManagmentMenu, [osasMenu]);
			generatedMenuItems = menu;
			menu = addMenuAndItems(generatedMenuItems, ISAManagmentMenu, [
				ISAManagmentOverview,
				ISAManagmentISAs,
				ISAManagmentLeads,
			]);
			generatedMenuItems = menu;
			menu = addMenuAndItems(generatedMenuItems, LeadsMarketingMenu, [
				marketingOverview,
				marketingLeads,
			]);
			generatedMenuItems = menu;
		}
		appMenuItems.unshift(...generatedMenuItems);
		setMenuItems(expandMenuItems(appMenuItems));
	}, []);

	const getRoutes = (arg) => {
		const user = { ...JSON.parse(localStorage.getItem('user')) };
		const availableRoutes = [];

		let usrRoles = user.roles;
		let isaMenu = { name: 'ISA', link: '/isa' };
		let osaMenu = { name: 'Agent', link: '/osa' };
		let isasMenu = { name: 'ISAs', link: '/isas' };
		let osasMenu = { name: 'Agents', link: '/osas' };
		let overviewMenu = { name: 'Overview', link: '/overview' };
		let trasactionsMenu = { name: 'Trasactions', link: '/trasactions' };

		const addMenuAndItems = (menuToModify, itemsToAdd) => {
			let childItems = itemsToAdd.filter((childItem, childIndex) => {
				return !menuToModify.some((el) => {
					return el.link.toLowerCase() !== childItem.link.toLowerCase();
				});
			});

			let menu = [...menuToModify, ...childItems];
			return menu;
		};

		let mainDashOverview = {
			name: 'Overview',
			link: '/overview',
		};
		let mainDashTransactions = {
			name: 'Transactions',
			link: '/trasactions',
		};

		let ISAManagmentOverview = {
			name: 'Overview',
			link: '/manager/overview',
		};
		let ISAManagmentISAs = {
			name: 'ISAs',
			link: '/isas',
		};
		let ISAManagmentLeads = {
			name: 'Leads',
			link: '/manager/leads',
		};
		let marketingOverview = {
			name: 'Overview',
			link: '/marketing/overview',
		};
		let marketingLeads = {
			name: 'Lead sources',
			link: '/marketing/leads',
		};

		if (!usrRoles) return [];
		if (usrRoles.some((el) => el.name.toLowerCase() === 'team leader')) {
			let menu = addMenuAndItems(availableRoutes, [
				mainDashOverview,
				mainDashTransactions,
				osasMenu,
				ISAManagmentOverview,
				ISAManagmentISAs,
				ISAManagmentLeads,
				marketingOverview,
				marketingLeads,
			]);
			availableRoutes.push(...menu);
		} else if (usrRoles.some((el) => el.name.toLowerCase() === 'isa manager')) {
			let menu = addMenuAndItems(availableRoutes, [
				ISAManagmentOverview,
				ISAManagmentISAs,
				ISAManagmentLeads,
			]);
			availableRoutes.push(...menu);
		} else if (usrRoles.some((el) => el.name.toLowerCase() === 'sales manager')) {
			let menu = addMenuAndItems(availableRoutes, [osasMenu]);
			availableRoutes.push(...menu);
		} else if (
			usrRoles.some((el) => el.name.toLowerCase() === 'marketing manager')
		) {
			let menu = addMenuAndItems(availableRoutes, [
				marketingOverview,
				marketingLeads,
			]);
			availableRoutes.push(...menu);
		} else if (usrRoles.some((el) => el.name.toLowerCase() === 'ops director')) {
			let menu = addMenuAndItems(availableRoutes, [
				mainDashOverview,
				mainDashTransactions,
				osasMenu,
				ISAManagmentOverview,
				ISAManagmentISAs,
				ISAManagmentLeads,
				marketingOverview,
				marketingLeads,
			]);
			availableRoutes.push(...menu);
		} else if (usrRoles.some((el) => el.name.toLowerCase() === 'agent')) {
			let menu = addMenuAndItems(availableRoutes, [osaMenu]);
			availableRoutes.push(...menu);
		} else if (usrRoles.some((el) => el.name.toLowerCase() === 'isa')) {
			let menu = addMenuAndItems(availableRoutes, [isaMenu]);
			availableRoutes.push(...menu);
		} else if (usrRoles.some((el) => el.name.toLowerCase() === 'client care m')) {
			let menu = addMenuAndItems(availableRoutes, [
				mainDashOverview,
				mainDashTransactions,
				osasMenu,
				ISAManagmentOverview,
				ISAManagmentISAs,
				ISAManagmentLeads,
				marketingOverview,
				marketingLeads,
			]);
			availableRoutes.push(...menu);
		} else if (
			usrRoles.some((el) => el.name.toLowerCase() === 'chief mindset officer')
		) {
			let menu = addMenuAndItems(availableRoutes, [
				osasMenu,
				ISAManagmentOverview,
				ISAManagmentISAs,
				ISAManagmentLeads,
				marketingOverview,
				marketingLeads,
			]);
			availableRoutes.push(...menu);
		}

		return availableRoutes;
	};

	return (
		<NavContext.Provider
			value={{
				menuItems: menuItems,
				updateTree,
				getRoutes,
			}}
		>
			{props.children}
		</NavContext.Provider>
	);
};
