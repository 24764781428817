import React, { useRef, useEffect, useState } from 'react';
import { Divider, Grid } from '@material-ui/core';

import {
	ContentSection,
	TableList,
	NavTabs,
	useCheckAuth,
	SwitchBtns,
	SwitchBtnsPlchld,
	useAPI,
} from 'components/lib';

import {
	initState,
	generateStats,
	statsCardTemplate,
	generateChartFooter,
	generateLeaderboardList,
	generateChart,
	generateLeadsList,
	countListTabsVals,
} from './OSAHelpers';
import {
	OSANotifLinks,
	OSANotifMessage,
	OSAGreetings,
	OSAStats,
	BarChartSection,
} from './OSAComponents';

export function OSADetail(props) {
	const { apiCall = false, managerView = false } = props;
	useCheckAuth();
	useAPI('/auth/check');
	apiCall || (window.location = '/osas');
	const metrics = useAPI(apiCall);
	let listRef = useRef(null);
	let tabRef = useRef(null);
	const [state, setState] = useState(initState);
	const [stateData, setStateData] = useState(false);

	const [user, setUser] = useState(false);
	const [viewIndex, setViewIndex] = React.useState(0);
	const handleViewChange = (event) => {
		setViewIndex(event);
	};

	let newUser = false;
	useEffect(() => {
		setUser({ ...JSON.parse(localStorage.getItem('user')) });
	}, [newUser]);

	const scrollFn = (ref) => {
		if (ref.current) {
			setViewIndex(4);

			tabRef.current.updateIndicator();
			window.scrollTo({
				behavior: 'smooth',
				top: ref.current.offsetTop - 100,
			});
		}
	};

	const formBtnClb = (El, row) => {
		if (row.id) {
			const url =
				El === 'IconCell'
					? `https://reefpointrealty.followupboss.com/2/people/view/${row.id}`
					: El[0] === 'form'
					? El[3]
					: ``;
			url.length > 0 && window.open(url, '_blank');
		}
	};

	useEffect(() => {
		if (metrics.data) {
			try {
				const data = metrics.data;
				//console.log('metrics osa agent: ', data);
				const currentData30 = data['30'];
				const currentData90 = data['90'];
				const currentData365 = data['365'];
				const commisionCards = generateStats(
					statsCardTemplate,
					{
						current: data.commissionThisMonth,
						previous: data.commissionLastMonth,
						average: data.commissionAvg,
					},
					true
				);
				const apptSignRatioCards = generateStats(
					statsCardTemplate,
					{
						current: data.thisMonthRatio,
						previous: data.lastMonthRatio,
						average: data.avgRatio,
					},
					false
				);

				const OSABarChartFooter = {
					30: generateChartFooter(currentData30),
					90: generateChartFooter(currentData90),
					365: generateChartFooter(currentData365),
				};
				const OSALeaderboard = {
					30: generateLeaderboardList(data.leaderboard['30']),
					90: generateLeaderboardList(data.leaderboard['90']),
					365: generateLeaderboardList(data.leaderboard['365']),
				};

				const chartData = {
					30: generateChart(currentData30),
					90: generateChart(currentData90),
					365: generateChart(currentData365),
				};

				const AssignedLeadsListActive = {
					30: generateLeadsList(currentData30, 'Active'),
					90: generateLeadsList(currentData90, 'Active'),
					365: generateLeadsList(currentData365, 'Active'),
				};
				const AssignedLeadsListNurture = {
					30: generateLeadsList(currentData30, 'Nurture'),
					90: generateLeadsList(currentData90, 'Nurture'),
					365: generateLeadsList(currentData365, 'Nurture'),
				};
				const AssignedLeadsListClosed = {
					30: generateLeadsList(currentData30, 'Closed'),
					90: generateLeadsList(currentData90, 'Closed'),
					365: generateLeadsList(currentData365, 'Closed'),
				};
				const AssignedLeadsListPending = {
					30: generateLeadsList(currentData30, 'Pending'),
					90: generateLeadsList(currentData90, 'Pending'),
					365: generateLeadsList(currentData365, 'Pending'),
				};
				const AssignedLeadsListOpenDispo = {
					30: generateLeadsList(currentData30, 'OpenDispo'),
					90: generateLeadsList(currentData90, 'OpenDispo'),
					365: generateLeadsList(currentData365, 'OpenDispo'),
				};

				const osaListTabs = countListTabsVals({
					Active: AssignedLeadsListActive,
					Nurture: AssignedLeadsListNurture,
					Closed: AssignedLeadsListClosed,
					Pending: AssignedLeadsListPending,
					'Open Dispositions': AssignedLeadsListOpenDispo,
				});

				const missingDispositions = data[90]?.missingDispositions
					? data[90].missingDispositions
					: 0;

				const closedCount = {
					30:
						currentData30.closedCount !== undefined ? currentData30.closedCount : 0,
					90:
						currentData90.closedCount !== undefined ? currentData90.closedCount : 0,
					365:
						currentData365.closedCount !== undefined ? currentData365.closedCount : 0,
				};

				setState({
					...state,
					apptSignRatioCards,
					commisionCards,
					missingDispositions,
					loading: false,
				});

				setStateData((previousState) => {
					return {
						...previousState,
						OSABarChartFooter,
						OSALeaderboard,
						chartData,
						AssignedLeadsListActive,
						AssignedLeadsListNurture,
						AssignedLeadsListClosed,
						AssignedLeadsListPending,
						AssignedLeadsListOpenDispo,
						osaListTabs,
						closedCount,
					};
				});
			} catch (e) {
				console.log('error osa', e);
				setState({
					...state,
					loading: false,
				});
			}
		}

		if (!metrics.data) setState({ ...state, loading: metrics.loading });
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [metrics]);

	const handleTimeSelector = async (index, val) => {
		let indx = parseInt(index);
		switch (indx) {
			case 0:
				await setState((previousState) => {
					return {
						...previousState,
						chartData: stateData.chartData[val],
						OSABarChartFooter: stateData.OSABarChartFooter[val],

						closedCount: stateData.closedCount[val],
						selectedTimeframe: val,
					};
				});
				break;
			case 1:
				await setState((previousState) => {
					return {
						...previousState,
						OSALeaderboard: stateData.OSALeaderboard[val],
					};
				});

				break;
			case 2:
				await setState((previousState) => {
					return {
						...previousState,
						AssignedLeadsListActive: stateData.AssignedLeadsListActive[val],
						AssignedLeadsListClosed: stateData.AssignedLeadsListClosed[val],
						AssignedLeadsListNurture: stateData.AssignedLeadsListNurture[val],
						AssignedLeadsListPending: stateData.AssignedLeadsListPending[val],
						AssignedLeadsListOpenDispo: stateData.AssignedLeadsListOpenDispo[val],
						osaListTabs: stateData.osaListTabs[val],
					};
				});
				break;
			default:
				break;
		}
	};

	return (
		<>
			<Divider style={{ marginBottom: '0.5em', height: 0 }} />

			{managerView || (
				<OSAGreetings
					state={state}
					user={user}
					selectedTimeframe={state.selectedTimeframe}
				/>
			)}

			{false && <OSANotifLinks />}

			<OSANotifMessage state={state} scrollFn={scrollFn} listRef={listRef} />

			<ContentSection disableButton title='Stats'>
				<ContentSection disableButton>
					<OSAStats state={state} />
				</ContentSection>
			</ContentSection>

			<ContentSection
				title='My Sales Funnel'
				disableButton
				headerEl={
					stateData.chartData ? (
						<SwitchBtns clb={handleTimeSelector} indx='0' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				<BarChartSection state={state} />
			</ContentSection>
			<ContentSection
				disableButton
				title='Leaderboard'
				headerEl={
					stateData.chartData ? (
						<SwitchBtns clb={handleTimeSelector} indx='1' />
					) : (
						<SwitchBtnsPlchld />
					)
				}
			>
				<TableList
					data={state.OSALeaderboard}
					initialOrderBy='appt held'
					initialOrder='desc'
					maxRows={3}
					topResults={true}
				/>
			</ContentSection>
			<div ref={listRef} />
			<ContentSection disableButton title=' '>
				<Grid container direction='row' justify='space-between' alignItems='center'>
					<Grid item xs={8}>
						<NavTabs
							changeView={handleViewChange}
							items={state.osaListTabs}
							secondary
							viewIndex={viewIndex}
							elRef={tabRef}
						/>
					</Grid>
					<Grid item>
						{stateData.AssignedLeadsListActive ? (
							<SwitchBtns
								clb={handleTimeSelector}
								indx='2'
								disabled={viewIndex > 1}
								defaultOnDisable
							/>
						) : (
							<SwitchBtnsPlchld />
						)}
					</Grid>
				</Grid>

				<TableList
					callBack={formBtnClb}
					initialOrderBy='last contact'
					initialOrder='desc'
					minHeight='80vh'
					data={
						viewIndex === 1
							? state.AssignedLeadsListClosed
							: viewIndex === 2
							? state.AssignedLeadsListNurture
							: viewIndex === 3
							? state.AssignedLeadsListPending
							: viewIndex === 4
							? state.AssignedLeadsListOpenDispo
							: state.AssignedLeadsListActive
					}
				/>
			</ContentSection>
		</>
	);
}
