/***
 *
 *   Members
 *   Manage your members
 *
 **********/

import React from 'react';

import { Grid, Divider } from '@material-ui/core';

import { NavTabs, History } from 'components/lib';

import { InvitesPage } from './invitesPage';

const Tabs = [
	{ label: 'Members', link: '/users_managment/members' },
	{ label: 'Invites', link: '/users_managment/invites' },
];

export function Invites(props) {
	const handleViewChange = (e) =>
		e === 0 && History.push('/users_managment/members');
	const user = { ...JSON.parse(localStorage.getItem('user')) };
	let usrRoles = user.roles;
	if (!usrRoles.some((el) => el.name.toLowerCase() === 'team leader'))
		return <></>;
	return (
		<>
			<Grid
				container
				direction='column'
				justify='flex-start'
				style={{
					paddingTop: '5em',
					maxWidth: '72em',
					minHeight: '100vh',
					alignItems: 'center',
				}}
			>
				<NavTabs
					items={Tabs}
					defaultValue={1}
					disableSwitch
					changeView={handleViewChange}
				/>
				<Divider style={{ marginBottom: '3em', height: 0 }} />
				<InvitesPage />
				<Divider style={{ marginBottom: '3em', height: 0 }} />
			</Grid>
		</>
	);
}
