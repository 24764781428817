import React, { useState, useEffect } from 'react';

import {
	PageWrapper,
	ContentSection,
	Loader,
	ChartAlt as LineChart,
	Wrapper,
	useCheckAuth,
	ComparisonCard,
	ChartBare,
	apiAxiosCall,
} from 'components/lib';

import { Grid, Typography } from '@material-ui/core';

import {
	generateLineChart,
	copmpileMarketingSpend,
	generateStatusCards,
	generateBarChart,
	generatePieChart,
	generatePieChartLegend,
	generatePerformanceList,
} from './marketingHelpers';

import {
	PerformanceListRow,
	ListRow,
	PieChartLegendRow,
	BarChartSection,
} from './marketingComponents';

export function MarketingOverview(props) {
	useCheckAuth();

	const [state, setState] = useState({
		barChart: false,
		lineChart: false,
		pieChart: false,
		pieChartLegend: false,
		loading: false,
		performanceList: false,

		marketingSpend: {
			label: 'Total marketing spent',
			number1: null,
			number2: '',
		},
		statusCards: false,
	});

	const populateState = (data) => {
		try {
			const statusCards = generateStatusCards(data.status);
			const barChart = generateBarChart(data.costPerLead);
			const pieChart = generatePieChart(data.spent);
			const pieChartLegend = generatePieChartLegend(data.spent);
			const lineChart = generateLineChart(
				data.averageROI,
				data.averageROIPredictions
			);

			const performanceList = generatePerformanceList(
				data.now.performance,
				data.old.performance
			);

			const marketingSpend = copmpileMarketingSpend(
				data.now.totalMarketing,
				data.old.totalMarketing
			);

			setState((prevState) => {
				return {
					...prevState,
					loading: false,
					lineChart,
					barChart,
					statusCards,
					marketingSpend,
					pieChart,
					pieChartLegend,
					performanceList,
				};
			});
		} catch (error) {
			console.log('error in agent view', error);
		}
	};

	//
	useEffect(() => {
		apiAxiosCall(`/api/marketing`).then((res) => {
			if (!res.err) {
				populateState(res.data);
			}
		});
	}, []);

	return (
		<PageWrapper>
			<ContentSection title='Average ROI' disableButton>
				<Wrapper customStyle={lineChartPlaceholder}>
					{
						//state.loading
						false ? (
							<Loader wrapped customStyle={lineChartPlaceholder} />
						) : (
							<LineChart
								chartData={state.lineChart}
								customStyle={lineChartPlaceholder}
							/>
						)
					}
				</Wrapper>
			</ContentSection>

			<Grid
				container
				direction='row'
				justify='space-between'
				alignItems='flex-start'
				spacing={2}
			>
				<Grid item xs={6}>
					<ContentSection title='Lead interval status' disableButton>
						<Wrapper customStyle={cardsPlaceholder}>
							<Grid
								container
								direction='column'
								justify='flex-start'
								alignItems='stretch'
								spacing={1}
							>
								<ListRow isHeader />
								{state.statusCards &&
									state.statusCards?.map((el, index) => {
										return <ListRow data={el} key={index} />;
									})}
							</Grid>
						</Wrapper>
					</ContentSection>
				</Grid>
				<Grid item xs={6}>
					<ContentSection title='Source performance' disableButton>
						<Wrapper customStyle={{ minHeight: 230, width: '100%' }}>
							<Grid
								container
								direction='row'
								justify='space-evenly'
								alignItems='center'
								style={{ minHeight: 230 }}
							>
								<Grid
									item
									container
									direction='column'
									justify='flex-start'
									alignItems='center'
									xs={5}
									spacing={1}
								>
									<PerformanceListRow isHeader data={{ label: 'BEST PERFORMANCE' }} />
									{state.performanceList?.best &&
										state.performanceList?.best.map((el, indx) => {
											return <PerformanceListRow data={el} key={indx} />;
										})}
								</Grid>
								<Grid
									item
									container
									direction='column'
									justify='flex-start'
									alignItems='stretch'
									xs={5}
									spacing={1}
								>
									<PerformanceListRow isHeader data={{ label: 'WORST PERFORMANCE' }} />
									{state.performanceList?.worst &&
										state.performanceList?.worst.map((el, indx) => {
											return <PerformanceListRow data={el} key={indx} />;
										})}
								</Grid>
							</Grid>
						</Wrapper>
					</ContentSection>
				</Grid>
			</Grid>

			<ContentSection title='' disableButton>
				<Wrapper customStyle={cardsMultipartCardsPlaceholder}>
					<Grid
						container
						direction='row'
						justify='space-between'
						alignItems='stretch'
						style={cardsMultipartCardsPlaceholder}
					>
						<Grid
							item
							container
							direction='row'
							justify='center'
							alignItems='center'
							xs={4}
						>
							<ComparisonCard data={[state.marketingSpend]} />
						</Grid>
						<Grid
							item
							style={{
								borderColor: '#171C22',
								borderWidth: 0,
								borderLeftWidth: 1,
								borderRightWidth: 1,
								borderStyle: 'solid',
								padding: '20px 40px',
							}}
							xs={3}
						>
							<BarChartSection chart={state.barChart} loading={state.loading} />
							{/* <Grid
								container
								direction='row'
								justify='space-evenly'
								alignItems='center'
								//spacing={4}
								style={{ marginBottom: 5 }}
							>
								<ChartLabelIcons />
							</Grid> */}

							<Typography variant='subtitle1' align='center'>
								Cost per Lead
							</Typography>
						</Grid>
						<Grid
							item
							xs={5}
							container
							direction='row'
							justify='center'
							alignItems='center'
						>
							<Grid item xs={6}>
								<ChartBare
									chartData={state.pieChart}
									customStyle={{ width: '100%', minHeight: 200 }}
								/>
							</Grid>
							<Grid
								item
								xs={4}
								container
								direction='column'
								justify='flex-start'
								alignItems='flex-start'
							>
								<Typography variant='h4' align='center' gutterBottom>
									Total Spent
								</Typography>
								<Grid
									item
									container
									direction='column'
									justify='flex-start'
									alignItems='flex-start'
									spacing={1}
								>
									{state.pieChartLegend &&
										state.pieChartLegend.map((el, index) => {
											return <PieChartLegendRow el={el} key={index} />;
										})}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Wrapper>
			</ContentSection>
		</PageWrapper>
	);
}

const lineChartPlaceholder = { minHeight: 312, width: '100%' };
const cardsPlaceholder = {
	minHeight: 230,
	width: '100%',
	padding: 25,
};
const cardsMultipartCardsPlaceholder = { minHeight: 270, width: '100%' };
